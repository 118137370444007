import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import Header from 'components/Headers/Header';
import { useSelector } from 'react-redux'; 
import axios from 'axios'
import {Spinner, Container, Row, Col, Button, Card, CardHeader, CardBody} from 'reactstrap'
import {Form} from 'react-bootstrap'

function IoTComparation() {
    const [loadingiot, setloadingiot] = useState(true)

    const [loadingsubmit, setloadingsubmit] = useState(true)

    const [variable, setvariable] = useState('Temperatura')

    const [selectediot, setselectediot] = useState('');
    const [optionsiot, setoptionsiot] = useState([]);

    const [htmlContent, setHtmlContent] = useState('');

    const token = useSelector((state) => state.userLogin.userInfo.token);

    useEffect(() => {
      
        const fetchData = async () => {
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
                
            const responseposition = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/positioniot/`, config);
            
            setoptionsiot(responseposition.data);
            setloadingiot(false)

          } catch (error) {
            // console.error('Errore durante la richiesta GET:', error);
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //   setError(false)
            // }, 5000);
            // return;
          }
        };
    
        fetchData();
      }, [token]);



    ////////////
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);
    ////////////

    const submit = async () => {

        setloadingsubmit(false);

        try {            
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
            

            if (selectediot==='') {
                
                const response= await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iotcomparation?variable=${variable}`, config);
                setHtmlContent(response.data);

            } else {
                
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iotcomparation?variable=${variable}`, config);
                setHtmlContent(response.data);

            }
          
        } catch (error) {  
            // console.error('Errore durante la richiesta GET:', error);
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //   setError(false)
            // }, 5000);
            // return;
        } finally {
            setloadingsubmit(true);
        }

       
    };

    return (
        <div>
            <Header/>
            {loadingiot ? (
                <Container className="mt--15" fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                            <Spinner>{t('Loading')}</Spinner>
                        </Col>
                    </Row>
                </Container>
                ) : (
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} className="text-center mt-5">
                                <Card>
                                    <CardHeader>
                                        <h3>{t('Analysis')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <Form.Label>{t('Select the variable')}</Form.Label>
                                            <Form.Control 
                                                as="select" 
                                                onChange={(e) => setvariable(e.target.value)}
                                                value={variable}>
                                                    <option value={'Temperatura'}>
                                                        {t('Soil Temperature')} (°C)
                                                    </option>
                                                    <option value={'Umidità'}>
                                                        {t('Soil Humidity')} (%)                                      
                                                    </option>
                                                    <option value={'Conducibilità_Elettrica'}>
                                                        {t('Electrical Conductivity')} (S/m)                      
                                                    </option>
                                                    <option value={'Carbonio_Organico'}>
                                                        {t('Organic Carbon')} (mg/kg)                                    
                                                    </option>
                                                    <option value={'Azoto'}>
                                                        {t('Nitrogen')} (mg/kg)                                        
                                                    </option>
                                                    <option value={'Fosforo'}>
                                                        {t('Phosporous')} (mg/kg)                                      
                                                    </option>
                                                    <option value={'Potassio'}>
                                                        {t('Potassium')} (mg/kg)                                   
                                                    </option>
                                                    <option value={'Batteria'}>
                                                        {t('Battery')} (A)                                      
                                                    </option>
                                            </Form.Control>
                                            <br></br>
                                            {loadingsubmit ? (
                                                <>
                                                    <Button color='success' onClick={submit}>{t('View data')}</Button>
                                                </>
                                            ):(
                                                <>
                                                    <Button color="success" disabled>
                                                        <Spinner size="sm">
                                                            Loading...
                                                        </Spinner>
                                                        <span>
                                                        {' '}Loading
                                                        </span>
                                                    </Button>

                                                </>
                                            )}
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9} className="text-center mt-5">
                                {htmlContent && (
                                    <>
                                        <Card>
                                            <CardHeader>
                                                <h3>{t('Time Series Comparation')}</h3>
                                            </CardHeader>
                                            <CardBody>
                                                <iframe id="iframe11" width="100%" height="500" srcDoc={htmlContent}></iframe>
                                            </CardBody>
                                        </Card>    
                                    </>
                                )}                            
                            </Col>                            
                        </Row>
                    </Container>
                </>
            )}
        </div>
    )
}

export default IoTComparation
