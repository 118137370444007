const translation = {
  "GB": {
    "translation": {
      "Hello": "Hello",
      "The email is empty": "The email is empty",
      "Yes":"Yes",
      "No":"No",
      "The password is empty": "The password is empty",
      "The user is not active!": "The user is not active!",
      "The email is used": "The email is used",
      "Forgot password?": "Forgot password?",
      "Create new account": "Create new account",
      "Check your email inbox and follow the instructions to change the password.": "Check your email inbox and follow the instructions to change the password.",
      "Go back to Login": "Go back to Login",
      "The email doesn't exist in our database!": "The email doesn't exist in our database!",
      "Change Password": "Change Password",
      "Send Email": "Send Email",
      "Sending email...": "Sending email...",
      "The first password is empty": "The first password is empty",
      "The confirm password is empty": "The confirm password is empty",
      "The password don't match": "The password don't match",
      "Password change failed. If the problem persist, please send us an email at automaticfarmsolution@gmail.com": "Password change failed. If the problem persist, please send us an email at automaticfarmsolution@gmail.com",
      "An error occurred while changing the password. If the problem persist, please send us an email at automaticfarmsolution@gmail.com": "An error occurred while changing the password. If the problem persist, please send us an email at automaticfarmsolution@gmail.com",
      "Confirm Password": "Confirm Password",
      "Changing Password...": "Changing Password...",
      "The password has been changed with success. Now use the new password to login.": "The password has been changed with success. Now use the new password to login.",
      "In order to register you should accept the Privacy Policy": "In order to register you should accept the Privacy Policy",
      "In order to register you should provide your name": "In order to register you should provide your name",
      "In order to register you should provide a valid email": "In order to register you should provide a valid email",
      "The current email is used, please use another one!": "The current email is used, please use another one!",
      "Registration": "Registration",
      "Name": "Name",
      "I agree with the": "I agree with the",
      "Register": "Register",
      "Registration...": "Registration...",
      "Already have an account?": "Already have an account?",
      "Check your email inbox and follow the instructions to activate your account.": "Check your email inbox and follow the instructions to activate your account.",
      "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com",
      "An error occurred during the activation fo your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "An error occurred during the activation fo your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com",
      "Do you want to activate your account?": "Do you want to activate your account?",
      "Activate my account": "Activate my account",
      "Activation...": "Activation...",
      "Your Account has been activated. Now You can login": "Your Account has been activated. Now You can login",
      "Welcome!": "Welcome!",
      "My profile": "My profile",
      "Settings": "Settings",
      "Support": "Support",
      "Logout": "Logout",
      "Farm": "Farm",
      "Field": "Field",
      "Field Notebook": "Field Notebook",
      "Agrometeo": "Agrometeo",
      "Wheater": "Wheater",
      "Climatic Index": "Climatic Index",
      "Satellite": "Satellite",
      "Prescription Map": "Prescription Map",
      "Sensors": "Sensors",
      "Summary": "Summary",
      "Analysis": "Analysis",
      "Forecast": "Forecast",
      "Anomaly Detection": "Anomaly Detection",
      "Comparation": "Comparation",
      "Allert": "Allert",
      "Bio Trap": "Bio Trap",
      "Agronomic tools": "Agronomic tools",
      "Nutrient Balance": "Nutrient Balance",
      "Soil Data": "Soil Data",
      "Irrigation": "Irrigation",
      "Pest Model": "Pest Model",
      "Artificial Intelligence": "Artificial Intelligence",
      "Digital Consulting": "Digital Consulting",
      "Crop Models & simulations": "Crop Models & simulations",
      "Soil Mapping & Carbon Credits": "Soil Mapping & Carbon Credits",
      "Organic vs Convenctional": "Organic vs Convenctional",
      "Connections & Data Sharing": "Connections & Data Sharing",
      "Upgrade your profile": "Upgrade your profile",
      "Register a new farmer": "Register a new farmer",
      "Farmer Company Name:": "Farmer Company Name:",
      "Farmer First Name:": "Farmer First Name:",
      "Farmer Surname:": "Farmer Surname:",
      "Farmer Country:": "Farmer Country:",
      "Farmer City:": "Farmer City:",
      "Farmer Adress:": "Farmer Adress:",
      "Farmer Email:": "Farmer Email:",
      "Farmer Phone Number:": "Farmer Phone Number:",
      "Create Farm": "Create Farm",
      "Go back": "Go back",
      "Restart Creation": "Restart Creation",
      "Creating the farm...": "Creating the farm...",
      "Farm Registered": "Farm Registered",
      "No farmer are registered": "No farmer are registered",
      "If you want to start using this application, you should start by registering a new farmer.": "If you want to start using this application, you should start by registering a new farmer.",
      "Farm Registred": "Farm Registred",
      "Farm Name": "Farm Name",
      "Surname": "Surname",
      "Email": "Email",
      "Phone Number": "Phone Number",
      "Edit": "Edit",
      "Delete": "Delete",
      "Are you sure to remove the following farmer?": "Are you sure to remove the following farmer?",
      "Name of the farmer:": "Name of the farmer:",
      "Surname of the farmer:": "Surname of the farmer:",
      "Country of the farmer:": "Country of the farmer:",
      "City of the farmer:": "City of the farmer:",
      "Adress of the farmer:": "Adress of the farmer:",
      "Email of the farmer:": "Email of the farmer:",
      "Phone Number of the farmer:": "Phone Number of the farmer:",
      "Delete Farmer": "Delete Farmer",
      "Invalid email address": "Invalid email address",
      "Invalid phone number": "Invalid phone number",
      "Add Farm": "Add Farm",
      "Farmer Registred": "Farmer Registred",
      "Farmer": "Farmer",
      "No registered farm": "No registered farm",
      "Loading": "Loading",
      "Update Farm": "Update Farm",
      "Updataing the farm...": "Updataing the farm...",
      "The Name of the farmer is empty": "The Name of the farmer is empty",
      "The Surname of the farmer is empty": "The Surname of the farmer is empty",
      "The CountryCode of the farmer is empty": "The CountryCode of the farmer is empty",
      "The City of the farmer is empty": "The City of the farmer is empty",
      "The Adress of the farmer is empty": "The Adress of the farmer is empty",
      "The Email of the farmer is empty": "The Email of the farmer is empty",
      "The Phone Number of the farmer is empty": "The Phone Number of the farmer is empty",
      "An error occured while updating the farm. if the problem persist please contact us at automaticfarmsolution@gmail.com": "An error occured while updating the farm. if the problem persist please contact us at automaticfarmsolution@gmail.com",
      "Create Field": "Create Field",
      "You don't have any farm registred, first you have to set at least one farmer": "You don't have any farm registred, first you have to set at least one farmer",
      "Field name:": "Field name:",
      "Crop Rotation:": "Crop Rotation:",
      "Crop Rotation": "Crop Rotation",
      "Perennial crop": "Perennial crop",
      "Two year crop rotation": "Two year crop rotation",
      "Three year crop rotation": "Three year crop rotation",
      "Actual Crop": "Actual Crop",
      "Alfalfa": "Alfalfa",
      "Barley": "Barley",
      "Maize": "Maize",
      "Durum Wheat": "Durum Wheat",
      "The next year Crop": "The next year Crop",
      "The crop in two years' time": "The crop in two years' time",
      "Organic": "Organic",
      "Convenctional": "Convenctional",
      "Draw the edge of your field:": "Draw the edge of your field:",
      "The field name is empty": "The field name is empty",
      "Please draw the edge of your field in the map": "Please draw the edge of your field in the map",
      "An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com": "An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com",
      "List of the field": "List of the field",
      "Field Name:": "Field Name:",
      "Field Detail": "Field Detail",
      "Crop:": "Crop:",
      "Agronomic Management:": "Agronomic Management:",
      "Are you sure to delete this field?": "Are you sure to delete this field?",
      "Delete Field": "Delete Field",
      "Field:": "Campo:",
      "Loading...": "Loading...",
      "Edit Field": "Edit Field",
      "Draw again the edge of your field:": "Draw again the edge of your field:",
      "In red there is the old field": "In red there is the old field",
      "An error occured while editing the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com": "An error occured while editing the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com",
      "Try Again": "Try Again",
      "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com",
      "Select the field to analyze": "Select the field to analyze",
      "View Satellite Image": "View Satellite Image",
      "Field Details": "Field Details",
      "Actual Crop:": "Actual Crop:",
      "Time series plot": "Time series plot",
      "Satellite Image": "Satellite Image",
      "Select one field": "Select one field",
      "Field Boundary": "Field Boundary",
      "Select the Vegetation Index": "Select the Vegetation Index",
      "Set the prescription map": "Set the prescription map",
      "Do you want to define the number of zone in Automatically way or Manual?": "Do you want to define the number of zone in Automatically way or Manual?",
      "Automatic": "Automatic",
      "Manual": "Manual",
      "If is set Manual, how many zones do you want to create?": "If is set Manual, how many zones do you want to create?",
      "Max fertilizer that you want to apply?": "Max fertilizer that you want to apply?",
      "Which strategy do you want to use?": "Which strategy do you want to use?",
      "Provide higher amout of fertilizer where the crop is stronger": "Provide higher amout of fertilizer where the crop is stronger",
      "Provide lower amout of fertilizer where the crop is stronger": "Provide lower amout of fertilizer where the crop is stronger",
      "Percentage of difference of fertilizer between zones": "Percentage of difference of fertilizer between zones",
      "Selected Percentage:": "Selected Percentage:",
      "Create": "Create",
      "Send to Jhon Deere Operation Center": "Send to Jhon Deere Operation Center",
      "Download": "Download",
      "Downloading...": "Downloading...",
      "Downloading": "Downloading",
      "Sending": "Sending",
      "Sending...": "Sending...",
      "Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com": "Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com",
      "Creating the field...": "Creating the field...",
      "Update Field": "Update Field",
      "Updating Field": "Updating Field",
      "Integration done": "Integration done",
      "You have done the integration with jhon deere": "You have done the integration with jhon deere",
      "Start the integration with the Operation Center Jhon Deere": "Start the integration with the Operation Center Jhon Deere",
      "A problem occured during the integration with Jhon Deere": "A problem occured during the integration with Jhon Deere",
      "Start the integration with the Operation Center John Deere": "Start the integration with the Operation Center John Deere",
      "Connect to Operation Center Jhon Deere": "Connect to Operation Center Jhon Deere",
      "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center": "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center",
      "Satellite Image analysis": "Satellite Image analysis",
      "Please enable the geolocation in order to get the wheater data and visualization": "Please enable the geolocation in order to get the wheater data and visualization",
      "Actual Wheater Data": "Actual Wheater Data",
      "Wheater Map": "Wheater Map",
      "Select the climate variable to see": "Select the climate variable to see",
      "Forecast Temperature": "Forecast Temperature",
      "Wind": "Wind",
      "Pressure": "Pressure",
      "Snow": "Snow",
      "Precipitation": "Precipitation",
      "Clouds": "Clouds",
      "Temperature": "Temperature",
      "Add / Modify / Delete": "Add / Modify / Delete",
      "Field Registred": "Field Registred",
      "Field Name": "Field Name",
      "Select the field to view": "Select the field to view",
      "Number of farmer": "Number of farmer",
      "Number of field": "Number of field",
      "Hectares Monitored": "Hectares Monitored",
      "Anomalies": "Anomalies",
      "IoT Number": "IoT Number",
      "Last Reding": "Last Reding",
      "Soil Temperature": "Soil Temperature",
      "Soil Humidity": "Soil Humidity",
      "Electrical Conductivity": "Electrical Conductivity",
      "Organic Carbon": "Organic Carbon",
      "Nitrogen": "Nitrogen",
      "Phosporous": "Phosporous",
      "Potassium": "Potassium",
      "Battery": "Battery",
      "Select the variable": "Select the variable",
      "Select the IoT Cabin": "Select the IoT Cabin",
      "View data": "View data",
      "Time series Graph": "Time series Graph",
      "Time Series Comparation": "Time Series Comparation",
      "Table": "Table",
      "Add Allert": "Add Allert",
      "Allert Registred": "Allert Registred",
      "Sensor": "Sensor",
      "Variable": "Variable",
      "Condition": "Condition",
      "Value": "Value",
      "No Allert Registrated": "No Allert Registrated",
      "No IoT is linked to your account": "No IoT is linked to your account",
      "No IoT allert is setted": "No IoT allert is setted",
      "Please select the IoT Cabin": "Please select the IoT Cabin",
      "Please select the variable": "Please select the variable",
      "Please select the logic to apply": "Please select the logic to apply",
      "Please set the threshold value": "Please set the threshold value",
      "Select the logic": "Select the logic",
      "Set the threshold value": "Set the threshold value",
      "Adding the allert...": "Adding the allert...",
      "Higher than": "Higher than",
      "Lower than": "Lower than",
      "IoT Position": "IoT Position",
      "Create Allert": "Create Allert",
      "Are you sure to remove this allert?": "Are you sure to remove this allert?",
      "Cabin Number:": "Cabin Number:",
      "Logic - Higher or Lower:": "Logic - Higher or Lower:",
      "Threshold Value:": "Threshold Value:",
      "Variabile": "Variabile",
      "Delete Allert": "Delete Allert",
      "Update Allert": "Update Allert",
      "Updating the allert...": "Updating the allert...",
      "Please the expected yield (q/ha)": "Please the expected yield (q/ha)",
      "Please the fertilizer title (%)": "Please the fertilizer title (%)",
      "Setting of the Calculation": "Setting of the Calculation",
      "Select the Nutrient": "Select the Nutrient",
      "Select the Crop": "Select the Crop",
      "Set the expected yield (q/ha)": "Set the expected yield (q/ha)",
      "Title of fertilizer - range 0-100 %": "Title of fertilizer - range 0-100 %",
      "Calculate": "Calculate",
      "The amount of fertilizer that you have provide to the crop for the entire crop growth cycle is:": "The amount of fertilizer that you have provide to the crop for the entire crop growth cycle is:",
      "Set the field to get the soil data": "Set the field to get the soil data",
      "Hello, I'm your Digital Agronomist Consultant ! Ask me anything!": "Hello, I'm your Digital Agronomist Consultant ! Ask me anything!",
      "The digital agronomist consultant is typing": "The digital agronomist consultant is typing",
      "Send a Message": "Send a Message",
      "Digital agronomist consultant": "Digital agronomist consultant",
      "You have done the integration with John Deere": "You have done the integration with John Deere",
      "Please fill all the requests": "Please fill all the requests",
      "Set the analysis parameter": "Set the analysis parameter",
      "Date of sowing": "Date of sowing",
      "Select the soil type": "Select the soil type",
      "Sand": "Sand",
      "Loamy Sand": "Loamy Sand",
      "Sandy Loam": "Sandy Loam",
      "Loam": "Loam",
      "Silty Loam": "Silty Loam",
      "Silt": "Silt",
      "Sandy Clay Loam": "Sandy Clay Loam",
      "Clay Loam": "Clay Loam",
      "Silty Clay Loam": "Silty Clay Loam",
      "Sandy Clay": "Sandy Clay",
      "Clay": "Clay",
      "Sunflower": "Sunflower",
      "Millet": "Millet",
      "Rice": "Rice",
      "Sugarbeet": "Sugarbeet",
      "Sorghum": "Sorghum",
      "Soybean": "Soybean",
      "Canola": "Canola",
      "Tomato": "Tomato",
      "Cabbage": "Cabbage",
      "Potato": "Potato",
      "Sugarcane": "Sugarcane",
      "Select the Variety": "Select the Variety",
      "Do you want to provide a fertilization?": "Do you want to provide a fertilization?",
      "Date of fertilization": "Date of fertilization",
      "Define the amount of Nitrogen provided (kg N / ha)": "Define the amount of Nitrogen provided (kg N / ha)",
      "Define the amount of Phosphorus provided (kg P / ha)": "Define the amount of Phosphorus provided (kg P / ha)",
      "Define the amount of Potassium provided (kg K / ha)": "Define the amount of Potassium provided (kg K / ha)",
      "Do you want to provide a irrigation": "Do you want to provide a irrigation",
      "Date of irrigation": "Date of irrigation",
      "Define the amount of water mm": "Define the amount of water mm",
      "Next": "Next",
      "Submit": "Submit",
      "Temporal creation of the yield": "Temporal creation of the yield",
      "Before you can run the model you should define the crop type. Go to the Field and update section the information": "Before you can run the model you should define the crop type. Go to the Field and update section the information",
      "Set the Field where apply the analysis": "Set the Field where apply the analysis",
      "Set the Farmer": "Set the Farmer",
      "Set the Field": "Set the Field",
      "Run The Analysis": "Run The Analysis",
      "NDVI Comparation": "NDVI Comparation",
      "SAR Comparation": "SAR Comparation",
      "Probabilistic Map": "Probabilistic Map",
      "% Convenctional": "% Convenctional",
      "% Organic": "% Organic",
      "The field was declared by the farmer as": "The field was declared by the farmer as",
      "Select the start year to analyze": "Select the start year to analyze",
      "Select the base temperature for the crop": "Select the base temperature for the crop",
      "Select the max temperature for the crop": "Select the max temperature for the crop",
      "Start analysis": "Start analysis",
      "Upgrade your account": "Upgrade your account",
      "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO": "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO",
      "Not Now": "Not Now",
      "Upgrade your account to PRO": "Upgrade your account to PRO",
      "Your Acccount is PRO": "Your Acccount is PRO",
      "You can access to all the service": "You can access to all the service",
      "Your Acccount is not PRO": "Your Acccount is not PRO",
      "Purchase the PRO Service": "Purchase the PRO Service",
      "The total price is": "The total price is",
      "The payment was successful": "The payment was successful",
      "Now you should logout and login again to access all the features just unlocked": "Now you should logout and login again to access all the features just unlocked",
      "By purchasing this service you will access :": "By purchasing this service you will access :",
      "Prescription maps that can be integrated with your display tractors": "Prescription maps that can be integrated with your display tractors",
      "Visualization & Analysis of sensor data": "Visualization & Analysis of sensor data",
      "Set allert": "Set allert",
      "All the agronomic tools": "All the agronomic tools",
      "AI Tools": "AI Tools",
      "Synchronization with Operation Center Jhon Deere": "Synchronization with Operation Center Jhon Deere",
      "Durum_wheat": "Durum Wheat",
      "Vineyards": "Vineyards",
      "perennial": "Perennial",
      "biennial": "Biennal",
      "triennial": "Triennial",
      "Kiwi": "Kiwi",
      "Garlic": "Garlic",
      "Apricot": "Apricot",
      "Orange": "Orange",
      "Asparagus": "Asparagus",
      "Oats": "Oats",
      "Sugar_beet": "Sugar beet",
      "Basil": "Basil",
      "Swiss_chard": "Swiss chard",
      "Beet_leaves": "Beet leaves",
      "Broccoli": "Broccoli",
      "Hemp": "Hemp",
      "Artichoke": "Artichoke",
      "Carrot": "Carrot",
      "Cauliflower": "Cauliflower",
      "chickpea": "Chickpea",
      "cucumber": "Cucumber",
      "Chicory": "Chicory",
      "Cherry": "Cherry",
      "Onion": "Onion",
      "Endive": "Endive",
      "Watermelon": "Watermelon",
      "Rapeseed": "Rapeseed",
      "Green_bean_for_industry": "Green bean for industry",
      "Fresh_green_bean": "Fresh green bean",
      "Bean": "Bean",
      "Dry_bean": "Dry bean",
      "Spelt": "Spelt",
      "Grain_fava_bean": "Grain fava bean",
      "Favino": "Favino",
      "Fico": "Fig",
      "Fennel": "Fennel",
      "Strawberry": "Strawberry",
      "winter_wheat": "Winter wheat",
      "Kaki": "Persimmon",
      "Lettuce": "Lettuce",
      "Lentil": "Lentil",
      "Lemon": "Lemon",
      "Linen": "Linen",
      "Lupin": "Lupin",
      "Corn": "Corn",
      "Tangerine": "Tangerine",
      "Almond": "Almond",
      "Eggplant": "Eggplant",
      "Apple": "Apple",
      "Melon": "Melon",
      "Blueberry": "Blueberry",
      "Nectarines": "Nectarines",
      "Kernel": "Kernel",
      "Walnut": "Walnut",
      "Olive": "Olive",
      "Bell_pepper": "Bell pepper",
      "Pear": "Pear",
      "Peach": "Peach",
      "Pea": "Pea",
      "Leek": "Leek",
      "Parsley": "Parsley",
      "Radicchio": "Radicchio",
      "Turnip": "Turnip",
      "Radish": "Radish",
      "Ribes": "Currant",
      "Shallots": "Shallots",
      "Celery": "Celery",
      "Rye": "Rye",
      "Soy": "Soy",
      "Spinach": "Spinach",
      "Plum_tree": "Plum tree",
      "Tabacco": "Tobacco",
      "Triticale": "Triticale",
      "Vineyard": "Vineyard",
      "Savoy_cabbage": "Savoy cabbage",
      "Pumpkin": "Pumpkin",
      "Zucchini_for_industry": "Zucchini for industry",
      "Fresh_zucchini": "Fresh zucchini",
      "Peanuts": "Peanuts",
      "Chard": "Swiss chard",
      "Leaf_beet": "Beet leaves",
      "Hemp_for_seeds": "Hemp (seeds)",
      "Hemp_for_fiber": "Hemp (fiber)",
      "Cardoon": "Cardoon",
      "Chestnut": "Chestnut",
      "Cherry_tree": "Cherry tree",
      "French_bean": "Fresh green bean",
      "Fava_bean": "Dry bean",
      "Wheat": "Wheat",
      "Raspberry": "Raspberry",
      "Flax": "Linen",
      "Ley_grass": "Lupin",
      "Corn_for_grain": "Eggplant",
      "Hazelnut": "Hazelnut",
      "Pear_tree": "Pear tree",
      "Peach_tree": "Peach tree",
      "Protein_pea": "Protein pea",
      "Processing_tomato": "Processing tomato",
      "Table_tomato": "Table tomato",
      "Table_grape": "Table grape",
      "Gooseberry": "Gooseberry",
      "Grapevine": "Grapevine",
      "Clover": "Clover",
      "Corn_salad": "Corn salad",
      "Grapevine_for_wine_grapes": "Grapevine for wine grapes",
      "Sweet_corn": "Sweet corn",
      "Silage_corn": "Silage corn",
      "Oilseed_rape": "Oilseed rape",
      "Field-grown_bell_pepper": "Field-grown bell pepper",
      "Pea_for_industry": "Pea for industry",
      "Fresh_pea": "Fresh pea",
      "Greenhouse_table_tomato": "Greenhouse table tomato",
      "Clover_grassland": "Clover grassland",
      "Hill_pastures": "Hill pastures",
      "Mixed_grassland_>50_legumes": "Mixed grassland >50% legumes",
      "Artificial_mixed_hill_pastures": "Artificial mixed hill pastures",
      "Stable_plains": "Stable plains",
      "Silage_fodder": "Silage fodder",
      "Arugula_1st_cut": "Arugula, 1st cut",
      "Arugula_2nd_cut": "Arugula, 2nd cut",
      "Shallot": "Shallot",
      "Arugula_in_open_field": "Arugula in open field",
      "Phosphorus": "Phosphorus",
      "Organic vs Conventional:": "Organic vs Conventional:",
      "Are you sure to edit the polygon? if yes press again the Update Field button": "Are you sure to edit the polygon? if yes press again the Update Field button",
      "Set the Year of analysis": "Set the Year of analysis",
      "Set the nutrient to visualize": "Set the nutrient to visualize",
      "Carbonates": "Carbonates",
      "Soil Electrical Conductivity": "Soil Electrical Conductivity",
      "pH": "pH",
      "Please set the year": "Please set the year",
      "Please set the nutrient": "Please set the nutrient",
      "Please set the farmer": "Please set the farmer",
      "Please set the field": "Please set the field",
      "The map is not ready yet": "The map is not ready yet",
      "Please select the object of your request": "Please select the object of your request",
      "Please report a valid message": "Please report a valid message",
      "Send us your message": "Send us your message",
      "Object of the request:": "Object of the request:",
      "Object:": "Object:",
      "Support Request": "Support Request",
      "Upgrade account": "Upgrade account",
      "Problem with the application": "Problem with the application",
      "Other": "Other",
      "Message of the request:": "Message of the request:",
      "Message:": "Message:",
      "Send message": "Send message",
      "My account": "My account",
      "Do you want to upgrade your account?": "Do you want to upgrade your account?",
      "Send us a message": "Send us a message",
      "Your Message have been sent with success": "Your Message have been sent with success",
      "As soon as possible one of our operator will check your request and answer you": "As soon as possible one of our operator will check your request and answer you",
      "Frumento_tenero": "Winter Wheat",
      "Winter Wheat": "Winter Wheat",
      "Annual percentage increase": "Annual percentage increase",
      "Tons of CO2 Sequestered": "Tons of CO2 Sequestered",
      "Carbon Credits Generated": "Carbon Credits Generated",
      "Soil Map": "Soil Map",
      "Temporal Variation": "Temporal Variation",
      "If you want to access to this module data, you must upgrade your account to PRO":"If you want to access to this module data, you must upgrade your account to PRO",

      "Large Scale Application":"Large Scale Application",
      "Field Name":"Field Name",
      "Crop":"Crop",
      "Anomaly":"Anomaly",
      "Declared":"Declared",
      "Class":"Class",
      "Percentage":"Percentage",
      "Hectares Deleted":"Hectares Deleted",

      "Search City:":"Search City:",
      "Export Document":"Export Document",
      "Download Section":"Download Section",
      "Download Field - Format ShapeFile":"Download Field - Format ShapeFile",
      "Download Field - Format Excel":"Download Field - Format Excel",
      "Download Farmer Data - Format Excel":"Download Farmer Data - Format Excel",

      "Create a farm" : "Create a farm",
      "Create a field" : "Create a field",
      "Delete Account":"Delete Account",
      "Are you sure to delete your account?":"Are you sure to delete your account?",
      "Are you sure to delete the account?":"Are you sure to delete the account?",
      "Please be carefull cause if you delete your account all the data related will be removed":"Please be carefull cause if you delete your account all the data related will be removed",
      "If you want to proceed, please report your email ":"If you want to proceed, please report your email ",
      " in the following form input": " in the following form input",
      "Deleting the user...":"Deleting the user...",
      "Delete account":"Delete account",
      "In order to register you should accept the Terms And Conditions":"In order to register you should accept the Terms And Conditions",
      "Upload Field":"Upload Field",
      "Upload Zip File where within there is the shapefile with all the fields":"Upload Zip File where within there is the shapefile with all the fields",
      "Create Fields":"Create Fields"

    }
  },
  "EN-US": {
    "translation": {
      "Hello": "Hello",
      "Yes":"Yes",
      "No":"No",
      "The email is empty": "The email is empty",
      "The password is empty": "The password is empty",
      "The user is not active!": "The user is not active!",
      "The email is used": "The email is used",
      "Forgot password?": "Forgot password?",
      "Create new account": "Create new account",
      "Check your email inbox and follow the instructions to change the password.": "Check your email inbox and follow the instructions to change the password.",
      "Go back to Login": "Go back to Login",
      "The email doesn't exist in our database!": "The email doesn't exist in our database!",
      "Change Password": "Change Password",
      "Send Email": "Send Email",
      "Sending email...": "Sending email...",
      "The first password is empty": "The first password is empty",
      "The confirm password is empty": "The confirm password is empty",
      "The password don't match": "The password don't match",
      "Password change failed. If the problem persist, please send us an email at automaticfarmsolution@gmail.com": "Password change failed. If the problem persist, please send us an email at automaticfarmsolution@gmail.com",
      "An error occurred while changing the password. If the problem persist, please send us an email at automaticfarmsolution@gmail.com": "An error occurred while changing the password. If the problem persist, please send us an email at automaticfarmsolution@gmail.com",
      "Confirm Password": "Confirm Password",
      "Changing Password...": "Changing Password...",
      "The password has been changed with success. Now use the new password to login.": "The password has been changed with success. Now use the new password to login.",
      "In order to register you should accept the Privacy Policy": "In order to register you should accept the Privacy Policy",
      "In order to register you should provide your name": "In order to register you should provide your name",
      "In order to register you should provide a valid email": "In order to register you should provide a valid email",
      "The current email is used, please use another one!": "The current email is used, please use another one!",
      "Registration": "Registration",
      "Name": "Name",
      "I agree with the": "I agree with the",
      "Register": "Register",
      "Registration...": "Registration...",
      "Already have an account?": "Already have an account?",
      "Check your email inbox and follow the instructions to activate your account.": "Check your email inbox and follow the instructions to activate your account.",
      "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com",
      "An error occurred during the activation fo your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "An error occurred during the activation fo your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com",
      "Do you want to activate your account?": "Do you want to activate your account?",
      "Activate my account": "Activate my account",
      "Activation...": "Activation...",
      "Your Account has been activated. Now You can login": "Your Account has been activated. Now You can login",
      "Welcome!": "Welcome!",
      "My profile": "My profile",
      "Settings": "Settings",
      "Support": "Support",
      "Logout": "Logout",
      "Farm": "Farm",
      "Field": "Field",
      "Field Notebook": "Field Notebook",
      "Agrometeo": "Agrometeo",
      "Wheater": "Wheater",
      "Climatic Index": "Climatic Index",
      "Satellite": "Satellite",
      "Prescription Map": "Prescription Map",
      "Sensors": "Sensors",
      "Summary": "Summary",
      "Analysis": "Analysis",
      "Forecast": "Forecast",
      "Anomaly Detection": "Anomaly Detection",
      "Comparation": "Comparation",
      "Allert": "Allert",
      "Bio Trap": "Bio Trap",
      "Agronomic tools": "Agronomic tools",
      "Nutrient Balance": "Nutrient Balance",
      "Soil Data": "Soil Data",
      "Irrigation": "Irrigation",
      "Pest Model": "Pest Model",
      "Artificial Intelligence": "Artificial Intelligence",
      "Digital Consulting": "Digital Consulting",
      "Crop Models & simulations": "Crop Models & simulations",
      "Soil Mapping & Carbon Credits": "Soil Mapping & Carbon Credits",
      "Organic vs Convenctional": "Organic vs Convenctional",
      "Connections & Data Sharing": "Connections & Data Sharing",
      "Upgrade your profile": "Upgrade your profile",
      "Register a new farmer": "Register a new farmer",
      "Farmer Company Name:": "Farmer Company Name:",
      "Farmer First Name:": "Farmer First Name:",
      "Farmer Surname:": "Farmer Surname:",
      "Farmer Country:": "Farmer Country:",
      "Farmer City:": "Farmer City:",
      "Farmer Adress:": "Farmer Adress:",
      "Farmer Email:": "Farmer Email:",
      "Farmer Phone Number:": "Farmer Phone Number:",
      "Create Farm": "Create Farm",
      "Go back": "Go back",
      "Restart Creation": "Restart Creation",
      "Creating the farm...": "Creating the farm...",
      "Farm Registered": "Farm Registered",
      "No farmer are registered": "No farmer are registered",
      "If you want to start using this application, you should start by registering a new farmer.": "If you want to start using this application, you should start by registering a new farmer.",
      "Farm Registred": "Farm Registred",
      "Farm Name": "Farm Name",
      "Surname": "Surname",
      "Email": "Email",
      "Phone Number": "Phone Number",
      "Edit": "Edit",
      "Delete": "Delete",
      "Are you sure to remove the following farmer?": "Are you sure to remove the following farmer?",
      "Name of the farmer:": "Name of the farmer:",
      "Surname of the farmer:": "Surname of the farmer:",
      "Country of the farmer:": "Country of the farmer:",
      "City of the farmer:": "City of the farmer:",
      "Adress of the farmer:": "Adress of the farmer:",
      "Email of the farmer:": "Email of the farmer:",
      "Phone Number of the farmer:": "Phone Number of the farmer:",
      "Delete Farmer": "Delete Farmer",
      "Invalid email address": "Invalid email address",
      "Invalid phone number": "Invalid phone number",
      "Add Farm": "Add Farm",
      "Farmer Registred": "Farmer Registred",
      "Farmer": "Farmer",
      "No registered farm": "No registered farm",
      "Loading": "Loading",
      "Update Farm": "Update Farm",
      "Updataing the farm...": "Updataing the farm...",
      "The Name of the farmer is empty": "The Name of the farmer is empty",
      "The Surname of the farmer is empty": "The Surname of the farmer is empty",
      "The CountryCode of the farmer is empty": "The CountryCode of the farmer is empty",
      "The City of the farmer is empty": "The City of the farmer is empty",
      "The Adress of the farmer is empty": "The Adress of the farmer is empty",
      "The Email of the farmer is empty": "The Email of the farmer is empty",
      "The Phone Number of the farmer is empty": "The Phone Number of the farmer is empty",
      "An error occured while updating the farm. if the problem persist please contact us at automaticfarmsolution@gmail.com": "An error occured while updating the farm. if the problem persist please contact us at automaticfarmsolution@gmail.com",
      "Create Field": "Create Field",
      "You don't have any farm registred, first you have to set at least one farmer": "You don't have any farm registred, first you have to set at least one farmer",
      "Field name:": "Field name:",
      "Crop Rotation:": "Crop Rotation:",
      "Crop Rotation": "Crop Rotation",
      "Perennial crop": "Perennial crop",
      "Two year crop rotation": "Two year crop rotation",
      "Three year crop rotation": "Three year crop rotation",
      "Actual Crop": "Actual Crop",
      "Alfalfa": "Alfalfa",
      "Barley": "Barley",
      "Maize": "Maize",
      "Durum Wheat": "Durum Wheat",
      "The next year Crop": "The next year Crop",
      "The crop in two years' time": "The crop in two years' time",
      "Organic": "Organic",
      "Convenctional": "Convenctional",
      "Draw the edge of your field:": "Draw the edge of your field:",
      "The field name is empty": "The field name is empty",
      "Please draw the edge of your field in the map": "Please draw the edge of your field in the map",
      "An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com": "An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com",
      "List of the field": "List of the field",
      "Field Name:": "Field Name:",
      "Field Detail": "Field Detail",
      "Crop:": "Crop:",
      "Agronomic Management:": "Agronomic Management:",
      "Are you sure to delete this field?": "Are you sure to delete this field?",
      "Delete Field": "Delete Field",
      "Field:": "Campo:",
      "Loading...": "Loading...",
      "Edit Field": "Edit Field",
      "Draw again the edge of your field:": "Draw again the edge of your field:",
      "In red there is the old field": "In red there is the old field",
      "An error occured while editing the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com": "An error occured while editing the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com",
      "Try Again": "Try Again",
      "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com",
      "Select the field to analyze": "Select the field to analyze",
      "View Satellite Image": "View Satellite Image",
      "Field Details": "Field Details",
      "Actual Crop:": "Actual Crop:",
      "Time series plot": "Time series plot",
      "Satellite Image": "Satellite Image",
      "Select one field": "Select one field",
      "Field Boundary": "Field Boundary",
      "Select the Vegetation Index": "Select the Vegetation Index",
      "Set the prescription map": "Set the prescription map",
      "Do you want to define the number of zone in Automatically way or Manual?": "Do you want to define the number of zone in Automatically way or Manual?",
      "Automatic": "Automatic",
      "Manual": "Manual",
      "If is set Manual, how many zones do you want to create?": "If is set Manual, how many zones do you want to create?",
      "Max fertilizer that you want to apply?": "Max fertilizer that you want to apply?",
      "Which strategy do you want to use?": "Which strategy do you want to use?",
      "Provide higher amout of fertilizer where the crop is stronger": "Provide higher amout of fertilizer where the crop is stronger",
      "Provide lower amout of fertilizer where the crop is stronger": "Provide lower amout of fertilizer where the crop is stronger",
      "Percentage of difference of fertilizer between zones": "Percentage of difference of fertilizer between zones",
      "Selected Percentage:": "Selected Percentage:",
      "Create": "Create",
      "Send to Jhon Deere Operation Center": "Send to Jhon Deere Operation Center",
      "Download": "Download",
      "Downloading...": "Downloading...",
      "Downloading": "Downloading",
      "Sending": "Sending",
      "Sending...": "Sending...",
      "Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com": "Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com",
      "Creating the field...": "Creating the field...",
      "Update Field": "Update Field",
      "Updating Field": "Updating Field",
      "Integration done": "Integration done",
      "You have done the integration with jhon deere": "You have done the integration with jhon deere",
      "Start the integration with the Operation Center Jhon Deere": "Start the integration with the Operation Center Jhon Deere",
      "A problem occured during the integration with Jhon Deere": "A problem occured during the integration with Jhon Deere",
      "Start the integration with the Operation Center John Deere": "Start the integration with the Operation Center John Deere",
      "Connect to Operation Center Jhon Deere": "Connect to Operation Center Jhon Deere",
      "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center": "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center",
      "Satellite Image analysis": "Satellite Image analysis",
      "Please enable the geolocation in order to get the wheater data and visualization": "Please enable the geolocation in order to get the wheater data and visualization",
      "Actual Wheater Data": "Actual Wheater Data",
      "Wheater Map": "Wheater Map",
      "Select the climate variable to see": "Select the climate variable to see",
      "Forecast Temperature": "Forecast Temperature",
      "Wind": "Wind",
      "Pressure": "Pressure",
      "Snow": "Snow",
      "Precipitation": "Precipitation",
      "Clouds": "Clouds",
      "Temperature": "Temperature",
      "Add / Modify / Delete": "Add / Modify / Delete",
      "Field Registred": "Field Registred",
      "Field Name": "Field Name",
      "Select the field to view": "Select the field to view",
      "Number of farmer": "Number of farmer",
      "Number of field": "Number of field",
      "Hectares Monitored": "Hectares Monitored",
      "Anomalies": "Anomalies",
      "IoT Number": "IoT Number",
      "Last Reding": "Last Reding",
      "Soil Temperature": "Soil Temperature",
      "Soil Humidity": "Soil Humidity",
      "Electrical Conductivity": "Electrical Conductivity",
      "Organic Carbon": "Organic Carbon",
      "Nitrogen": "Nitrogen",
      "Phosporous": "Phosporous",
      "Potassium": "Potassium",
      "Battery": "Battery",
      "Select the variable": "Select the variable",
      "Select the IoT Cabin": "Select the IoT Cabin",
      "View data": "View data",
      "Time series Graph": "Time series Graph",
      "Time Series Comparation": "Time Series Comparation",
      "Table": "Table",
      "Add Allert": "Add Allert",
      "Allert Registred": "Allert Registred",
      "Sensor": "Sensor",
      "Variable": "Variable",
      "Condition": "Condition",
      "Value": "Value",
      "No Allert Registrated": "No Allert Registrated",
      "No IoT is linked to your account": "No IoT is linked to your account",
      "No IoT allert is setted": "No IoT allert is setted",
      "Please select the IoT Cabin": "Please select the IoT Cabin",
      "Please select the variable": "Please select the variable",
      "Please select the logic to apply": "Please select the logic to apply",
      "Please set the threshold value": "Please set the threshold value",
      "Select the logic": "Select the logic",
      "Set the threshold value": "Set the threshold value",
      "Adding the allert...": "Adding the allert...",
      "Higher than": "Higher than",
      "Lower than": "Lower than",
      "IoT Position": "IoT Position",
      "Create Allert": "Create Allert",
      "Are you sure to remove this allert?": "Are you sure to remove this allert?",
      "Cabin Number:": "Cabin Number:",
      "Logic - Higher or Lower:": "Logic - Higher or Lower:",
      "Threshold Value:": "Threshold Value:",
      "Variabile": "Variabile",
      "Delete Allert": "Delete Allert",
      "Update Allert": "Update Allert",
      "Updating the allert...": "Updating the allert...",
      "Please the expected yield (q/ha)": "Please the expected yield (q/ha)",
      "Please the fertilizer title (%)": "Please the fertilizer title (%)",
      "Setting of the Calculation": "Setting of the Calculation",
      "Select the Nutrient": "Select the Nutrient",
      "Select the Crop": "Select the Crop",
      "Set the expected yield (q/ha)": "Set the expected yield (q/ha)",
      "Title of fertilizer - range 0-100 %": "Title of fertilizer - range 0-100 %",
      "Calculate": "Calculate",
      "The amount of fertilizer that you have provide to the crop for the entire crop growth cycle is:": "The amount of fertilizer that you have provide to the crop for the entire crop growth cycle is:",
      "Set the field to get the soil data": "Set the field to get the soil data",
      "Hello, I'm your Digital Agronomist Consultant ! Ask me anything!": "Hello, I'm your Digital Agronomist Consultant ! Ask me anything!",
      "The digital agronomist consultant is typing": "The digital agronomist consultant is typing",
      "Send a Message": "Send a Message",
      "Digital agronomist consultant": "Digital agronomist consultant",
      "You have done the integration with John Deere": "You have done the integration with John Deere",
      "Please fill all the requests": "Please fill all the requests",
      "Set the analysis parameter": "Set the analysis parameter",
      "Date of sowing": "Date of sowing",
      "Select the soil type": "Select the soil type",
      "Sand": "Sand",
      "Loamy Sand": "Loamy Sand",
      "Sandy Loam": "Sandy Loam",
      "Loam": "Loam",
      "Silty Loam": "Silty Loam",
      "Silt": "Silt",
      "Sandy Clay Loam": "Sandy Clay Loam",
      "Clay Loam": "Clay Loam",
      "Silty Clay Loam": "Silty Clay Loam",
      "Sandy Clay": "Sandy Clay",
      "Clay": "Clay",
      "Sunflower": "Sunflower",
      "Millet": "Millet",
      "Rice": "Rice",
      "Sugarbeet": "Sugarbeet",
      "Sorghum": "Sorghum",
      "Soybean": "Soybean",
      "Canola": "Canola",
      "Tomato": "Tomato",
      "Cabbage": "Cabbage",
      "Potato": "Potato",
      "Sugarcane": "Sugarcane",
      "Select the Variety": "Select the Variety",
      "Do you want to provide a fertilization?": "Do you want to provide a fertilization?",
      "Date of fertilization": "Date of fertilization",
      "Define the amount of Nitrogen provided (kg N / ha)": "Define the amount of Nitrogen provided (kg N / ha)",
      "Define the amount of Phosphorus provided (kg P / ha)": "Define the amount of Phosphorus provided (kg P / ha)",
      "Define the amount of Potassium provided (kg K / ha)": "Define the amount of Potassium provided (kg K / ha)",
      "Do you want to provide a irrigation": "Do you want to provide a irrigation",
      "Date of irrigation": "Date of irrigation",
      "Define the amount of water mm": "Define the amount of water mm",
      "Next": "Next",
      "Submit": "Submit",
      "Temporal creation of the yield": "Temporal creation of the yield",
      "Before you can run the model you should define the crop type. Go to the Field and update section the information": "Before you can run the model you should define the crop type. Go to the Field and update section the information",
      "Set the Field where apply the analysis": "Set the Field where apply the analysis",
      "Set the Farmer": "Set the Farmer",
      "Set the Field": "Set the Field",
      "Run The Analysis": "Run The Analysis",
      "NDVI Comparation": "NDVI Comparation",
      "SAR Comparation": "SAR Comparation",
      "Probabilistic Map": "Probabilistic Map",
      "% Convenctional": "% Convenctional",
      "% Organic": "% Organic",
      "The field was declared by the farmer as": "The field was declared by the farmer as",
      "Select the start year to analyze": "Select the start year to analyze",
      "Select the base temperature for the crop": "Select the base temperature for the crop",
      "Select the max temperature for the crop": "Select the max temperature for the crop",
      "Start analysis": "Start analysis",
      "Upgrade your account": "Upgrade your account",
      "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO": "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO",
      "Not Now": "Not Now",
      "Upgrade your account to PRO": "Upgrade your account to PRO",
      "Your Acccount is PRO": "Your Acccount is PRO",
      "You can access to all the service": "You can access to all the service",
      "Your Acccount is not PRO": "Your Acccount is not PRO",
      "Purchase the PRO Service": "Purchase the PRO Service",
      "The total price is": "The total price is",
      "The payment was successful": "The payment was successful",
      "Now you should logout and login again to access all the features just unlocked": "Now you should logout and login again to access all the features just unlocked",
      "By purchasing this service you will access :": "By purchasing this service you will access :",
      "Prescription maps that can be integrated with your display tractors": "Prescription maps that can be integrated with your display tractors",
      "Visualization & Analysis of sensor data": "Visualization & Analysis of sensor data",
      "Set allert": "Set allert",
      "All the agronomic tools": "All the agronomic tools",
      "AI Tools": "AI Tools",
      "Synchronization with Operation Center Jhon Deere": "Synchronization with Operation Center Jhon Deere",
      "Durum_wheat": "Durum Wheat",
      "Vineyards": "Vineyards",
      "perennial": "Perennial",
      "biennial": "Biennal",
      "triennial": "Triennial",
      "Kiwi": "Kiwi",
      "Garlic": "Garlic",
      "Apricot": "Apricot",
      "Orange": "Orange",
      "Asparagus": "Asparagus",
      "Oats": "Oats",
      "Sugar_beet": "Sugar beet",
      "Basil": "Basil",
      "Swiss_chard": "Swiss chard",
      "Beet_leaves": "Beet leaves",
      "Broccoli": "Broccoli",
      "Hemp": "Hemp",
      "Artichoke": "Artichoke",
      "Carrot": "Carrot",
      "Cauliflower": "Cauliflower",
      "chickpea": "Chickpea",
      "cucumber": "Cucumber",
      "Chicory": "Chicory",
      "Cherry": "Cherry",
      "Onion": "Onion",
      "Endive": "Endive",
      "Watermelon": "Watermelon",
      "Rapeseed": "Rapeseed",
      "Green_bean_for_industry": "Green bean for industry",
      "Fresh_green_bean": "Fresh green bean",
      "Bean": "Bean",
      "Dry_bean": "Dry bean",
      "Spelt": "Spelt",
      "Grain_fava_bean": "Grain fava bean",
      "Favino": "Favino",
      "Fico": "Fig",
      "Fennel": "Fennel",
      "Strawberry": "Strawberry",
      "winter_wheat": "Winter wheat",
      "Kaki": "Persimmon",
      "Lettuce": "Lettuce",
      "Lentil": "Lentil",
      "Lemon": "Lemon",
      "Linen": "Linen",
      "Lupin": "Lupin",
      "Corn": "Corn",
      "Tangerine": "Tangerine",
      "Almond": "Almond",
      "Eggplant": "Eggplant",
      "Apple": "Apple",
      "Melon": "Melon",
      "Blueberry": "Blueberry",
      "Nectarines": "Nectarines",
      "Kernel": "Kernel",
      "Walnut": "Walnut",
      "Olive": "Olive",
      "Bell_pepper": "Bell pepper",
      "Pear": "Pear",
      "Peach": "Peach",
      "Pea": "Pea",
      "Leek": "Leek",
      "Parsley": "Parsley",
      "Radicchio": "Radicchio",
      "Turnip": "Turnip",
      "Radish": "Radish",
      "Ribes": "Currant",
      "Shallots": "Shallots",
      "Celery": "Celery",
      "Rye": "Rye",
      "Soy": "Soy",
      "Spinach": "Spinach",
      "Plum_tree": "Plum tree",
      "Tabacco": "Tobacco",
      "Triticale": "Triticale",
      "Vineyard": "Vineyard",
      "Savoy_cabbage": "Savoy cabbage",
      "Pumpkin": "Pumpkin",
      "Zucchini_for_industry": "Zucchini for industry",
      "Fresh_zucchini": "Fresh zucchini",
      "Peanuts": "Peanuts",
      "Chard": "Swiss chard",
      "Leaf_beet": "Beet leaves",
      "Hemp_for_seeds": "Hemp (seeds)",
      "Hemp_for_fiber": "Hemp (fiber)",
      "Cardoon": "Cardoon",
      "Chestnut": "Chestnut",
      "Cherry_tree": "Cherry tree",
      "French_bean": "Fresh green bean",
      "Fava_bean": "Dry bean",
      "Wheat": "Wheat",
      "Raspberry": "Raspberry",
      "Flax": "Linen",
      "Ley_grass": "Lupin",
      "Corn_for_grain": "Eggplant",
      "Hazelnut": "Hazelnut",
      "Pear_tree": "Pear tree",
      "Peach_tree": "Peach tree",
      "Protein_pea": "Protein pea",
      "Processing_tomato": "Processing tomato",
      "Table_tomato": "Table tomato",
      "Table_grape": "Table grape",
      "Gooseberry": "Gooseberry",
      "Grapevine": "Grapevine",
      "Clover": "Clover",
      "Corn_salad": "Corn salad",
      "Grapevine_for_wine_grapes": "Grapevine for wine grapes",
      "Sweet_corn": "Sweet corn",
      "Silage_corn": "Silage corn",
      "Oilseed_rape": "Oilseed rape",
      "Field-grown_bell_pepper": "Field-grown bell pepper",
      "Pea_for_industry": "Pea for industry",
      "Fresh_pea": "Fresh pea",
      "Greenhouse_table_tomato": "Greenhouse table tomato",
      "Clover_grassland": "Clover grassland",
      "Hill_pastures": "Hill pastures",
      "Mixed_grassland_>50_legumes": "Mixed grassland >50% legumes",
      "Artificial_mixed_hill_pastures": "Artificial mixed hill pastures",
      "Stable_plains": "Stable plains",
      "Silage_fodder": "Silage fodder",
      "Arugula_1st_cut": "Arugula, 1st cut",
      "Arugula_2nd_cut": "Arugula, 2nd cut",
      "Shallot": "Shallot",
      "Arugula_in_open_field": "Arugula in open field",
      "Phosphorus": "Phosphorus",
      "Organic vs Conventional:": "Organic vs Conventional:",
      "Are you sure to edit the polygon? if yes press again the Update Field button": "Are you sure to edit the polygon? if yes press again the Update Field button",
      "Set the Year of analysis": "Set the Year of analysis",
      "Set the nutrient to visualize": "Set the nutrient to visualize",
      "Carbonates": "Carbonates",
      "Soil Electrical Conductivity": "Soil Electrical Conductivity",
      "pH": "pH",
      "Please set the year": "Please set the year",
      "Please set the nutrient": "Please set the nutrient",
      "Please set the farmer": "Please set the farmer",
      "Please set the field": "Please set the field",
      "The map is not ready yet": "The map is not ready yet",
      "Please select the object of your request": "Please select the object of your request",
      "Please report a valid message": "Please report a valid message",
      "Send us your message": "Send us your message",
      "Object of the request:": "Object of the request:",
      "Object:": "Object:",
      "Support Request": "Support Request",
      "Upgrade account": "Upgrade account",
      "Problem with the application": "Problem with the application",
      "Other": "Other",
      "Message of the request:": "Message of the request:",
      "Message:": "Message:",
      "Send message": "Send message",
      "My account": "My account",
      "Do you want to upgrade your account?": "Do you want to upgrade your account?",
      "Send us a message": "Send us a message",
      "Your Message have been sent with success": "Your Message have been sent with success",
      "As soon as possible one of our operator will check your request and answer you": "As soon as possible one of our operator will check your request and answer you",
      "Frumento_tenero": "Winter Wheat",
      "Winter Wheat": "Winter Wheat",
      "Annual percentage increase": "Annual percentage increase",
      "Tons of CO2 Sequestered": "Tons of CO2 Sequestered",
      "Carbon Credits Generated": "Carbon Credits Generated",
      "Soil Map": "Soil Map",
      "Temporal Variation": "Temporal Variation",
      "If you want to access to this module data, you must upgrade your account to PRO":"If you want to access to this module data, you must upgrade your account to PRO",

      "Large Scale Application":"Large Scale Application",
      "Field Name":"Field Name",
      "Crop":"Crop",
      "Anomaly":"Anomaly",
      "Declared":"Declared",
      "Class":"Class",
      "Percentage":"Percentage",

      "Hectares Deleted":"Hectares Deleted",

      "Search City:":"Search City:",
      "Export Document":"Export Document",
      "Download Section":"Download Section",
      "Download Field - Format ShapeFile":"Download Field - Format ShapeFile",
      "Download Field - Format Excel":"Download Field - Format Excel",
      "Download Farmer Data - Format Excel":"Download Farmer Data - Format Excel",

      "Create a farm" : "Create a farm",
      "Create a field" : "Create a field",
      "Delete Account":"Delete Account",
      "Are you sure to delete your account?":"Are you sure to delete your account?",
      "Are you sure to delete the account?":"Are you sure to delete the account?",
      "Please be carefull cause if you delete your account all the data related will be removed":"Please be carefull cause if you delete your account all the data related will be removed",
      "If you want to proceed, please report your email ":"If you want to proceed, please report your email ",
      " in the following form input": " in the following form input",
      "Deleting the user...":"Deleting the user...",
      "Delete account":"Delete account",
      "In order to register you should accept the Terms And Conditions":"In order to register you should accept the Terms And Conditions",
      "Upload Field":"Upload Field",
      "Upload Zip File where within there is the shapefile with all the fields":"Upload Zip File where within there is the shapefile with all the fields",
      "Create Fields":"Create Fields"
    }
  },
  "IT": {
    "translation": {
      "Hello": "Ciao",
      "Yes":"Si",
      "No":"No",
      "The email is empty": "Il campo email è vuoto",
      "The password is empty": "Il campo password è vuoto",
      "The user is not active!": "L'utente non è attivo",
      "The email is used": "L'email è già in uso",
      "Forgot password?": "Password dimenticata",
      "Create new account": "Crea un nuovo account",
      "Check your email inbox and follow the instructions to change the password.": "Ti abbiamo appena inviato una email, segui le istruzione in essa per poter cambiare la password",
      "Go back to Login": "Torna al login",
      "The email doesn't exist in our database!": "L'email non esiste nel nostro databases",
      "Change Password": "Cambia la password",
      "Send Email": "Invia Email",
      "Sending email...": "Ti stiamo inviando una email",
      "The first password is empty": "La prima password è vuota",
      "The confirm password is empty": "La password di conferma è vuota",
      "The password don't match": "Le due password non combaciano",
      "Password change failed. If the problem persist, please send us an email at automaticfarmsolution@gmail.com": "Cambio password fallito. Se il problema persiste, contattaci al automaticfarmsolution@gmail.com",
      "An error occurred while changing the password. If the problem persist, please send us an email at automaticfarmsolution@gmail.com": "Un errore si è manifestando durante il cambio della password. Se il problema persiste, contattaci al automaticfarmsolution@gmail.com",
      "Confirm Password": "Conferma Password",
      "Changing Password...": "Cambiando la password",
      "The password has been changed with success. Now use the new password to login.": "La password è stata modificata con successo, ora puoi fare login con le nuove credenziali",
      "In order to register you should accept the Privacy Policy": "Per poterti registrare devi accettare la nostra privacy policy",
      "In order to register you should provide your name": "Per poterti registrare devi fornire il tuo nome",
      "In order to register you should provide a valid email": "Per poterti registrare devi fornire una email valida",
      "The current email is used, please use another one!": "L'email inserita è già presente nel nostro database. Usa una email diversa",
      "Registration": "Registrazione",
      "Name": "Nome",
      "I agree with the": "Accetto la",
      "Register": "Registrami",
      "Registration...": "Registrazione in corso",
      "Already have an account?": "Hai già un account?",
      "Check your email inbox and follow the instructions to activate your account.": "Guarda la tua email inbox e segui le istruzioni per attivare il tuo account",
      "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Attivazione del tuo account è fallito. Se il problema persiste, per favore contattaci ad automaticfarmsolution@gmail.com",
      "An error occurred during the activation fo your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Un errore si è mostrato durante l'attivazione del tuo account. Se il problema persiste, contattaci al automaticfarmsolution@gmail.com",
      "Do you want to activate your account?": "Vuoi attivare il tuo account?",
      "Activate my account": "Attiva il mio account",
      "Activation...": "Attivazione in corso...",
      "Your Account has been activated. Now You can login": "Il tuo account è stato attivato con successo. Ora puoi fare il login.",
      "Welcome!": "Benvenuto!",
      "My profile": "Profilo",
      "Settings": "Impostazioni",
      "Support": "Supporto",
      "Logout": "Logout",
      "Farm": "Azienda agricola",
      "Field": "Campi",
      "Field Notebook": "Quaderno di campagna",
      "Agrometeo": "Agrometeo",
      "Wheater": "Meteo",
      "Climatic Index": "Indici Climatici",
      "Satellite": "Satellite",
      "Prescription Map": "Mappa di Prescrizione",
      "Sensors": "Sensori",
      "Summary": "Sommario",
      "Analysis": "Analisi",
      "Forecast": "Previsioni",
      "Anomaly Detection": "Individuazione di anomalie",
      "Comparation": "Comparation",
      "Allert": "Allerte",
      "Bio Trap": "Trappole biologiche",
      "Agronomic tools": "Strumenti agronomici",
      "Nutrient Balance": "Bilancio dei nutrienti",
      "Irrigation": "Irrigazione",
      "Pest Model": "Modelli previsionali",
      "Artificial Intelligence": "Intelligenza Artificiale",
      "Digital Consulting": "Consulenza digitale",
      "Crop Models & simulations": "Modelli colturali & Simulazioni",
      "Soil Mapping & Carbon Credits": "Mappatura del suolo & Crediti di carbonio",
      "Connections & Data Sharing": "Connesioni & Condivisione dei dati",
      "Upgrade your profile": "Migliora il tuo abbonamento",
      "Register a new farmer": "Registra una nuova azienda",
      "Farmer company name:": "Nome dell'Azienda:",
      "Farmer Company Name:": "Nome dell'Azienda:",
      "Farmer First Name:": "Nome del proprietario",
      "Farmer Surname:": "Cognome del proprietario",
      "Farmer Country:": "Stato",
      "Farmer City:": "Comune",
      "Farmer Adress:": "Indirizzo",
      "Farmer Email:": "Email",
      "Farmer Phone Number:": "Numero telefonico",
      "Create Farm": "Crea l'azienda",
      "Go back": "Torna indietro",
      "Restart Creation": "Ricomincia",
      "Creating the farm...": "Creazione dell'azienda",
      "Farm Registered": "Aziende registrate",
      "No farmer are registered": "Nessuna azienda è stata registrata",
      "If you want to start using this application, you should start by registering a new farmer.": "Se vuoi inziare ad usare l'applicazione, dovresti registrare una azienda agricola",
      "Farm Registred": "Aziende Registrate",
      "Farm Name": "Nome dell'azienda",
      "Surname": "Cognome del proprietario",
      "Email": "Email",
      "Phone Number": "Numero telefonico",
      "Edit": "Modifica",
      "Delete": "Elimina",
      "Are you sure to remove the following farmer?": "Sei sicuro di voler eliminare l'azienda?",
      "Name of the farmer:": "Nome del proprietario",
      "Surname of the farmer:": "Cognome del proprietario",
      "Country of the farmer:": "Stato",
      "City of the farmer:": "Comune dell'azienda",
      "Adress of the farmer:": "Indirizzo dell'azienda",
      "Email of the farmer:": "Email dell'azienda",
      "Phone Number of the farmer:": "Numero dell'azienda",
      "Delete Farmer": "Elimina l'azienda",
      "Invalid email address": "Email non valida",
      "Invalid phone number": "Numero di telefono non valido",
      "Add Farm": "Aggiungi Azienda Agricola",
      "Farmer Registred": "Aziende Agricole Registrate",
      "Farmer": "Azienda agricola",
      "No registered farm": "Nessuna azienda agricola registrata",
      "Loading": "Caricamento",
      "Update Farm": "Modifica azienda agricola",
      "Updataing the farm...": "Modificando l'azienda agricola",
      "The farmer company name is empty": "Il nome dell'azienda è vuoto",
      "The Name of the farmer is empty": "Il nome del proprietario dell'azienda agricola è vuoto",
      "The Surname of the farmer is empty": "Il cognome del proprietario dell'azienda agricola è vuoto",
      "The CountryCode of the farmer is empty": "Lo stato è vuoto",
      "The City of the farmer is empty": "Il comune è vuoto",
      "The Adress of the farmer is empty": "L'indirizzo è vuoto",
      "The Email of the farmer is empty": "L'email è vuota",
      "The Phone Number of the farmer is empty": "Il numero telefonico è vuoto",
      "An error occured while updating the farm. if the problem persist please contact us at automaticfarmsolution@gmail.com": "Un errore si è evidenziato mentre stavamo aggiornando l'azienda. Se il problema persiste, per favore contattaci al automaticfarmsolution@gmail.com",
      "Create Field": "Crea Campo",
      "You don't have any farm registred, first you have to set at least one farmer": "Non hai nessuna azienda agricola registrata, prima di tutto crea almeno una azienda agricola",
      "Field name:": "Nome del campo:",
      "Crop Rotation:": "Rotazione delle colture:",
      "Perennial crop": "Coltura perenne",
      "Two year crop rotation": "Rotazione Biennale",
      "Three year crop rotation": "Rotazione triennale",
      "Actual Crop": "Coltura attuale",
      "Alfalfa": "Erba medica",
      "Vineyards": "Vigneto",
      "Barley": "Orzo",
      "Sunflower": "Girasole",
      "Durum Wheat": "Frumento Duro",
      "The next year Crop": "La coltura nell'anno successivo",
      "The crop in two years' time": "La coltura fra 2 anni",
      "Organic vs Convenctional": "Biologico o Convenzionale",
      "Organic": "Biologico",
      "Convenctional": "Convenzionale",
      "Draw the edge of your field:": "Disegna i confini del campo",
      "The field name is empty": "Il nome del campo è vuoto",
      "Please draw the edge of your field in the map": "Disegna il confine del tuo campo sulla mappa",
      "An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com": "Un errore si è manifestato durante la creazione del campo. Se il problema persiste, invaci una email al automaticfarmsolution@gmail.com",
      "List of the field": "Lista dai campi",
      "Field Name:": "Nome del campo:",
      "Field Detail": "Dettaglio del campo",
      "Crop:": "Coltura:",
      "Agronomic Management:": "Gestione agronomica:",
      "Are you sure to delete this field?": "Sei sicuro di voler eliminare il campo?",
      "Delete Field": "Elimina il campo",
      "Field:": "Campo:",
      "Edit Field": "Modifica il campo",
      "Draw again the edge of your field:": "Disegna di nuovi il contorno del tuo campo",
      "In red there is the old field": "In rosso c'è il vecchio campo",
      "An error occured while editing the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com": "Un errore si è manifestato durante la modificazione del campo. Se il problema persiste, invaci una email al automaticfarmsolution@gmail.com",
      "Try Again": "Prova ancora",
      "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Un errore si è manifestato durante la richiesta dei dati. Per favore fai il refresh della pagina e se il problema persiste per favore contattaci al automaticfarmsolution@gmail.com",
      "Select the field to analyze": "Seleziona il campo da analizzare",
      "View Satellite Image": "Guarda l'immagine satellitare",
      "Field Details": "Dettaglio del campo",
      "Actual Crop:": "Coltura attuale:",
      "Time series plot": "Grafico Temporale",
      "Satellite Image": "Immagine satellitare",
      "Select one field": "Seleziona un campo",
      "Field Boundary": "Confine del campo",
      "Set the prescription map": "Imposta la mappa di prescrizione",
      "Do you want to define the number of zone in Automatically way or Manual?": "Vuoi definire il numero delle zone in maniera automatica o manuale?",
      "Automatic": "Automaticamente",
      "Manual": "Manualmente",
      "If is set Manual, how many zones do you want to create?": "Se in manuale, quante zone vorresti creare?",
      "Max fertilizer that you want to apply?": "Quantità massima di fertilizzante da somministrare",
      "Which strategy do you want to use?": "Quale strategia vuoi usare?",
      "Provide higher amout of fertilizer where the crop is stronger": "Fornire maggiore concime dove la coltura è più vigorosa",
      "Provide lower amout of fertilizer where the crop is stronger": "Fornire minore concime dove la coltura è meno vigorosa",
      "Percentage of difference of fertilizer between zones": "Differenza percentuale di concime fra le zone",
      "Selected Percentage:": "Seleziona la percentuale",
      "Create": "Crea",
      "Loading...": "Caricamento in corso",
      "Send to Jhon Deere Operation Center": "Invia la mappa su Jhon Deere Operation Center",
      "Download": "Scarica",
      "Downloading...": "Scaricamento in corso",
      "Downloading": "Scaricamento in corso",
      "Sending": "Invio in corso",
      "Sending...": "Invio in corso",
      "Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com": "Un errore si è manifestato, se il problema persiste per favore contattaci al automaticfarmsolution@gmail.com",
      "Creating the field...": "Stiamo creando il campo... attendere",
      "Update Field": "Aggiorna il campo",
      "Updating Field": "Stiamo salvando il campo... Attendere",
      "Integration done": "Integrazione eseguita con successo",
      "You have done the integration with jhon deere": "Hai completato l'integrazione con jhon deere",
      "Start the integration with the Operation Center Jhon Deere": "Inizia l'integrazione con Jhon Deere",
      "Select the Vegetation Index": "Seleziona l'indice vegetazionale",
      "A problem occured during the integration with Jhon deere": "Un problema si è manifestato con l'integrazione con Jhon Deere",
      "Please try again, and if the problem persists please send us an email to automaticfarmsolution@gmail.com": "Prova ancora ad eseguire l'integrazione. Se il problema persiste contattaci al automaticfarmsolution@gmail.com",
      "You don't have any farm registered, first you have to set at least one farmer": "Non hai registrato alcuna azienda agricola, per poter sincronizzare Jhon Deere prima devi avere almeno una azienda sincronizzata",
      "Start the integration with the Operation Center John Deere": "Inizia l'integrazione con Jhon Deere",
      "Connect to Operation Center Jhon Deere": "Connetti a Operation Center Jhon Deere",
      "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center": "Se vuoi inviare le mappe di prescrizione all'operation center, puoi connettere questa piattaforma ad Operation Center",
      "Satellite Image analysis": "Analisi delle immagini satellitari",
      "Please enable the geolocation in order to get the wheater data and visualization": "Per poter visualizzare i dati meteo devi attivare la geolocalizzazione",
      "Actual Wheater Data": "Dati meteo attuali",
      "Wheater Map": "Mappa del meteo",
      "Select the climate variable to see": "Seleziona la variabile climatica da visualizzare",
      "Forecast Temperature": "Previsioni della temperatura",
      "Wind": "Vento",
      "Pressure": "Pressione atmosferica",
      "Snow": "Neve",
      "Precipitation": "Precipitazioni",
      "Clouds": "Nuvolosità",
      "Temperature": "Temperatura",
      "Add / Modify / Delete": "Aggiungi / Modfica / Elimina",
      "Field Registred": "Campi Registrati",
      "Field Name": "Nome del campo",
      "Crop Rotation": "Rotazione Colturale",
      "Select the field to view": "Seleziona il campo da visualizzare",
      "Number of farmer": "Numero delle aziende agricole",
      "Number of field": "Numero dei campi",
      "Hectares Monitored": "Ettari Monitorati",
      "Anomalies": "Anomalie",
      "IoT Number": "Numero IoT",
      "Last Reding": "Ultima Lettura",
      "Soil Temperature": "Temperatura del suolo",
      "Soil Humidity": "Umidità del suolo",
      "Electrical Conductivity": "Condubilità Elettrica",
      "Organic Carbon": "Carbonio Organico",
      "Nitrogen": "Azoto",
      "Phosporous": "Fosforo",
      "Potassium": "Potassio",
      "Battery": "Batteria",
      "Select the variable": "Seleziona la variabile",
      "Select the IoT Cabin": "Seleziona la cabina da analizzare",
      "View data": "Visualizza i dati",
      "Time series Graph": "Grafico Temporale",
      "Time Series Comparation": "Comparazione",
      "Table": "Tabella",
      "Add Allert": "Aggiungi Allerta",
      "Allert Registred": "Allerta Registrate",
      "Sensor": "Sensore",
      "Variable": "Variabile",
      "Condition": "Condizione",
      "Value": "Valore",
      "No Allert Registrated": "Nessuna allerta registrata",
      "No IoT is linked to your account": "Nessun IoT è legato al tuo account",
      "No IoT allert is setted": "Nessuna allerta è stata impostata",
      "Please select the IoT Cabin": "Seleziona una cabina",
      "Please select the variable": "Seleziona una variabile",
      "Please select the logic to apply": "Seleziona la logica da impostare",
      "Please set the threshold value": "Definisci il valore soglia",
      "Select the logic": "Seleziona la logica",
      "Set the threshold value": "Setta il valore soglia",
      "Adding the allert...": "Stiamo aggiungendo l'allerta...",
      "Higher than": "Maggiore di",
      "Lower than": "Minore di",
      "IoT Position": "Posizione dell'IoT",
      "Create Allert": "Crea allerta",
      "Are you sure to remove this allert?": "Sei sicuro di voler eliminare questa allerta?",
      "Cabin Number:": "Cabina IoT Numero:",
      "Logic - Higher or Lower:": "Logico - Maggior di o Minore di:",
      "Threshold Value:": "Valore Soglia",
      "Variabile": "Variabile",
      "Delete Allert": "Elimin allerta",
      "Update Allert": "Modifica l'allerta",
      "Updating the allert...": "Stiamo aggiornando l'allerta",
      "Please the expected yield (q/ha)": "Per favore setta la resa attesa (q/ha)",
      "Please the fertilizer title (%)": "Per favore setta il titolo del fertilizzante (%)",
      "Setting of the Calculation": "Setta i dati di calcolo",
      "Select the Nutrient": "Seleziona il nutriente",
      "Select the Crop": "Seleziona la coltura",
      "Set the expected yield (q/ha)": "Seleziona la resa attesa (q/ha)",
      "Title of fertilizer - range 0-100 %": "Titolo del fertilizzante - Range 0-100%",
      "Calculate": "Calcola",
      "The amount of fertilizer that you have provide to the crop for the entire crop growth cycle is:": "La quantità totale di concime da fornire alla coltura per l'intero ciclo di crescita è:",
      "Set the field to get the soil data": "Seleziona il campo dal quale vuoi avere i dati del suolo",
      "Soil Data": "Dati del suolo",
      "Hello, I'm your Digital Agronomist Consultant ! Ask me anything!": "Ciao, sono il tuo agronomo digitale ! Chiedemi qualcosa!",
      "The digital agronomist consultant is typing": "L'agronomo digitale sta scrivendo!",
      "Send a Message": "Invia il messaggio",
      "Digital agronomist consultant": "Consulente digitale agronomico",
      "You have done the integration with John Deere": "Hai già completato la sincronizzazione con Jhon Deere",
      "Please fill all the requests": "Per favore compila ogni elemento",
      "Set the analysis parameter": "Definisci i parametri dell'analisi",
      "Date of sowing": "Data di semina",
      "Select the soil type": "Seleziona il tipo di suolo",
      "Sand": "Sabbia",
      "Loamy Sand": "Limoso Sabbioso",
      "Sandy Loam": "Sabbioso Limoso",
      "Loam": "Limoso",
      "Silty Loam": "Molto limoso",
      "Silt": "Limoso",
      "Sandy Clay Loam": "Sabbiso argilloso",
      "Clay Loam": "Argilloso Limoso",
      "Silty Clay Loam": "Franco",
      "Sandy Clay": "Sabbioso Argilloso",
      "Clay": "Argilloso",
      "Maize": "Mais",
      "Millet": "Miglio",
      "Rice": "Riso",
      "Sugarbeet": "Barbabietola da zucchero",
      "Sorghum": "Sorgo",
      "Soybean": "Soia",
      "Canola": "Colza",
      "Tomato": "Pomodoro",
      "Cabbage": "Cavolo",
      "Potato": "Patata",
      "Sugarcane": "Canna da zucchero",
      "Select the Variety": "Seleziona la variatà",
      "Do you want to provide a fertilization?": "Vuoi fornire del fertilizzante alla coltura?",
      "Date of fertilization": "Data della fertilizzazione",
      "Define the amount of Nitrogen provided (kg N / ha)": "Determina l'ammontare di azoto da fornire alla coltura (kg N / ha)",
      "Define the amount of Phosphorus provided (kg P / ha)": "Determina l'ammontate di fosforo da fornire alla coltura (kg P / ha)",
      "Define the amount of Potassium provided (kg K / ha)": "Determina l'ammontare di potassio da fornire alla coltura (kg K/ ha)",
      "Do you want to provide a irrigation": "Vuoi fornire dell'acqua tramite l'irrigazione",
      "Date of irrigation": "Data dell'apporto dell'irrigazione",
      "Define the amount of water mm": "Determina l'ammontare di acqua da fornire mm",
      "Next": "Avanti",
      "Submit": "Invia",
      "Temporal creation of the yield": "Creazione temporale della resa",
      "Before you can run the model you should define the crop type. Go to the Field and update section the information": "Prima di poter lanciare i modelli di AI, devi determinare la tipologia di coltura. Vai nella sezione Campi e aggiorna le informazioni dei campi",
      "Set the Field where apply the analysis": "Definisci il campo sul quale eseguire l'analisi",
      "Set the Farmer": "Azienda Agricola",
      "Set the Field": "Campo",
      "Run The Analysis": "Esegui l'analisi",
      "NDVI Comparation": "Comparazione NDVI",
      "SAR Comparation": "Comparazione SAR",
      "Probabilistic Map": "Mappa Probabilistica",
      "% Convenctional": "% Convenzionale",
      "% Organic": "% Biologico",
      "The field was declared by the farmer as": "Il campo era stato dichiarato dall'agricoltore come",
      "Select the start year to analyze": "Seleziona l'anno dal quale iniziare per eseguire l'analisi",
      "Select the base temperature for the crop": "Seleziona la temperatura di base della tua coltura",
      "Select the max temperature for the crop": "Seleziona la temperatura massima della coltura",
      "Start analysis": "Inizia l'analisi",
      "Upgrade your account": "Aggiorna il tuo account",
      "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO": "Se vuoi accedere alle immagini satellitari e generare le mappe di prescrizione devi prima aggiornare il tuo account",
      "Not Now": "Non Ora",
      "Upgrade your account to PRO": "Sali di livello",
      "Your Acccount is PRO": "Il tuo account è PRO",
      "You can access to all the service": "Puoi accedere a tutti i servizi dell'applicazione",
      "Your Acccount is not PRO": "Il tuo account non è PRO",
      "Purchase the PRO Service": "Acquista il servizio PRO",
      "The total price is": "Il prezzo totale è di:",
      "The payment was successful": "Il pagamento è avvenuto con successo",
      "Now you should logout and login again to access all the features just unlocked": "Ora dovresti fare il logout dall'applicazione e rintrare nuovamente per poter accedere a tutti i nuovi servizi sbloccati per te",
      "By purchasing this service you will access :": "Acquistando questo servizio potrai accedere a:",
      "Prescription maps that can be integrated with your display tractors": "Mappe di prescrizione compatibili con tutti i display dei trattori",
      "Visualization & Analysis of sensor data": "Visuallizzare & Analizzare i dati dei sensori",
      "Set allert": "Definire delle allerte",
      "All the agronomic tools": "Tutti i strumenti agronomici",
      "AI Tools": "Strumenti di intelligenza artificiale",
      "Synchronization with Operation Center Jhon Deere": "Sincronizzazione con l'operation center di Jhon Deere",
      "Durum_wheat": "Grano duro",
      "perennial": "Perenne",
      "biennial": "Biennale",
      "triennial": "Triennale",
      "Kiwi": "Kiwi",
      "Garlic": "Aglio",
      "Apricot": "Albicocca",
      "Orange": "Arancia",
      "Asparagus": "Asparago",
      "Oats": "Avena",
      "Sugar_beet": "Barbabietola da zucchero",
      "Basil": "Basilico",
      "Swiss_chard": "Bietola",
      "Beet_leaves": "Foglie di barbabietola",
      "Broccoli": "Broccoli",
      "Hemp": "Canapa",
      "Artichoke": "Carciofo",
      "Carrot": "Carota",
      "Cauliflower": "Cavolfiore",
      "chickpea": "Ceci",
      "cucumber": "Cetriolo",
      "Chicory": "Cicoria",
      "Cherry": "Ciliegia",
      "Onion": "Cipolla",
      "Endive": "Cicoria scarola",
      "Watermelon": "Anguria",
      "Rapeseed": "Colza",
      "Green_bean_for_industry": "Fagiolino per l'industria",
      "Fresh_green_bean": "Fagiolino fresco",
      "Bean": "Fagiolo",
      "Dry_bean": "Fagiolo secco",
      "Spelt": "Farro",
      "Grain_fava_bean": "Fava",
      "Favino": "Favino",
      "Fico": "Fico",
      "Fennel": "Finocchio",
      "Strawberry": "Fragola",
      "winter_wheat": "Grano invernale",
      "Kaki": "Kaki",
      "Lettuce": "Lattuga",
      "Lentil": "Lenticchia",
      "Lemon": "Limone",
      "Linen": "Lino",
      "Lupin": "Lupino",
      "Corn": "Mais",
      "Tangerine": "Mandarino",
      "Almond": "Mandorla",
      "Eggplant": "Melanzana",
      "Apple": "Mela",
      "Melon": "Melone",
      "Blueberry": "Mirtillo",
      "Nectarines": "Nettarine",
      "Kernel": "Nocciola",
      "Walnut": "Noce",
      "Olive": "Oliva",
      "Bell_pepper": "Peperone",
      "Pear": "Pera",
      "Peach": "Pesca",
      "Pea": "Pisello",
      "Leek": "Porro",
      "Parsley": "Prezzemolo",
      "Radicchio": "Radicchio",
      "Turnip": "Rapa",
      "Radish": "Ravanello",
      "Ribes": "Ribes",
      "Shallots": "Scalogno",
      "Celery": "Sedano",
      "Rye": "Segale",
      "Soy": "Soia",
      "Spinach": "Spinacio",
      "Plum_tree": "Susino",
      "Tabacco": "Tabacco",
      "Triticale": "Triticale",
      "Vineyard": "Vigneto",
      "Savoy_cabbage": "Verza",
      "Pumpkin": "Zucca",
      "Zucchini_for_industry": "Zucchina per l'industria",
      "Fresh_zucchini": "Zucchina fresca",
      "Peanuts": "Arachidi",
      "Chard": "Bietola",
      "Leaf_beet": "Foglie di barbabietola",
      "Hemp_for_seeds": "Canapa (semi)",
      "Hemp_for_fiber": "Canapa (fibra)",
      "Cardoon": "Cardo",
      "Chestnut": "Castagna",
      "Chickpea": "Ceci",
      "Cucumber": "Cetriolo",
      "Cherry_tree": "Albero di ciliegio",
      "French_bean": "Fagiolo verde",
      "Fava_bean": "Fava",
      "Wheat": "Grano",
      "Raspberry": "Lampone",
      "Flax": "Lino",
      "Ley_grass": "Erba medica perenne",
      "Corn_for_grain": "Mais da cereali",
      "Hazelnut": "Nocciola",
      "Pear_tree": "Albero di pero",
      "Peach_tree": "Albero di pesco",
      "Protein_pea": "Pisello proteico",
      "Processing_tomato": "Pomodoro da industria",
      "Table_tomato": "Pomodoro da tavola",
      "Table_grape": "Uva da tavola",
      "Gooseberry": "Uva spina",
      "Grapevine": "Vite",
      "Clover": "Trifoglio",
      "Corn_salad": "Valeriana",
      "Grapevine_for_wine_grapes": "Vite per uva da vino",
      "Sweet_corn": "Mais dolce",
      "Silage_corn": "Mais insilato",
      "Oilseed_rape": "Colza da olio",
      "Field-grown_bell_pepper": "Peperone coltivato in campo",
      "Pea_for_industry": "Pisello per l'industria",
      "Fresh_pea": "Pisello fresco",
      "Greenhouse_table_tomato": "Pomodoro da tavola in serra",
      "Clover_grassland": "Prato di trifoglio",
      "Hill_pastures": "Pascoli di collina",
      "Mixed_grassland_>50_legumes": "Pascolo misto con >50% leguminose",
      "Artificial_mixed_hill_pastures": "Pascoli artificiali misti di collina",
      "Stable_plains": "Pianure stabili",
      "Silage_fodder": "Fieno insilato",
      "Arugula_2nd_cut": "Rucola, 2° taglio",
      "Shallot": "Scalogno",
      "Arugula_in_open_field": "Rucola in campo aperto",
      "Arugula_1st_cut": "Rucola, 1° taglio",
      "Phosphorus": "Fosforo",
      "Organic vs Conventional:": "Biologico vs Convenzionale:",
      "Are you sure to edit the polygon? if yes press again the Update Field button": "Sei sicuro di voler aggiornare questo campo? Se sei sicuro premi di nuovo il pulsante verde aggiorna il campo",
      "Set the Year of analysis": "Anno di analisi",
      "Set the nutrient to visualize": "Nutriente da visualizzare",
      "Carbonates": "Carbonati",
      "Soil Electrical Conductivity": "Conducibilità elettrica",
      "pH": "pH",
      "Please set the year": "Prima di eseguire l'analisi definisci l'anno di analisi",
      "Please set the nutrient": "Prima di eseguire l'analisi definisci il nutriente di analisi",
      "Please set the farmer": "Prima di eseguire l'analisi definisci l'azienda agricola di analisi",
      "Please set the field": "Prima di eseguire l'analisi definisci il campo",
      "The map is not ready yet": "La mappa non è ancora pronta",
      "Please select the object of your request": "Seleziona l'oggetto della richiesta",
      "Please report a valid message": "Scrivi un messaggio valido",
      "Send us your message": "Inviaci un messaggio",
      "Object of the request:": "Oggetto della richiesta:",
      "Object:": "Oggetto:",
      "Support Request": "Richiesta di supporto",
      "Upgrade account": "Sali di livello",
      "Problem with the application": "Problema con l'applicazione",
      "Other": "Altro",
      "Message of the request:": "Messaggio della richiesta:",
      "Message:": "Messaggio:",
      "Send message": "Invia il messaggio",
      "My account": "Il mio account",
      "Do you want to upgrade your account?": "Vuoi salire di livello?",
      "Send us a message": "Inviaci un messaggio",
      "Your Message have been sent with success": "Il tuo messaggio è stato inviato con successo",
      "As soon as possible one of our operator will check your request and answer you": "Presto un nostro operatore prenderà in carico la richiesta e ti risponderà il prima possibile",
      "Frumento_tenero": "Frumento Tenero",
      "Winter Wheat": "Frumento Tenero",
      "Annual percentage increase": "Aumento Percentuale Annuo",
      "Tons of CO2 Sequestered": "Tonnelate di CO2 sequestrate",
      "Carbon Credits Generated": "Crediti di carbonio generati",
      "Soil Map": "Mappa del suolo",
      "Temporal Variation": "Variazione Temporale",
      "If you want to access to this module data, you must upgrade your account to PRO":"Se vuoi accedere a questo modulo, devi fare l'Upgrade del tuo account",

      "Large Scale Application":"Applicazione a larga scala",
      "Field Name":"Nome del campo",
      "Crop":"Coltura",
      "Anomaly":"Anomalia",
      "Declared":"Dichiarato",
      "Class":"Classe",
      "Percentage":"Percentuale",

      "Hectares Deleted":"Ettari eliminati",

      "Search City:":"Cerca la città",
      "Export Document":"Esportazione Dati",
      "Download Section":"Sezione download",
      "Download Field - Format ShapeFile":"Download Campi - Formato ShapeFile",
      "Download Field - Format Excel":"Download Campi - Formato Excel",
      "Download Farmer Data - Format Excel":"Download Dati Aziende Agricole - Formato Excel",
      
      "The crop field is empty":"Il campo delle colture è vuoto",

      "Create a farm" : "Crea una azienda",
      "Create a field" : "Crea un campo",
      "Delete Account":"Elimina l'account",
      "Are you sure to delete your account?":"Sei sicuro di eliminare l'account?",
      "Are you sure to delete the account?":"Sei sicuro di eliminare l'account?",
      "Please be carefull cause if you delete your account all the data related will be removed":"Perfavore presta attenzione, se elimini il tuo account, tutti i dati relativi al tuo account saranno eliminati per sempre",
      "If you want to proceed, please report your email ":"Se vuoi procedere, riporta la tua email ",
      " in the following form input": " nel seguente form",
      "Deleting the user...":"Eliminando l'utente...",
      "Delete account":"Elimina l'account",
      "In order to register you should accept the Terms And Conditions":"Per poterti registrare dei prima accettare i termini e condizioni",
      "Upload Field":"Upload Campi",
      "Upload Zip File where within there is the shapefile with all the fields":"Carica uno zipfile che al suo interno c'è uno shapefile dove ci sono tutti i campi che vuoi creare",
      "Create Fields":"Crea Campi"
      
    }
  },
  "ES": {
    "translation": {
      "Hello": "Hola",
      "Yes":"Si",
      "No":"No",
      "Phosporous": "Fósforo",
      "Farmer Registred": "Agricultor registrado",
      "Field Registred": "Campo registrado",
      "Durum_wheat": "Trigo duro",
      "Download data": "Descargar datos",
      "The email is empty": "El correo electrónico está vacío",
      "The password is empty": "La contraseña está vacía",
      "The user is not active!": "¡El usuario no está activo!",
      "The email is used": "El correo electrónico ya está en uso",
      "Forgot password?": "¿Olvidaste la contraseña?",
      "Create new account": "Crear nueva cuenta",
      "Check your email inbox and follow the instructions to change the password.": "Verifica tu bandeja de entrada de correo electrónico y sigue las instrucciones para cambiar la contraseña.",
      "Go back to Login": "Volver al inicio de sesión",
      "The email doesn't exist in our database!": "¡El correo electrónico no existe en nuestra base de datos!",
      "Change Password": "Cambiar contraseña",
      "Send Email": "Enviar correo electrónico",
      "Sending email...": "Enviando correo electrónico...",
      "The first password is empty": "La primera contraseña está vacía",
      "The confirm password is empty": "La confirmación de la contraseña está vacía",
      "The password don't match": "Las contraseñas no coinciden",
      "Password change failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Error al cambiar la contraseña. Si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "An error occurred while changing the password. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Se produjo un error al cambiar la contraseña. Si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "Confirm Password": "Confirmar contraseña",
      "Changing Password...": "Cambiando contraseña...",
      "The password has been changed with success. Now use the new password to login.": "La contraseña se ha cambiado con éxito. Ahora utiliza la nueva contraseña para iniciar sesión.",
      "In order to register you should accept the Privacy Policy": "Para registrarte, debes aceptar la Política de privacidad",
      "In order to register you should provide your name": "Para registrarte, debes proporcionar tu nombre",
      "In order to register you should provide a valid email": "Para registrarte, debes proporcionar un correo electrónico válido",
      "The current email is used, please use another one!": "El correo electrónico actual ya está en uso, por favor usa otro",
      "Registration": "Registro",
      "Name": "Nombre",
      "I agree with the": "Estoy de acuerdo con la",
      "Register": "Registrarse",
      "Registration...": "Registrando...",
      "Already have an account?": "¿Ya tienes una cuenta?",
      "Check your email inbox and follow the instructions to activate your account.": "Verifica tu bandeja de entrada de correo electrónico y sigue las instrucciones para activar tu cuenta.",
      "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "La activación de tu cuenta falló. Si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "An error occurred during the activation of your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Se produjo un error durante la activación de tu cuenta. Si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "Do you want to activate your account?": "¿Quieres activar tu cuenta?",
      "Activate my account": "Activar mi cuenta",
      "Activation...": "Activando...",
      "Your Account has been activated. Now you can login": "Tu cuenta ha sido activada. Ahora puedes iniciar sesión",
      "Welcome!": "¡Bienvenido!",
      "My profile": "Mi perfil",
      "Settings": "Configuraciones",
      "Support": "Soporte",
      "Logout": "Cerrar sesión",
      "Farm": "Granja",
      "Field": "Campo",
      "Field Notebook": "Cuaderno de campo",
      "Agrometeo": "Agrometeo",
      "Weather": "Clima",
      "Climatic Index": "Índice climático",
      "Satellite": "Satélite",
      "Prescription Map": "Mapa de prescripción",
      "Sensors": "Sensores",
      "Summary": "Resumen",
      "Analysis": "Análisis",
      "Forecast": "Pronóstico",
      "Anomaly Detection": "Detección de Anomalías",
      "Comparation": "Comparación",
      "Allert": "Alerta",
      "Bio Trap": "Trampa Biológica",
      "Agronomic tools": "Herramientas Agronómicas",
      "Nutrient Balance": "Balance de Nutrientes",
      "Soil Data": "Datos del Suelo",
      "Irrigation": "Riego",
      "Pest Model": "Modelo de Plagas",
      "Artificial Intelligence": "Inteligencia Artificial",
      "Digital Consulting": "Asesoramiento Digital",
      "Crop Models & simulations": "Modelos y Simulaciones de Cultivos",
      "Soil Mapping & Carbon Credits": "Mapeo de Suelos y Créditos de Carbono",
      "Organic vs Conventional": "Orgánico vs Convencional",
      "Connections & Data Sharing": "Conexiones y Compartir Datos",
      "Upgrade your profile": "Mejora tu perfil",
      "Register a new farmer": "Registrar un nuevo agricultor",
      "Farmer company name:": "Nombre de la Empresa del Agricultor:",
      "Farmer Company Name:": "Nombre de la Empresa del Agricultor:",
      "Farmer First Name:": "Nombre del Agricultor:",
      "Farmer Surname:": "Apellido del Agricultor:",
      "Farmer Country:": "País del Agricultor:",
      "Farmer City:": "Ciudad del Agricultor:",
      "Farmer Address:": "Dirección del Agricultor:",
      "Farmer Email:": "Correo Electrónico del Agricultor:",
      "Farmer Phone Number:": "Número de Teléfono del Agricultor:",
      "Create Farm": "Crear Granja",
      "Go back": "Volver",
      "Restart Creation": "Reiniciar Creación",
      "Creating the farm...": "Creando la granja...",
      "Farm Registered": "Granja Registrada",
      "No farmer are registered": "No hay agricultores registrados",
      "If you want to start using this application, you should start by registering a new farmer.": "Si deseas comenzar a utilizar esta aplicación, debes empezar registrando a un nuevo agricultor.",
      "Farm Name": "Nombre de la Granja",
      "Surname": "Apellido",
      "Email": "Correo Electrónico",
      "Phone Number": "Número de Teléfono",
      "Edit": "Editar",
      "Delete": "Eliminar",
      "Are you sure to remove the following farmer?": "¿Estás seguro de eliminar al siguiente agricultor?",
      "Name of the farmer:": "Nombre del agricultor:",
      "Surname of the farmer:": "Apellido del agricultor:",
      "Country of the farmer:": "País del agricultor:",
      "City of the farmer:": "Ciudad del agricultor:",
      "Address of the farmer:": "Dirección del agricultor:",
      "Email of the farmer:": "Correo Electrónico del agricultor:",
      "Phone Number of the farmer:": "Número de Teléfono del agricultor:",
      "Delete Farmer": "Eliminar Agricultor",
      "Invalid email address": "Dirección de correo electrónico no válida",
      "Invalid phone number": "Número de teléfono no válido",
      "Add Farm": "Agregar Granja",
      "Farmer Registered": "Agricultor Registrado",
      "Farmer": "Agricultor",
      "No registered farm": "No hay granjas registradas",
      "Loading": "Cargando",
      "Update Farm": "Actualizar Granja",
      "Updating the farm...": "Actualizando la granja...",
      "The Name of the farmer is empty": "El nombre del agricultor está vacío",
      "The Surname of the farmer is empty": "El apellido del agricultor está vacío",
      "The CountryCode of the farmer is empty": "El código de país del agricultor está vacío",
      "The City of the farmer is empty": "La ciudad del agricultor está vacía",
      "The Address of the farmer is empty": "La dirección del agricultor está vacía",
      "The Email of the farmer is empty": "El correo electrónico del agricultor está vacío",
      "The Phone Number of the farmer is empty": "El número de teléfono del agricultor está vacío",
      "An error occurred while updating the farm. If the problem persists, please contact us at automaticfarmsolution@gmail.com": "Se produjo un error al actualizar la granja. Si el problema persiste, contáctanos en automaticfarmsolution@gmail.com",
      "Create Field": "Crear Campo",
      "You don't have any farm registred, first you have to set at least one farmer": "No tienes ninguna granja registrada, primero debes configurar al menos un agricultor",
      "Field name:": "Nombre del campo:",
      "Crop Rotation:": "Rotación de cultivos:",
      "Crop Rotation": "Rotación de cultivos",
      "Perennial crop": "Cultivo perenne",
      "Two year crop rotation": "Rotación de cultivos de dos años",
      "Three year crop rotation": "Rotación de cultivos de tres años",
      "Actual Crop": "Cultivo actual",
      "Alfalfa": "Alfalfa",
      "Barley": "Cebada",
      "Maize": "Maíz",
      "Durum Wheat": "Trigo duro",
      "The next year Crop": "Cultivo del próximo año",
      "The crop in two years' time": "Cultivo en dos años",
      "Organic": "Orgánico",
      "Conventional": "Convencional",
      "Draw the edge of your field:": "Dibuja el borde de tu campo:",
      "The field name is empty": "El nombre del campo está vacío",
      "Please draw the edge of your field in the map": "Por favor, dibuja el borde de tu campo en el mapa",
      "An error occurred while creating the field. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Se produjo un error al crear el campo. Si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "List of the field": "Lista de campos",
      "Field Name:": "Nombre del campo:",
      "Field Detail": "Detalles del campo",
      "Crop:": "Cultivo:",
      "Agronomic Management:": "Gestión agronómica:",
      "Are you sure to delete this field?": "¿Estás seguro de eliminar este campo?",
      "Delete Field": "Eliminar Campo",
      "Field:": "Campo:",
      "Loading...": "Cargando...",
      "Edit Field": "Editar Campo",
      "Draw again the edge of your field:": "Dibuja nuevamente el borde de tu campo:",
      "In red there is the old field": "En rojo está el campo antiguo",
      "An error occurred while editing the field. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Se produjo un error al editar el campo. Si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "Try Again": "Intentar de nuevo",
      "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Algo salió mal. Por favor, actualiza la página y si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "Select the field to analyze": "Selecciona el campo para analizar",
      "View Satellite Image": "Ver imagen satelital",
      "Field Details": "Detalles del Campo",
      "Actual Crop:": "Cultivo actual:",
      "Time series plot": "Gráfico de series temporales",
      "Satellite Image": "Imagen satelital",
      "Select one field": "Selecciona un campo",
      "Field Boundary": "Límite del campo",
      "Select the Vegetation Index": "Selecciona el Índice de Vegetación",
      "Set the prescription map": "Configura el mapa de prescripción",
      "Do you want to define the number of zones in an Automatic way or Manual?": "¿Quieres definir el número de zonas de manera automática o manual?",
      "Automatic": "Automático",
      "Manual": "Manual",
      "If it is set to Manual, how many zones do you want to create?": "Si está configurado como Manual, ¿cuántas zonas deseas crear?",
      "Max fertilizer that you want to apply?": "¿Máxima cantidad de fertilizante que deseas aplicar?",
      "Which strategy do you want to use?": "¿Qué estrategia deseas utilizar?",
      "Provide higher amount of fertilizer where the crop is stronger": "Proporcionar una mayor cantidad de fertilizante donde el cultivo es más fuerte",
      "Provide lower amount of fertilizer where the crop is stronger": "Proporcionar una menor cantidad de fertilizante donde el cultivo es más fuerte",
      "Percentage of difference of fertilizer between zones": "Porcentaje de diferencia de fertilizante entre zonas",
      "Selected Percentage:": "Porcentaje seleccionado:",
      "Create": "Crear",
      "Send to Jhon Deere Operation Center": "Enviar al Centro de Operaciones Jhon Deere",
      "Download": "Descargar",
      "Downloading...": "Descargando...",
      "Downloading": "Descargando",
      "Sending": "Enviando",
      "Sending...": "Enviando...",
      "Something went wrong. If the problem persists please send us an email at automaticfarmsolution@gmail.com": "Algo salió mal. Si el problema persiste, por favor envíenos un correo electrónico a automaticfarmsolution@gmail.com",
      "Creating the field...": "Creando el campo...",
      "Update Field": "Actualizar Campo",
      "Updating Field": "Actualizando Campo",
      "Integration done": "Integración completada",
      "You have done the integration with Jhon Deere": "Ha completado la integración con Jhon Deere",
      "Start the integration with the Operation Center Jhon Deere": "Iniciar la integración con el Centro de Operaciones Jhon Deere",
      "A problem occurred during the integration with Jhon Deere": "Ocurrió un problema durante la integración con Jhon Deere",
      "Start the integration with the Operation Center John Deere": "Iniciar la integración con el Centro de Operaciones John Deere",
      "Connect to Operation Center Jhon Deere": "Conectar al Centro de Operaciones Jhon Deere",
      "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center": "Si desea enviar el mapa de prescripción al centro de operaciones, puede conectar esta plataforma directamente a su Centro de Operaciones",
      "Satellite Image analysis": "Análisis de imagen por satélite",
      "Please enable the geolocation in order to get the weather data and visualization": "Por favor, habilite la geolocalización para obtener datos meteorológicos y visualización",
      "Actual Weather Data": "Datos meteorológicos actuales",
      "Weather Map": "Mapa meteorológico",
      "Select the climate variable to see": "Seleccione la variable climática para ver",
      "Forecast Temperature": "Temperatura Pronosticada",
      "Wind": "Viento",
      "Pressure": "Presión",
      "Snow": "Nieve",
      "Precipitation": "Precipitación",
      "Clouds": "Nubes",
      "Temperature": "Temperatura",
      "Add / Modify / Delete": "Agregar / Modificar / Eliminar",
      "Field Registered": "Campo Registrado",
      "Field Name": "Nombre del Campo",
      "Select the field to view": "Seleccione el campo para ver",
      "Number of farmer": "Número de agricultores",
      "Number of field": "Número de campos",
      "Hectares Monitored": "Hectáreas Monitoreadas",
      "Anomalies": "Anomalías",
      "IoT Number": "Número de IoT",
      "Last Reading": "Última Lectura",
      "Soil Temperature": "Temperatura del Suelo",
      "Soil Humidity": "Humedad del Suelo",
      "Electrical Conductivity": "Conductividad Eléctrica",
      "Organic Carbon": "Carbono Orgánico",
      "Nitrogen": "Nitrógeno",
      "Phosphorus": "Fósforo",
      "Potassium": "Potasio",
      "Battery": "Batería",
      "Select the variable": "Seleccione la variable",
      "Select the IoT Cabin": "Seleccione la Cabina IoT",
      "View data": "Ver datos",
      "Time series Graph": "Gráfico de series temporales",
      "Time Series Comparison": "Comparación de series temporales",
      "Table": "Tabla",
      "Add Alert": "Agregar Alerta",
      "Alert Registered": "Alerta Registrada",
      "Sensor": "Sensor",
      "Variable": "Variable",
      "Condition": "Condición",
      "Value": "Valor",
      "Number of farmers": "Número de agricultores",
      "Number of fields": "Número de campos",
      "No Alert Registered": "Ninguna Alerta Registrada",
      "No IoT is linked to your account": "No hay IoT vinculado a su cuenta",
      "No IoT alert is set": "No hay alerta de IoT configurada",
      "Please select the IoT Cabin": "Por favor, seleccione la Cabina IoT",
      "Please select the variable": "Por favor, seleccione la variable",
      "Please select the logic to apply": "Seleccione la lógica a aplicar",
      "Please set the threshold value": "Por favor, establezca el valor umbral",
      "Select the logic": "Seleccionar la lógica",
      "Set the threshold value": "Establecer el valor umbral",
      "Adding the alert...": "Añadiendo la alerta...",
      "Higher than": "Mayor que",
      "Lower than": "Menor que",
      "IoT Position": "Posición del IoT",
      "Create Alert": "Crear alerta",
      "Are you sure to remove this alert?": "¿Está seguro de eliminar esta alerta?",
      "Cabin Number:": "Número de cabina:",
      "Logic - Higher or Lower:": "Lógica - Mayor o menor:",
      "Threshold Value:": "Valor umbral:",
      "Delete Alert": "Eliminar alerta",
      "Update Alert": "Actualizar alerta",
      "Updating the alert...": "Actualizando la alerta...",
      "Please the expected yield (q/ha)": "Por favor, introduzca el rendimiento esperado (q/ha)",
      "Please the fertilizer title (%)": "Por favor, introduzca el porcentaje de fertilizante",
      "Setting of the Calculation": "Configuración del cálculo",
      "Select the Nutrient": "Seleccione el nutriente",
      "Select the Crop": "Seleccione el cultivo",
      "Set the expected yield (q/ha)": "Establecer el rendimiento esperado (q/ha)",
      "Title of fertilizer - range 0-100 %": "Título del fertilizante - rango 0-100 %",
      "Calculate": "Calcular",
      "The amount of fertilizer that you have provided to the crop for the entire crop growth cycle is:": "La cantidad de fertilizante que ha proporcionado al cultivo durante todo el ciclo de crecimiento del cultivo es:",
      "Set the field to get the soil data": "Establecer el campo para obtener datos del suelo",
      "Hello, I'm your Digital Agronomist Consultant! Ask me anything!": "¡Hola, soy tu Consultor Agrónomo Digital! ¡Pregúntame lo que quieras!",
      "The digital agronomist consultant is typing": "El consultor agrónomo digital está escribiendo",
      "Send a Message": "Enviar un mensaje",
      "Digital agronomist consultant": "Consultor agrónomo digital",
      "You have done the integration with John Deere": "Has completado la integración con John Deere",
      "Please fill all the requests": "Por favor, complete todas las solicitudes",
      "Set the analysis parameter": "Establecer el parámetro de análisis",
      "Date of sowing": "Fecha de siembra",
      "Select the soil type": "Seleccione el tipo de suelo",
      "Sand": "Arena",
      "Loamy Sand": "Arena limosa",
      "Sandy Loam": "Franco arenoso",
      "Loam": "Franco",
      "Silty Loam": "Franco limoso",
      "Silt": "Limo",
      "Sandy Clay Loam": "Franco arcilloso arenoso",
      "Clay Loam": "Franco arcilloso",
      "Silty Clay Loam": "Franco arcilloso limoso",
      "Sandy Clay": "Arcilla arenosa",
      "Clay": "Arcilla",
      "Sunflower": "Girasol",
      "Millet": "Mijo",
      "Rice": "Arroz",
      "Sugarbeet": "Remolacha azucarera",
      "Sorghum": "Sorgo",
      "Soybean": "Soja",
      "Canola": "Colza",
      "Tomato": "Tomate",
      "Cabbage": "Col",
      "Potato": "Patata",
      "Sugarcane": "Caña de azúcar",
      "Select the Variety": "Seleccione la variedad",
      "Do you want to provide fertilization?": "¿Desea proporcionar fertilización?",
      "Date of fertilization": "Fecha de fertilización",
      "Define the amount of Nitrogen provided (kg N / ha)": "Defina la cantidad de nitrógeno proporcionada (kg N / ha)",
      "Define the amount of Phosphorus provided (kg P / ha)": "Defina la cantidad de fósforo proporcionada (kg P / ha)",
      "Define the amount of Potassium provided (kg K / ha)": "Defina la cantidad de potasio proporcionada (kg K / ha)",
      "Do you want to provide irrigation": "¿Desea proporcionar riego?",
      "Date of irrigation": "Fecha de riego",
      "Define the amount of water mm": "Defina la cantidad de agua en mm",
      "Next": "Siguiente",
      "Submit": "Enviar",
      "Temporal creation of the yield": "Creación temporal del rendimiento",
      "Before you can run the model you should define the crop type. Go to the Field and update section the information": "Antes de ejecutar el modelo, debes definir el tipo de cultivo. Ve a la sección 'Campo' y actualiza la información.",
      "Set the Field where apply the analysis": "Establecer el campo donde aplicar el análisis",
      "Set the Farmer": "Establecer el agricultor",
      "Set the Field": "Establecer el campo",
      "Run The Analysis": "Ejecutar el análisis",
      "NDVI Comparation": "Comparación de NDVI",
      "SAR Comparation": "Comparación de SAR",
      "Probabilistic Map": "Mapa Probabilístico",
      "% Convenctional": "% Convencional",
      "% Organic": "% Orgánico",
      "The field was declared by the farmer as": "El agricultor declaró el campo como",
      "Select the start year to analyze": "Selecciona el año de inicio para analizar",
      "Select the base temperature for the crop": "Selecciona la temperatura base para el cultivo",
      "Select the max temperature for the crop": "Selecciona la temperatura máxima para el cultivo",
      "Start analysis": "Iniciar el análisis",
      "Upgrade your account": "Mejora tu cuenta",
      "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO": "Si deseas acceder a los datos satelitales y generar el mapa de prescripción, debes mejorar tu cuenta a PRO",
      "Not Now": "Ahora no",
      "Upgrade your account to PRO": "Mejora tu cuenta a PRO",
      "Your Account is PRO": "Tu cuenta es PRO",
      "You can access to all the service": "Puedes acceder a todos los servicios",
      "Your Account is not PRO": "Tu cuenta no es PRO",
      "Purchase the PRO Service": "Compra el servicio PRO",
      "The total price is": "El precio total es",
      "The payment was successful": "El pago se realizó con éxito",
      "Now you should logout and login again to access all the features just unlocked": "Ahora debes cerrar sesión e iniciarla nuevamente para acceder a todas las funciones desbloqueadas",
      "By purchasing this service you will access:": "Al comprar este servicio, accederás a:",
      "Prescription maps that can be integrated with your display tractors": "Mapas de prescripción que se pueden integrar con tus tractores con pantalla",
      "Visualization & Analysis of sensor data": "Visualización y análisis de datos del sensor",
      "Set alert": "Configurar alerta",
      "All the agronomic tools": "Todas las herramientas agronómicas",
      "AI Tools": "Herramientas de IA",
      "Synchronization with Operation Center John Deere": "Sincronización con el Centro de Operaciones de John Deere",
      "Vineyards": "Viñedos",
      "perennial": "Perenne",
      "biennial": "Bienal",
      "triennial": "Trienal",
      "Kiwi": "Kiwi",
      "Garlic": "Ajo",
      "Apricot": "Albaricoque",
      "Orange": "Naranja",
      "Asparagus": "Espárragos",
      "Oats": "Avena",
      "Sugar_beet": "Remolacha azucarera",
      "Basil": "Albahaca",
      "Swiss_chard": "Acelga",
      "Beet_leaves": "Hojas de remolacha",
      "Broccoli": "Brócoli",
      "Hemp": "Cáñamo",
      "Artichoke": "Alcachofa",
      "Carrot": "Zanahoria",
      "Cauliflower": "Coliflor",
      "chickpea": "Garbanzo",
      "cucumber": "Pepino",
      "Chicory": "Achicoria",
      "Cherry": "Cereza",
      "Onion": "Cebolla",
      "Endive": "Endibia",
      "Watermelon": "Sandía",
      "Rapeseed": "Colza",
      "Green_bean_for_industry": "Judía verde para la industria",
      "Fresh_green_bean": "Judía verde fresca",
      "Bean": "Judía",
      "Dry_bean": "Judía seca",
      "Spelt": "Espelta",
      "Grain_fava_bean": "Haba de grano",
      "Favino": "Favino",
      "Fico": "Higo",
      "Fennel": "Hinojo",
      "Strawberry": "Fresa",
      "winter_wheat": "Trigo de invierno",
      "Kaki": "Caqui",
      "Lettuce": "Lechuga",
      "Lentil": "Lentejas",
      "Lemon": "Limón",
      "Linen": "Lino",
      "Lupin": "Altramuz",
      "Corn": "Maíz",
      "Tangerine": "Mandarina",
      "Almond": "Almendra",
      "Eggplant": "Berenjena",
      "Apple": "Manzana",
      "Melon": "Melón",
      "Blueberry": "Arándano",
      "Nectarines": "Nectarina",
      "Kernel": "Nuez",
      "Walnut": "Nuez",
      "Olive": "Oliva",
      "Bell_pepper": "Pimiento",
      "Pear": "Pera",
      "Peach": "Durazno",
      "Pea": "Chícharo",
      "Leek": "Puerro",
      "Parsley": "Perejil",
      "Radicchio": "Radicchio",
      "Turnip": "Nabo",
      "Radish": "Rábano",
      "Ribes": "Grosella",
      "Shallots": "Chalote",
      "Celery": "Apio",
      "Rye": "Centeno",
      "Soy": "Soja",
      "Spinach": "Espinaca",
      "Plum_tree": "Ciruelo",
      "Tabacco": "Tabaco",
      "Triticale": "Triticale",
      "Vineyard": "Viñedo",
      "Savoy_cabbage": "Col rizada de Saboya",
      "Pumpkin": "Calabaza",
      "Zucchini_for_industry": "Calabacín para la industria",
      "Fresh_zucchini": "Calabacín fresco",
      "Peanuts": "Cacahuetes",
      "Chard": "Acelga",
      "Leaf_beet": "Hojas de remolacha",
      "Hemp_for_seeds": "Cáñamo (semillas)",
      "Hemp_for_fiber": "Cáñamo (fibra)",
      "Cardoon": "Cardo",
      "Chestnut": "Castaño",
      "Cherry_tree": "Cerezo",
      "French_bean": "Judía verde fresca",
      "Fava_bean": "Haba seca",
      "Wheat": "Trigo",
      "Raspberry": " Frambuesa",
      "Flax": "Lino",
      "Ley_grass": "Lupino",
      "Corn_for_grain": "Berenjena",
      "Hazelnut": "Avellana",
      "Pear_tree": "Peral",
      "Peach_tree": "Duraznero",
      "Protein_pea": "Guisante proteico",
      "Processing_tomato": "Tomate para procesar",
      "Table_tomato": "Tomate de mesa",
      "Table_grape": "Uva de mesa",
      "Gooseberry": "Grosella espinosa",
      "Grapevine": "Viña",
      "Clover": "Trébol",
      "Corn_salad": "Ensalada de maíz",
      "Grapevine_for_wine_grapes": "Viña para uvas de vino",
      "Sweet_corn": "Maíz dulce",
      "Silage_corn": "Maíz para ensilaje",
      "Oilseed_rape": "Colza oleaginosa",
      "Field-grown_bell_pepper": "Pimiento cultivado en campo",
      "Pea_for_industry": "Guisante para la industria",
      "Fresh_pea": "Guisante fresco",
      "Greenhouse_table_tomato": "Tomate de mesa de invernadero",
      "Clover_grassland": "Pastizal de trébol",
      "Hill_pastures": "Pastizales de colina",
      "Mixed_grassland_>50_legumes": "Pastizal mixto >50% leguminosas",
      "Artificial_mixed_hill_pastures": "Pastizales mixtos artificiales de colina",
      "Stable_plains": "Llanuras estables",
      "Silage_fodder": "Forraje para ensilaje",
      "Arugula_1st_cut": "Rúcula, 1ª cosecha",
      "Arugula_2nd_cut": "Rúcula, 2ª cosecha",
      "Shallot": "Chalota",
      "Arugula_in_open_field": "Rúcula en campo abierto",
      "Organic vs Conventional:": "Orgánico vs Convencional:",
      "Are you sure to edit the polygon? if yes press again the Update Field button": "¿Está seguro de editar el polígono? Si es así, presione nuevamente el botón Actualizar campo",
      "Set the Year of analysis": "Establecer el año de análisis",
      "Set the nutrient to visualize": "Establecer el nutriente a visualizar",
      "Carbonates": "Carbonatos",
      "Soil Electrical Conductivity": "Conductividad eléctrica del suelo",
      "pH": "pH",
      "Please set the year": "Por favor, establezca el año",
      "Please set the nutrient": "Por favor, establezca el nutriente",
      "Please set the farmer": "Por favor, establezca el agricultor",
      "Please set the field": "Por favor, establezca el campo",
      "The map is not ready yet": "El mapa aún no está listo",
      "Please select the object of your request": "Seleccione el objeto de su solicitud",
      "Please report a valid message": "Informe un mensaje válido",
      "Send us your message": "Envíenos su mensaje",
      "Object of the request:": "Objeto de la solicitud:",
      "Object:": "Objeto:",
      "Support Request": "Solicitud de soporte",
      "Upgrade account": "Actualizar cuenta",
      "Problem with the application": "Problema con la aplicación",
      "Other": "Otro",
      "Message of the request:": "Mensaje de la solicitud:",
      "Message:": "Mensaje:",
      "Send message": "Enviar mensaje",
      "My account": "Mi cuenta",
      "Do you want to upgrade your account?": "¿Desea actualizar su cuenta?",
      "Send us a message": "Envíenos un mensaje",
      "Your Message have been sent with success": "Su mensaje se ha enviado con éxito",
      "As soon as possible one of our operators will check your request and answer you": "Tan pronto como sea posible, uno de nuestros operadores verificará su solicitud y le responderá",
      "Frumento_tenero": "Trigo de invierno",
      "Winter Wheat": "Trigo de invierno",
      "Annual percentage increase": "Aumento porcentual anual",
      "Tons of CO2 Sequestered": "Toneladas de CO2 secuestradas",
      "Carbon Credits Generated": "Créditos de carbono generados",
      "Soil Map": "Mapa de suelo",
      "Temporal Variation": "Variación temporal",
      "If you want to access to this module data, you must upgrade your account to PRO":"Si desea acceder a los datos de este módulo, debe actualizar su cuenta a PRO",

      "Large Scale Application": "Aplicación a gran escala",
      "Field Name": "Nombre del campo",
      "Crop": "Cultivo",
      "Anomaly": "Anomalía",
      "Declared": "Declarado",
      "Class": "Clase",
      "Percentage": "Porcentaje",

      "Hectares Deleted":"Hectáreas Eliminadas",

      "Search City:":"Buscar ciudad:",
      "Export Document":"Exportar documento",
      "Download Section":"Sección de descarga",
      "Download Field - Format ShapeFile":"Descargar campo - Formato ShapeFile",
      "Download Field - Format Excel":"Descargar campo - Formato Excel",
      "Download Farmer Data - Format Excel":"Descargar datos de agricultores - Formato Excel",

      "The crop field is empty":"El campo de cultivo está vacío",

      "Create a farm" : "Crear una granja",
      "Create a field" : "Crear un campo",
      "Delete Account":"Eliminar cuenta",
      "Are you sure to delete your account?":"¿Estás seguro de eliminar tu cuenta?",
      "Are you sure to delete the account?":"¿Estás seguro de eliminar la cuenta?",
      "Please be carefull cause if you delete your account all the data related will be removed":"Por favor, ten cuidado porque si eliminas tu cuenta, todos los datos relacionados serán eliminados",
      "If you want to proceed, please report your email ":"Si deseas continuar, por favor, reporta tu correo electrónico ",
      " in the following form input": " en el siguiente campo de formulario",
      "Deleting the user...":"Eliminando el usuario...",
      "Delete account":"Eliminar el usario",
      "In order to register you should accept the Terms And Conditions":"Para registrarse debe aceptar los Términos y Condiciones",
      "Upload Field":"Upload Campi",
      "Upload Zip File where within there is the shapefile with all the fields":"Cargar un archivo zip que contiene un shapefile donde están todos los campos que quieres crear",
      "Create Fields":"Crear Campos"
    }
  },
  "FR": {
    "translation": {
      "Hello": "Bonjour",
      "Yes":"Oui",
      "No":"Non",
      "Phosporous":"Phosphore",
      "Number of farmer":"Nombre D'Agriculteurs",
      "Number of field":"Nombre de champs",
      "Farmer Registred": "Agriculteur enregistré",
      "Field Registred": "Champ enregistré",
      "Durum_wheat": "Blé dur",
      "Download data": "Télécharger les données",
      "The email is empty": "L'e-mail est vide",
      "The password is empty": "Le mot de passe est vide",
      "The user is not active!": "L'utilisateur n'est pas actif !",
      "The email is used": "L'e-mail est déjà utilisé",
      "Forgot password?": "Mot de passe oublié ?",
      "Create new account": "Créer un nouveau compte",
      "Check your email inbox and follow the instructions to change the password.": "Consultez votre boîte de réception électronique et suivez les instructions pour changer le mot de passe.",
      "Go back to Login": "Retour à la connexion",
      "The email doesn't exist in our database!": "L'e-mail n'existe pas dans notre base de données !",
      "Change Password": "Changer le mot de passe",
      "Send Email": "Envoyer un e-mail",
      "Sending email...": "Envoi de l'e-mail...",
      "The first password is empty": "Le premier mot de passe est vide",
      "The confirm password is empty": "La confirmation du mot de passe est vide",
      "The password don't match": "Les mots de passe ne correspondent pas",
      "Password change failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Échec du changement de mot de passe. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "An error occurred while changing the password. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Une erreur s'est produite lors du changement de mot de passe. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "Confirm Password": "Confirmer le mot de passe",
      "Changing Password...": "Changement de mot de passe...",
      "The password has been changed with success. Now use the new password to login.": "Le mot de passe a été changé avec succès. Utilisez désormais le nouveau mot de passe pour vous connecter.",
      "In order to register you should accept the Privacy Policy": "Pour vous inscrire, vous devez accepter la politique de confidentialité",
      "In order to register you should provide your name": "Pour vous inscrire, vous devez fournir votre nom",
      "In order to register you should provide a valid email": "Pour vous inscrire, vous devez fournir une adresse e-mail valide",
      "The current email is used, please use another one!": "L'e-mail actuel est déjà utilisé, veuillez en utiliser un autre",
      "Registration": "Inscription",
      "Name": "Nom",
      "I agree with the": "Je suis d'accord avec le",
      "Register": "S'inscrire",
      "Registration...": "Inscription...",
      "Already have an account?": "Vous avez déjà un compte ?",
      "Check your email inbox and follow the instructions to activate your account.": "Consultez votre boîte de réception électronique et suivez les instructions pour activer votre compte.",
      "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "L'activation de votre compte a échoué. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "An error occurred during the activation of your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Une erreur s'est produite lors de l'activation de votre compte. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "Do you want to activate your account?": "Voulez-vous activer votre compte ?",
      "Activate my account": "Activer mon compte",
      "Activation...": "Activation...",
      "Your Account has been activated. Now You can login": "Votre compte a été activé. Vous pouvez maintenant vous connecter",
      "Welcome!": "Bienvenue !",
      "My profile": "Mon profil",
      "Settings": "Paramètres",
      "Support": "Support",
      "Logout": "Déconnexion",
      "Farm": "Ferme",
      "Field": "Champ",
      "Field Notebook": "Carnet de champ",
      "Agrometeo": "Agrométéo",
      "Weather": "Météo",
      "Climatic Index": "Indice climatique",
      "Satellite": "Satellite",
      "Prescription Map": "Carte de prescription",
      "Sensors": "Capteurs",
      "Summary": "Résumé",
      "Analysis": "Analyse",
      "Forecast": "Prévision",
      "Anomaly Detection": "Détection d'anomalies",
      "Comparation": "Comparaison",
      "Allert": "Alerte",
      "Bio Trap": "Piège Biologique",
      "Agronomic tools": "Outils Agronomiques",
      "Nutrient Balance": "Bilan des Nutriments",
      "Soil Data": "Données du Sol",
      "Irrigation": "Irrigation",
      "Pest Model": "Modèle de Ravageurs",
      "Artificial Intelligence": "Intelligence Artificielle",
      "Digital Consulting": "Conseil Numérique",
      "Crop Models & simulations": "Modèles et Simulations de Cultures",
      "Soil Mapping & Carbon Credits": "Cartographie des Sols et Crédits Carbone",
      "Organic vs Conventional": "Bio vs Conventionnel",
      "Connections & Data Sharing": "Connexions et Partage de Données",
      "Upgrade your profile": "Mettre à niveau votre profil",
      "Register a new farmer": "Enregistrer un nouveau fermier",
      "Farmer company name:": "Nom de l'entreprise agricole :",
      "Farmer Company Name:": "Nom de l'entreprise agricole :",
      "Farmer First Name:": "Prénom du fermier :",
      "Farmer Surname:": "Nom de famille du fermier :",
      "Farmer Country:": "Pays du fermier :",
      "Farmer City:": "Ville du fermier :",
      "Farmer Address:": "Adresse du fermier :",
      "Farmer Email:": "E-mail du fermier :",
      "Farmer Phone Number:": "Numéro de téléphone du fermier :",
      "Create Farm": "Créer une ferme",
      "Go back": "Retour",
      "Restart Creation": "Recommencer la création",
      "Creating the farm...": "Création de la ferme...",
      "Farm Registered": "Ferme enregistrée",
      "No farmer are registered": "Aucun fermier n'est enregistré",
      "If you want to start using this application, you should start by registering a new farmer.": "Si vous voulez commencer à utiliser cette application, commencez par enregistrer un nouveau fermier.",
      "Farm Name": "Nom de la ferme",
      "Surname": "Nom de famille",
      "Email": "E-mail",
      "Phone Number": "Numéro de téléphone",
      "Edit": "Modifier",
      "Delete": "Supprimer",
      "Are you sure to remove the following farmer?": "Êtes-vous sûr de vouloir supprimer le fermier suivant ?",
      "Name of the farmer:": "Nom du fermier :",
      "Surname of the farmer:": "Nom de famille du fermier :",
      "Country of the farmer:": "Pays du fermier :",
      "City of the farmer:": "Ville du fermier :",
      "Address of the farmer:": "Adresse du fermier :",
      "Email of the farmer:": "E-mail du fermier :",
      "Phone Number of the farmer:": "Numéro de téléphone du fermier :",
      "Delete Farmer": "Supprimer le fermier",
      "Invalid email address": "Adresse e-mail invalide",
      "Invalid phone number": "Numéro de téléphone invalide",
      "Add Farm": "Ajouter une ferme",
      "Farmer Registered": "Fermier enregistré",
      "Farmer": "Fermier",
      "No registered farm": "Aucune ferme enregistrée",
      "Loading": "Chargement",
      "Update Farm": "Mettre à jour la ferme",
      "Updating the farm...": "Mise à jour de la ferme...",
      "The Name of the farmer is empty": "Le nom du fermier est vide",
      "The Surname of the farmer is empty": "Le nom de famille du fermier est vide",
      "The CountryCode of the farmer is empty": "Le code pays du fermier est vide",
      "The City of the farmer is empty": "La ville du fermier est vide",
      "The Address of the farmer is empty": "L'adresse du fermier est vide",
      "The Email of the farmer is empty": "L'e-mail du fermier est vide",
      "The Phone Number of the farmer is empty": "Le numéro de téléphone du fermier est vide",
      "An error occurred while updating the farm. If the problem persists, please contact us at automaticfarmsolution@gmail.com": "Une erreur s'est produite lors de la mise à jour de la ferme. Si le problème persiste, veuillez nous contacter à automaticfarmsolution@gmail.com",
      "Create Field": "Créer un champ",
      "You don't have any farm registred, first you have to set at least one farmer": "Vous n'avez aucune ferme enregistrée, vous devez d'abord configurer au moins un agriculteur",
      "Field name:": "Nom du champ :",
      "Crop Rotation:": "Rotation des cultures :",
      "Crop Rotation": "Rotation des cultures",
      "Perennial crop": "Culture pérenne",
      "Two year crop rotation": "Rotation des cultures sur deux ans",
      "Three year crop rotation": "Rotation des cultures sur trois ans",
      "Actual Crop": "Culture actuelle",
      "Alfalfa": "Trèfle",
      "Barley": "Orge",
      "Maize": "Maïs",
      "Durum Wheat": "Blé dur",
      "The next year Crop": "Culture de l'année suivante",
      "The crop in two years' time": "Culture dans deux ans",
      "Organic": "Biologique",
      "Conventional": "Conventionnelle",
      "Draw the edge of your field:": "Dessinez le bord de votre champ :",
      "The field name is empty": "Le nom du champ est vide",
      "Please draw the edge of your field in the map": "Veuillez dessiner le bord de votre champ sur la carte",
      "An error occurred while creating the field. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Une erreur s'est produite lors de la création du champ. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "List of the field": "Liste des champs",
      "Field Name:": "Nom du champ :",
      "Field Detail": "Détail du champ",
      "Crop:": "Culture :",
      "Agronomic Management:": "Gestion agronomique :",
      "Are you sure to delete this field?": "Êtes-vous sûr de vouloir supprimer ce champ ?",
      "Delete Field": "Supprimer le champ",
      "Field:": "Champ :",
      "Loading...": "Chargement...",
      "Edit Field": "Modifier le champ",
      "Draw again the edge of your field:": "Dessinez à nouveau le bord de votre champ :",
      "In red there is the old field": "En rouge, il y a le champ ancien",
      "An error occurred while editing the field. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Une erreur s'est produite lors de l'édition du champ. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "Try Again": "Réessayer",
      "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Quelque chose s'est mal passé. Veuillez rafraîchir la page et si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "Select the field to analyze": "Sélectionnez le champ à analyser",
      "View Satellite Image": "Voir l'image satellite",
      "Field Details": "Détails du champ",
      "Actual Crop:": "Culture actuelle :",
      "Time series plot": "Graphique de séries temporelles",
      "Satellite Image": "Image satellite",
      "Select one field": "Sélectionnez un champ",
      "Field Boundary": "Limite du champ",
      "Select the Vegetation Index": "Sélectionnez l'indice de végétation",
      "Set the prescription map": "Définir la carte de prescription",
      "Do you want to define the number of zones in an Automatic way or Manual?": "Voulez-vous définir le nombre de zones de manière automatique ou manuelle ?",
      "Automatic": "Automatique",
      "Manual": "Manuel",
      "If it is set to Manual, how many zones do you want to create?": "Si c'est réglé sur Manuel, combien de zones souhaitez-vous créer ?",
      "Max fertilizer that you want to apply?": "Quelle quantité maximale d'engrais souhaitez-vous appliquer ?",
      "Which strategy do you want to use?": "Quelle stratégie souhaitez-vous utiliser ?",
      "Provide higher amount of fertilizer where the crop is stronger": "Fournir une plus grande quantité d'engrais là où la culture est plus forte",
      "Provide lower amount of fertilizer where the crop is stronger": "Fournir une plus petite quantité d'engrais là où la culture est plus forte",
      "Percentage of difference of fertilizer between zones": "Pourcentage de différence d'engrais entre les zones",
      "Selected Percentage:": "Pourcentage sélectionné :",
      "Create": "Créer",
      "Send to Jhon Deere Operation Center": "Envoyer au Centre d'Opérations Jhon Deere",
      "Download": "Télécharger",
      "Downloading...": "Téléchargement en cours...",
      "Downloading": "Téléchargement",
      "Sending": "Envoi",
      "Sending...": "Envoi en cours...",
      "Something went wrong. If the problem persists please send us an email at automaticfarmsolution@gmail.com": "Quelque chose s'est mal passé. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "Creating the field...": "Création du champ...",
      "Update Field": "Mettre à jour le champ",
      "Updating Field": "Mise à jour du champ en cours",
      "Integration done": "Intégration terminée",
      "You have done the integration with Jhon Deere": "Vous avez terminé l'intégration avec Jhon Deere",
      "Start the integration with the Operation Center Jhon Deere": "Démarrer l'intégration avec le Centre d'Opérations Jhon Deere",
      "A problem occurred during the integration with Jhon Deere": "Un problème est survenu lors de l'intégration avec Jhon Deere",
      "You don't have any farm registered, first you have to set at least one farmer": "Vous n'avez aucune ferme enregistrée, vous devez d'abord configurer au moins un agriculteur",
      "Start the integration with the Operation Center John Deere": "Démarrer l'intégration avec le Centre d'Opérations John Deere",
      "Connect to Operation Center Jhon Deere": "Se connecter au Centre d'Opérations Jhon Deere",
      "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center": "Si vous souhaitez envoyer la carte de prescription au centre d'opérations, vous pouvez connecter cette plateforme directement à votre Centre d'Opérations",
      "Satellite Image analysis": "Analyse d'image satellite",
      "Please enable the geolocation in order to get the weather data and visualization": "Veuillez activer la géolocalisation pour obtenir les données météorologiques et la visualisation",
      "Actual Weather Data": "Données météorologiques actuelles",
      "Weather Map": "Carte météo",
      "Select the climate variable to see": "Sélectionnez la variable climatique à voir",
      "Forecast Temperature": "Température prévue",
      "Wind": "Vent",
      "Pressure": "Pression",
      "Snow": "Neige",
      "Precipitation": "Précipitation",
      "Clouds": "Nuages",
      "Temperature": "Température",
      "Add / Modify / Delete": "Ajouter / Modifier / Supprimer",
      "Field Registered": "Champ Enregistré",
      "Field Name": "Nom du Champ",
      "Select the field to view": "Sélectionnez le champ à visualiser",
      "Number of farmers": "Nombre d'agriculteurs",
      "Number of fields": "Nombre de champs",
      "Hectares Monitored": "Hectares surveillés",
      "Anomalies": "Anomalies",
      "IoT Number": "Numéro IoT",
      "Last Reading": "Dernière Lecture",
      "Soil Temperature": "Température du Sol",
      "Soil Humidity": "Humidité du Sol",
      "Electrical Conductivity": "Conductivité Électrique",
      "Organic Carbon": "Carbone Organique",
      "Nitrogen": "Azote",
      "Phosphorus": "Phosphore",
      "Potassium": "Potassium",
      "Battery": "Batterie",
      "Select the variable": "Sélectionnez la variable",
      "Select the IoT Cabin": "Sélectionnez la Cabine IoT",
      "View data": "Voir les données",
      "Time series Graph": "Graphique de séries temporelles",
      "Time Series Comparison": "Comparaison de séries temporelles",
      "Table": "Tableau",
      "Add Alert": "Ajouter une Alerte",
      "Alert Registered": "Alerte Enregistrée",
      "Sensor": "Capteur",
      "Variable": "Variable",
      "Condition": "Condition",
      "Value": "Valeur",
      "No Alert Registered": "Aucune Alerte Enregistrée",
      "No IoT is linked to your account": "Aucun IoT n'est lié à votre compte",
      "No IoT alert is set": "Aucune alerte IoT n'est définie",
      "Please select the IoT Cabin": "Veuillez sélectionner la Cabine IoT",
      "Please select the variable": "Veuillez sélectionner la variable",
      "Please select the logic to apply": "Veuillez sélectionner la logique à appliquer",
      "Please set the threshold value": "Veuillez définir la valeur seuil",
      "Select the logic": "Sélectionnez la logique",
      "Set the threshold value": "Définissez la valeur seuil",
      "Adding the alert...": "Ajout de l'alerte en cours...",
      "Higher than": "Supérieur à",
      "Lower than": "Inférieur à",
      "IoT Position": "Position de l'IoT",
      "Create Alert": "Créer une alerte",
      "Are you sure to remove this alert?": "Êtes-vous sûr de vouloir supprimer cette alerte ?",
      "Cabin Number:": "Numéro de cabine :",
      "Logic - Higher or Lower:": "Logique - Supérieur ou inférieur :",
      "Threshold Value:": "Valeur seuil :",
      "Delete Alert": "Supprimer l'alerte",
      "Update Alert": "Mettre à jour l'alerte",
      "Updating the alert...": "Mise à jour de l'alerte en cours...",
      "Please the expected yield (q/ha)": "Veuillez indiquer le rendement attendu (q/ha)",
      "Please the fertilizer title (%)": "Veuillez indiquer le titre du fertilisant (%)",
      "Setting of the Calculation": "Réglage du calcul",
      "Select the Nutrient": "Sélectionnez le nutriment",
      "Select the Crop": "Sélectionnez la culture",
      "Set the expected yield (q/ha)": "Définissez le rendement attendu (q/ha)",
      "Title of fertilizer - range 0-100 %": "Titre du fertilisant - plage de 0 à 100 %",
      "Calculate": "Calculer",
      "The amount of fertilizer that you have provided to the crop for the entire crop growth cycle is:": "La quantité de fertilisant que vous avez fournie à la culture pour l'ensemble du cycle de croissance est :",
      "Set the field to get the soil data": "Sélectionnez le champ pour obtenir les données du sol",
      "Hello, I'm your Digital Agronomist Consultant! Ask me anything!": "Bonjour, je suis votre consultant agronome numérique ! Posez-moi toutes vos questions !",
      "The digital agronomist consultant is typing": "Le consultant agronome numérique est en train de taper",
      "Send a Message": "Envoyer un message",
      "Digital agronomist consultant": "Consultant agronome numérique",
      "You have done the integration with John Deere": "Vous avez réalisé l'intégration avec John Deere",
      "Please fill all the requests": "Veuillez remplir toutes les demandes",
      "Set the analysis parameter": "Définir le paramètre d'analyse",
      "Date of sowing": "Date de semis",
      "Select the soil type": "Sélectionnez le type de sol",
      "Sand": "Sable",
      "Loamy Sand": "Sable limoneux",
      "Sandy Loam": "Limono-sableux",
      "Loam": "Limon",
      "Silty Loam": "Limono-argileux",
      "Silt": "Argileux",
      "Sandy Clay Loam": "Argilo-sableux",
      "Clay Loam": "Argile limoneuse",
      "Silty Clay Loam": "Argilo-limoneux",
      "Sandy Clay": "Argile sableuse",
      "Clay": "Argile",
      "Sunflower": "Tournesol",
      "Millet": "Millet",
      "Rice": "Riz",
      "Sugarbeet": "Betterave à sucre",
      "Sorghum": "Sorgho",
      "Soybean": "Soja",
      "Canola": "Colza",
      "Tomato": "Tomate",
      "Cabbage": "Chou",
      "Potato": "Pomme de terre",
      "Sugarcane": "Canne à sucre",
      "Select the Variety": "Sélectionnez la variété",
      "Do you want to provide fertilization?": "Souhaitez-vous fournir de la fertilisation ?",
      "Date of fertilization": "Date de fertilisation",
      "Define the amount of Nitrogen provided (kg N / ha)": "Définir la quantité d'azote fournie (kg N / ha)",
      "Define the amount of Phosphorus provided (kg P / ha)": "Définir la quantité de phosphore fournie (kg P / ha)",
      "Define the amount of Potassium provided (kg K / ha)": "Définir la quantité de potassium fournie (kg K / ha)",
      "Do you want to provide irrigation": "Souhaitez-vous fournir de l'irrigation",
      "Date of irrigation": "Date de l'irrigation",
      "Define the amount of water mm": "Définir la quantité d'eau en mm",
      "Next": "Suivant",
      "Submit": "Envoyer",
      "Temporal creation of the yield": "Création temporelle du rendement",
      "Before you can run the model you should define the crop type. Go to the Field and update section the information": "Avant de pouvoir exécuter le modèle, vous devez définir le type de culture. Accédez à la section Champ et mettez à jour les informations.",
      "Set the Field where apply the analysis": "Définir le champ où appliquer l'analyse",
      "Set the Farmer": "Définir l'agriculteur",
      "Set the Field": "Définir le champ",
      "Run The Analysis": "Lancer l'analyse",
      "NDVI Comparation": "Comparaison de NDVI",
      "SAR Comparation": "Comparaison de SAR",
      "Probabilistic Map": "Carte probabiliste",
      "% Convenctional": "% Conventionnel",
      "% Organic": "% Biologique",
      "The field was declared by the farmer as": "Le champ a été déclaré par l'agriculteur comme",
      "Select the start year to analyze": "Sélectionnez l'année de début pour l'analyse",
      "Select the base temperature for the crop": "Sélectionnez la température de base pour la culture",
      "Select the max temperature for the crop": "Sélectionnez la température maximale pour la culture",
      "Start analysis": "Lancer l'analyse",
      "Upgrade your account": "Mettez à niveau votre compte",
      "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO": "Si vous souhaitez accéder aux données satellitaires et générer la carte de prescription, vous devez mettre à niveau votre compte en PRO",
      "Not Now": "Pas maintenant",
      "Upgrade your account to PRO": "Mettez à niveau votre compte en PRO",
      "Your Account is PRO": "Votre compte est PRO",
      "You can access to all the service": "Vous pouvez accéder à tous les services",
      "Your Account is not PRO": "Votre compte n'est pas PRO",
      "Purchase the PRO Service": "Acheter le service PRO",
      "The total price is": "Le prix total est",
      "The payment was successful": "Le paiement a réussi",
      "Now you should logout and login again to access all the features just unlocked": "Maintenant, vous devez vous déconnecter et vous reconnecter pour accéder à toutes les fonctionnalités débloquées",
      "By purchasing this service you will access:": "En achetant ce service, vous aurez accès à :",
      "Prescription maps that can be integrated with your display tractors": "Cartes de prescription pouvant être intégrées à vos tracteurs avec écran",
      "Visualization & Analysis of sensor data": "Visualisation et analyse des données du capteur",
      "Set alert": "Configurer l'alerte",
      "All the agronomic tools": "Tous les outils agronomiques",
      "AI Tools": "Outils d'IA",
      "Synchronization with Operation Center John Deere": "Synchronisation avec le centre d'opérations John Deere",
      "Vineyards": "Vignobles",
      "perennial": "Pluriannuel",
      "biennial": "Biennal",
      "triennial": "Triennal",
      "Kiwi": "Kiwi",
      "Garlic": "Ail",
      "Apricot": "Abricot",
      "Orange": "Orange",
      "Asparagus": "Asperge",
      "Oats": "Avoine",
      "Sugar_beet": "Betterave à sucre",
      "Basil": "Basilic",
      "Swiss_chard": "Blette",
      "Beet_leaves": "Feuilles de betterave",
      "Broccoli": "Brocoli",
      "Hemp": "Chanvre",
      "Artichoke": "Artichaut",
      "Carrot": "Carotte",
      "Cauliflower": "Chou-fleur",
      "chickpea": "Pois chiche",
      "cucumber": "Concombre",
      "Chicory": "Chicorée",
      "Cherry": "Cerise",
      "Onion": "Oignon",
      "Endive": "Endive",
      "Watermelon": "Pastèque",
      "Rapeseed": "Colza",
      "Green_bean_for_industry": "Haricot vert pour l'industrie",
      "Fresh_green_bean": "Haricot vert frais",
      "Bean": "Haricot",
      "Dry_bean": "Haricot sec",
      "Spelt": "Épeautre",
      "Grain_fava_bean": "Fève de grain",
      "Favino": "Favino",
      "Fico": "Figue",
      "Fennel": "Fenouil",
      "Strawberry": "Fraise",
      "winter_wheat": "Blé d'hiver",
      "Kaki": "Kaki",
      "Lettuce": "Laitue",
      "Lentil": "Lentilles",
      "Lemon": "Citron",
      "Linen": "Lin",
      "Lupin": "Lupin",
      "Corn": "Maïs",
      "Tangerine": "Mandarine",
      "Almond": "Amande",
      "Eggplant": "Aubergine",
      "Apple": "Pomme",
      "Melon": "Melon",
      "Blueberry": "Myrtille",
      "Nectarines": "Nectarine",
      "Kernel": "Noyau",
      "Walnut": "Noix",
      "Olive": "Olive",
      "Bell_pepper": "Poivron",
      "Pear": "Poire",
      "Peach": "Pêche",
      "Pea": "Pois",
      "Leek": "Poireau",
      "Parsley": "Persil",
      "Radicchio": "Radicchio",
      "Turnip": "Navet",
      "Radish": "Radis",
      "Ribes": "Groseille",
      "Shallots": "Échalote",
      "Celery": "Céleri",
      "Rye": "Seigle",
      "Soy": "Soja",
      "Spinach": "Épinard",
      "Plum_tree": "Prunier",
      "Tabacco": "Tabac",
      "Triticale": "Triticale",
      "Vineyard": "Vignoble",
      "Savoy_cabbage": "Chou de Savoie",
      "Pumpkin": "Citrouille",
      "Zucchini_for_industry": "Courgette pour l'industrie",
      "Fresh_zucchini": "Courgette fraîche",
      "Peanuts": "Arachides",
      "Chard": "Blette",
      "Leaf_beet": "Feuilles de betterave",
      "Hemp_for_seeds": "Chanvre (graines)",
      "Hemp_for_fiber": "Chanvre (fibres)",
      "Cardoon": "Cardon",
      "Chestnut": "Châtaigne",
      "Cherry_tree": "Cerisier",
      "French_bean": "Haricot vert frais",
      "Fava_bean": "Fève sèche",
      "Wheat": "Blé",
      "Raspberry": " Framboise",
      "Flax": "Lin",
      "Ley_grass": "Lupin",
      "Corn_for_grain": "Aubergine",
      "Hazelnut": "Noisette",
      "Pear_tree": "Poirier",
      "Peach_tree": "Pêcher",
      "Protein_pea": "Pois protéiné",
      "Processing_tomato": "Tomate de transformation",
      "Table_tomato": "Tomate de table",
      "Table_grape": "Raisin de table",
      "Gooseberry": "Groseille à maquereau",
      "Grapevine": "Vigne",
      "Clover": "Trèfle",
      "Corn_salad": "Salade de maïs",
      "Grapevine_for_wine_grapes": "Vigne pour raisins de vin",
      "Sweet_corn": "Maïs sucré",
      "Silage_corn": "Maïs pour ensilage",
      "Oilseed_rape": "Colza oléagineux",
      "Field-grown_bell_pepper": "Poivron cultivé en champ",
      "Pea_for_industry": "Pois pour l'industrie",
      "Fresh_pea": "Pois frais",
      "Greenhouse_table_tomato": "Tomate de table sous serre",
      "Clover_grassland": "Pâturage de trèfle",
      "Hill_pastures": "Pâturages de colline",
      "Mixed_grassland_>50_legumes": "Pâturage mixte >50% de légumineuses",
      "Artificial_mixed_hill_pastures": "Pâturages de colline mixtes artificiels",
      "Stable_plains": "Plaines stables",
      "Silage_fodder": "Fourrage pour ensilage",
      "Arugula_1st_cut": "Roquette, 1ère coupe",
      "Arugula_2nd_cut": "Roquette, 2ème coupe",
      "Shallot": "Échalote",
      "Arugula_in_open_field": "Roquette en champ ouvert",
      "Organic vs Conventional:": "Biologique vs Conventionnel:",
      "Are you sure to edit the polygon? if yes press again the Update Field button": "Êtes-vous sûr de vouloir éditer le polygone? Si oui, appuyez à nouveau sur le bouton Mettre à jour le champ",
      "Set the Year of analysis": "Définir l'année d'analyse",
      "Set the nutrient to visualize": "Définir le nutriment à visualiser",
      "Carbonates": "Carbonates",
      "Soil Electrical Conductivity": "Conductivité électrique du sol",
      "pH": "pH",
      "Please set the year": "Veuillez définir l'année",
      "Please set the nutrient": "Veuillez définir le nutriment",
      "Please set the farmer": "Veuillez définir l'agriculteur",
      "Please set the field": "Veuillez définir le champ",
      "The map is not ready yet": "La carte n'est pas encore prête",
      "Please select the object of your request": "Veuillez sélectionner l'objet de votre demande",
      "Please report a valid message": "Veuillez signaler un message valide",
      "Send us your message": "Envoyez-nous votre message",
      "Object of the request:": "Objet de la demande :",
      "Object:": "Objet :",
      "Support Request": "Demande de support",
      "Upgrade account": "Mettre à niveau le compte",
      "Problem with the application": "Problème avec l'application",
      "Other": "Autre",
      "Message of the request:": "Message de la demande :",
      "Message:": "Message :",
      "Send message": "Envoyer le message",
      "My account": "Mon compte",
      "Do you want to upgrade your account?": "Voulez-vous mettre à niveau votre compte ?",
      "Send us a message": "Envoyez-nous un message",
      "Your Message have been sent with success": "Votre message a été envoyé avec succès",
      "As soon as possible one of our operators will check your request and answer you": "Dès que possible, l'un de nos opérateurs vérifiera votre demande et vous répondra",
      "Frumento_tenero": "Blé d'hiver",
      "Winter Wheat": "Blé d'hiver",
      "Annual percentage increase": "Augmentation annuelle en pourcentage",
      "Tons of CO2 Sequestered": "Tonnes de CO2 séquestrées",
      "Carbon Credits Generated": "Crédits carbone générés",
      "Soil Map": "Carte des sols",
      "Temporal Variation": "Variation temporelle",
      "If you want to access to this module data, you must upgrade your account to PRO":"Si vous souhaitez accéder aux données de ce module, vous devez passer à la version PRO de votre compte.",
      
      "Large Scale Application": "Application à grande échelle",
      "Field Name": "Nom du champ",
      "Crop": "Culture",
      "Anomaly": "Anomalie",
      "Declared": "Déclaré",
      "Class": "Classe",
      "Percentage": "Pourcentage",

      "Hectares Deleted":"Hectares supprimés",
      "Search City:":"Rechercher une ville:",
      "Export Document":"Exporter le document",
      "Download Section":"Section de téléchargement",
      "Download Field - Format ShapeFile":"Télécharger champ - Format ShapeFile",
      "Download Field - Format Excel":"Télécharger champ - Format Excel",
      "Download Farmer Data - Format Excel":"Télécharger données agriculteur - Format Excel",

      "The crop field is empty":"Le champ de culture est vide",


      "Create a farm": "Créer une ferme",
      "Create a field": "Créer un champ",
      "Delete Account": "Supprimer le compte",
      "Are you sure to delete your account?": "Êtes-vous sûr de vouloir supprimer votre compte ?",
      "Are you sure to delete the account?": "Êtes-vous sûr de vouloir supprimer le compte ?",
      "Please be carefull cause if you delete your account all the data related will be removed": "Veuillez faire attention car si vous supprimez votre compte, toutes les données associées seront supprimées",
      "If you want to proceed, please report your email ": "Si vous souhaitez continuer, veuillez indiquer votre adresse e-mail ",
      " in the following form input": " dans le champ de formulaire suivant",
      "Deleting the user...": "Suppression de l'utilisateur...",
      "Delete account":"Supprimer l'utilisateur",
      "In order to register you should accept the Terms And Conditions":"Pour vous inscrire, vous devez accepter les conditions générales d'utilisation.",
      "Upload Field":"Upload Campi",
      "Upload Zip File where within there is the shapefile with all the fields":"Télécharger un fichier zip contenant un shapefile où se trouvent tous les champs que vous souhaitez créer",
      "Create Fields":"Créer des Champs"
    }
  },
  "DE": {
    "translation": {
      "Hello": "Hallo",      
      "Yes":"Ja",
      "No":"Nein",
      "Phosporous":"Phosphor",
      "Number of farmer":"Anzahl der Landwirte",
      "Number of field":"Anzahl der Felder",
      "Farmer Registred": "Landwirt registriert",
      "Field Registred": "Feld registriert",
      "Durum_wheat": "Hartweizen",
      "Download data": "Daten herunterladen",
      "The email is empty": "Die E-Mail ist leer",
      "The password is empty": "Das Passwort ist leer",
      "The user is not active!": "Der Benutzer ist nicht aktiv!",
      "The email is used": "Die E-Mail wird bereits verwendet",
      "Forgot password?": "Passwort vergessen?",
      "Create new account": "Neues Konto erstellen",
      "Check your email inbox and follow the instructions to change the password.": "Überprüfen Sie Ihr E-Mail-Postfach und folgen Sie den Anweisungen, um das Passwort zu ändern.",
      "Go back to Login": "Zurück zum Login",
      "The email doesn't exist in our database!": "Die E-Mail existiert nicht in unserer Datenbank!",
      "Change Password": "Passwort ändern",
      "Send Email": "E-Mail senden",
      "Sending email...": "E-Mail wird gesendet...",
      "The first password is empty": "Das erste Passwort ist leer",
      "The confirm password is empty": "Das Bestätigungspasswort ist leer",
      "The password don't match": "Die Passwörter stimmen nicht überein",
      "Password change failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Passwortänderung fehlgeschlagen. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "An error occurred while changing the password. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Es ist ein Fehler aufgetreten beim Ändern des Passworts. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "Confirm Password": "Passwort bestätigen",
      "Changing Password...": "Passwort wird geändert...",
      "The password has been changed with success. Now use the new password to login.": "Das Passwort wurde erfolgreich geändert. Verwenden Sie jetzt das neue Passwort zum Einloggen.",
      "In order to register you should accept the Privacy Policy": "Um sich zu registrieren, müssen Sie die Datenschutzrichtlinie akzeptieren",
      "In order to register you should provide your name": "Um sich zu registrieren, müssen Sie Ihren Namen angeben",
      "In order to register you should provide a valid email": "Um sich zu registrieren, müssen Sie eine gültige E-Mail-Adresse angeben",
      "The current email is used, please use another one!": "Die aktuelle E-Mail wird bereits verwendet, bitte verwenden Sie eine andere!",
      "Registration": "Registrierung",
      "Name": "Name",
      "I agree with the": "Ich stimme der",
      "Register": "Registrieren",
      "Registration...": "Registrierung...",
      "Already have an account?": "Sie haben bereits ein Konto?",
      "Check your email inbox and follow the instructions to activate your account.": "Überprüfen Sie Ihr E-Mail-Postfach und folgen Sie den Anweisungen, um Ihr Konto zu aktivieren.",
      "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Die Aktivierung Ihres Kontos ist fehlgeschlagen. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "An error occurred during the activation of your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Es ist ein Fehler aufgetreten während der Aktivierung Ihres Kontos. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "Do you want to activate your account?": "Möchten Sie Ihr Konto aktivieren?",
      "Activate my account": "Mein Konto aktivieren",
      "Activation...": "Aktivierung...",
      "Your Account has been activated. Now You can login": "Ihr Konto wurde aktiviert. Jetzt können Sie sich anmelden",
      "Welcome!": "Willkommen!",
      "My profile": "Mein Profil",
      "Settings": "Einstellungen",
      "Support": "Support",
      "Logout": "Abmelden",
      "Farm": "Bauernhof",
      "Field": "Feld",
      "Field Notebook": "Feldnotizbuch",
      "Agrometeo": "Agrometeo",
      "Weather": "Wetter",
      "Climatic Index": "Klimaindex",
      "Satellite": "Satellit",
      "Prescription Map": "Verschreibungskarte",
      "Sensors": "Sensoren",
      "Summary": "Zusammenfassung",
      "Analysis": "Analyse",
      "Forecast": "Prognose",
      "Anomaly Detection": "Anomalieerkennung",
      "Comparation": "Vergleich",
      "Allert": "Warnung",
      "Bio Trap": "Biofalle",
      "Agronomic tools": "Agronomische Werkzeuge",
      "Nutrient Balance": "Nährstoffbilanz",
      "Soil Data": "Bodendaten",
      "Irrigation": "Bewässerung",
      "Pest Model": "Schädlingsmodell",
      "Artificial Intelligence": "Künstliche Intelligenz",
      "Digital Consulting": "Digitale Beratung",
      "Crop Models & simulations": "Pflanzenmodelle & Simulationen",
      "Soil Mapping & Carbon Credits": "Bodenkartierung & Kohlenstoffguthaben",
      "Organic vs Conventional": "Bio vs Konventionell",
      "Connections & Data Sharing": "Verbindungen & Datenaustausch",
      "Upgrade your profile": "Profil aktualisieren",
      "Register a new farmer": "Neuen Landwirt registrieren",
      "Farmer company name:": "Name des Landwirtschaftsunternehmens:",
      "Farmer Company Name:": "Name des Landwirtschaftsunternehmens:",
      "Farmer First Name:": "Vorname des Landwirts:",
      "Farmer Surname:": "Nachname des Landwirts:",
      "Farmer Country:": "Land des Landwirts:",
      "Farmer City:": "Stadt des Landwirts:",
      "Farmer Address:": "Adresse des Landwirts:",
      "Farmer Email:": "E-Mail des Landwirts:",
      "Farmer Phone Number:": "Telefonnummer des Landwirts:",
      "Create Farm": "Farm erstellen",
      "Go back": "Zurück",
      "Restart Creation": "Neustart der Erstellung",
      "Creating the farm...": "Farm wird erstellt...",
      "Farm Registered": "Farm registriert",
      "No farmer are registered": "Keine Landwirte sind registriert",
      "If you want to start using this application, you should start by registering a new farmer.": "Wenn Sie diese Anwendung verwenden möchten, sollten Sie damit beginnen, einen neuen Landwirt zu registrieren.",
      "Farm Name": "Farmname",
      "Surname": "Nachname",
      "Email": "E-Mail",
      "Phone Number": "Telefonnummer",
      "Edit": "Bearbeiten",
      "Delete": "Löschen",
      "Are you sure to remove the following farmer?": "Sind Sie sicher, den folgenden Landwirt zu entfernen?",
      "Name of the farmer:": "Name des Landwirts:",
      "Surname of the farmer:": "Nachname des Landwirts:",
      "Country of the farmer:": "Land des Landwirts:",
      "City of the farmer:": "Stadt des Landwirts:",
      "Address of the farmer:": "Adresse des Landwirts:",
      "Email of the farmer:": "E-Mail des Landwirts:",
      "Phone Number of the farmer:": "Telefonnummer des Landwirts:",
      "Delete Farmer": "Landwirt löschen",
      "Invalid email address": "Ungültige E-Mail-Adresse",
      "Invalid phone number": "Ungültige Telefonnummer",
      "Add Farm": "Farm hinzufügen",
      "Farmer Registered": "Landwirt registriert",
      "Farmer": "Landwirt",
      "No registered farm": "Keine registrierte Farm",
      "Loading": "Laden",
      "Update Farm": "Farm aktualisieren",
      "Updating the farm...": "Farm wird aktualisiert...",
      "The Name of the farmer is empty": "Der Name des Landwirts ist leer",
      "The Surname of the farmer is empty": "Der Nachname des Landwirts ist leer",
      "The CountryCode of the farmer is empty": "Der Ländercode des Landwirts ist leer",
      "The City of the farmer is empty": "Die Stadt des Landwirts ist leer",
      "The Address of the farmer is empty": "Die Adresse des Landwirts ist leer",
      "The Email of the farmer is empty": "Die E-Mail des Landwirts ist leer",
      "The Phone Number of the farmer is empty": "Die Telefonnummer des Landwirts ist leer",
      "An error occurred while updating the farm. If the problem persists, please contact us at automaticfarmsolution@gmail.com": "Ein Fehler ist bei der Aktualisierung der Farm aufgetreten. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte unter automaticfarmsolution@gmail.com",
      "Create Field": "Feld erstellen",
      "You don't have any farm registred, first you have to set at least one farmer": "Sie haben keine registrierte Farm, zuerst müssen Sie mindestens einen Landwirt einrichten",
      "Field name:": "Feldname:",
      "Crop Rotation:": "Fruchtwechsel:",
      "Crop Rotation": "Fruchtwechsel",
      "Perennial crop": "Mehrjährige Kultur",
      "Two year crop rotation": "Zweijähriger Fruchtwechsel",
      "Three year crop rotation": "Dreijähriger Fruchtwechsel",
      "Actual Crop": "Aktuelle Kultur",
      "Alfalfa": "Alfalfa",
      "Barley": "Gerste",
      "Maize": "Mais",
      "Durum Wheat": "Durumweizen",
      "The next year Crop": "Kultur im nächsten Jahr",
      "The crop in two years' time": "Kultur in zwei Jahren",
      "Organic": "Biologisch",
      "Conventional": "Konventionell",
      "Draw the edge of your field:": "Zeichnen Sie den Rand Ihres Feldes:",
      "The field name is empty": "Der Feldname ist leer",
      "Please draw the edge of your field on the map": "Bitte zeichnen Sie den Rand Ihres Feldes auf der Karte",
      "An error occurred while creating the field. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Ein Fehler ist beim Erstellen des Feldes aufgetreten. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "List of the field": "Liste der Felder",
      "Field Name:": "Feldname:",
      "Field Detail": "Felddetails",
      "Crop:": "Kultur:",
      "Agronomic Management:": "Agronomisches Management:",
      "Are you sure to delete this field?": "Sind Sie sicher, dieses Feld zu löschen?",
      "Delete Field": "Feld löschen",
      "Field:": "Feld:",
      "Loading...": "Laden...",
      "Edit Field": "Feld bearbeiten",
      "Draw again the edge of your field:": "Zeichnen Sie den Rand Ihres Feldes erneut:",
      "In red there is the old field": "In Rot befindet sich das alte Feld",
      "An error occurred while editing the field. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Ein Fehler ist beim Bearbeiten des Feldes aufgetreten. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "Try Again": "Erneut versuchen",
      "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Etwas ist schiefgegangen. Bitte aktualisieren Sie die Seite und wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "Select the field to analyze": "Wählen Sie das Feld zur Analyse aus",
      "View Satellite Image": "Satellitenbild anzeigen",
      "Field Details": "Felddetails",
      "Actual Crop:": "Aktuelle Kultur:",
      "Time series plot": "Zeitreihenplot",
      "Satellite Image": "Satellitenbild",
      "Select one field": "Wählen Sie ein Feld aus",
      "Field Boundary": "Feldgrenze",
      "Select the Vegetation Index": "Wählen Sie den Vegetationsindex aus",
      "Set the prescription map": "Setzen Sie die Verschreibungskarte",
      "Do you want to define the number of zones in an Automatic way or Manual?": "Möchten Sie die Anzahl der Zonen automatisch oder manuell definieren?",
      "Automatic": "Automatisch",
      "Manual": "Manuell",
      "If it is set to Manual, how many zones do you want to create?": "Wenn es auf Manuell eingestellt ist, wie viele Zonen möchten Sie erstellen?",
      "Max fertilizer that you want to apply?": "Maximaldünger, den Sie anwenden möchten?",
      "Which strategy do you want to use?": "Welche Strategie möchten Sie verwenden?",
      "Provide higher amount of fertilizer where the crop is stronger": "Bieten Sie eine höhere Menge Dünger an, wo die Kultur stärker ist",
      "Provide lower amount of fertilizer where the crop is stronger": "Geben Sie weniger Dünger ab, wo die Pflanze stärker ist",
      "Percentage of difference of fertilizer between zones": "Prozentsatz des Unterschieds des Düngers zwischen den Zonen",
      "Selected Percentage:": "Ausgewählter Prozentsatz:",
      "Create": "Erstellen",
      "Send to Jhon Deere Operation Center": "An das Jhon Deere Operation Center senden",
      "Download": "Herunterladen",
      "Downloading...": "Herunterladen...",
      "Downloading": "Herunterladen",
      "Sending": "Senden",
      "Sending...": "Senden...",
      "Something went wrong. If the problem persists please send us an email at automaticfarmsolution@gmail.com": "Etwas ist schief gelaufen. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "Creating the field...": "Feld wird erstellt...",
      "Update Field": "Feld aktualisieren",
      "Updating Field": "Feld wird aktualisiert",
      "Integration done": "Integration abgeschlossen",
      "You have done the integration with Jhon Deere": "Sie haben die Integration mit Jhon Deere abgeschlossen",
      "Start the integration with the Operation Center Jhon Deere": "Starten Sie die Integration mit dem Jhon Deere Operation Center",
      "A problem occurred during the integration with Jhon Deere": "Ein Problem ist während der Integration mit Jhon Deere aufgetreten",
      "You don't have any farm registered, first you have to set at least one farmer": "Sie haben keine Farm registriert, zuerst müssen Sie mindestens einen Landwirt einrichten",
      "Start the integration with the Operation Center John Deere": "Starten Sie die Integration mit dem Operation Center John Deere",
      "Connect to Operation Center Jhon Deere": "Mit dem Jhon Deere Operation Center verbinden",
      "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center": "Wenn Sie die Verschreibungskarte an das Operation Center senden möchten, können Sie diese Plattform direkt mit Ihrem Operation Center verbinden",
      "Satellite Image analysis": "Satellitenbildanalyse",
      "Please enable the geolocation in order to get the weather data and visualization": "Bitte aktivieren Sie die Geolokalisierung, um Wetterdaten und Visualisierung zu erhalten",
      "Actual Weather Data": "Aktuelle Wetterdaten",
      "Weather Map": "Wetterkarte",
      "Select the climate variable to see": "Wählen Sie die Klimavariablen aus, um zu sehen",
      "Forecast Temperature": "Vorhersage Temperatur",
      "Wind": "Wind",
      "Pressure": "Druck",
      "Snow": "Schnee",
      "Precipitation": "Niederschlag",
      "Clouds": "Wolken",
      "Temperature": "Temperatur",
      "Add / Modify / Delete": "Hinzufügen / Ändern / Löschen",
      "Field Registered": "Feld registriert",
      "Field Name": "Feldname",
      "Select the field to view": "Wählen Sie das Feld aus, um es anzuzeigen",
      "Number of farmers": "Anzahl der Landwirte",
      "Number of fields": "Anzahl der Felder",
      "Hectares Monitored": "Überwachte Hektar",
      "Anomalies": "Anomalien",
      "IoT Number": "IoT-Nummer",
      "Last Reading": "Letzte Messung",
      "Soil Temperature": "Bodentemperatur",
      "Soil Humidity": "Bodenfeuchtigkeit",
      "Electrical Conductivity": "Elektrische Leitfähigkeit",
      "Organic Carbon": "Organischer Kohlenstoff",
      "Nitrogen": "Stickstoff",
      "Phosphorus": "Phosphor",
      "Potassium": "Kalium",
      "Battery": "Batterie",
      "Select the variable": "Variable auswählen",
      "Select the IoT Cabin": "IoT-Kabine auswählen",
      "View data": "Daten anzeigen",
      "Time series Graph": "Zeitreihen-Diagramm",
      "Time Series Comparison": "Zeitreihenvergleich",
      "Table": "Tabelle",
      "Add Alert": "Alarm hinzufügen",
      "Alert Registered": "Alarm registriert",
      "Sensor": "Sensor",
      "Variable": "Variable",
      "Condition": "Bedingung",
      "Value": "Wert",
      "No Alert Registered": "Kein Alarm registriert",
      "No IoT is linked to your account": "Kein IoT ist mit Ihrem Konto verknüpft",
      "No IoT alert is set": "Kein IoT-Alarm ist festgelegt",
      "Please select the IoT Cabin": "Bitte wählen Sie die IoT-Kabine aus",
      "Please select the variable": "Bitte wählen Sie die Variable aus",
      "Please select the logic to apply": "Bitte wählen Sie die Logik aus, die angewendet werden soll",
      "Please set the threshold value": "Bitte setzen Sie den Schwellenwert",
      "Select the logic": "Wählen Sie die Logik aus",
      "Set the threshold value": "Setzen Sie den Schwellenwert",
      "Adding the alert...": "Hinzufügen der Warnung...",
      "Higher than": "Höher als",
      "Lower than": "Niedriger als",
      "IoT Position": "IoT-Position",
      "Create Alert": "Warnung erstellen",
      "Are you sure to remove this alert?": "Sind Sie sicher, diese Warnung zu entfernen?",
      "Cabin Number:": "Kabinennummer:",
      "Logic - Higher or Lower:": "Logik - Höher oder Niedriger:",
      "Threshold Value:": "Schwellenwert:",
      "Delete Alert": "Warnung löschen",
      "Update Alert": "Warnung aktualisieren",
      "Updating the alert...": "Aktualisierung der Warnung...",
      "Please the expected yield (q/ha)": "Bitte geben Sie den erwarteten Ertrag an (q/ha)",
      "Please the fertilizer title (%)": "Bitte geben Sie den Düngertitel an (%)",
      "Setting of the Calculation": "Einstellung der Berechnung",
      "Select the Nutrient": "Nährstoff auswählen",
      "Select the Crop": "Kultur auswählen",
      "Set the expected yield (q/ha)": "Setzen Sie den erwarteten Ertrag (q/ha)",
      "Title of fertilizer - range 0-100 %": "Titel des Düngers - Bereich 0-100 %",
      "Calculate": "Berechnen",
      "The amount of fertilizer that you have provided to the crop for the entire crop growth cycle is:": "Die Menge an Dünger, die Sie der Kultur während des gesamten Wachstumszyklus bereitgestellt haben, beträgt:",
      "Set the field to get the soil data": "Wählen Sie das Feld aus, um die Bodendaten zu erhalten",
      "Hello, I'm your Digital Agronomist Consultant! Ask me anything!": "Hallo, ich bin Ihr Digitaler Agronomieberater! Fragen Sie mich alles!",
      "The digital agronomist consultant is typing": "Der digitale Agronomieberater tippt",
      "Send a Message": "Nachricht senden",
      "Digital agronomist consultant": "Digitaler Agronomieberater",
      "You have done the integration with John Deere": "Sie haben die Integration mit John Deere abgeschlossen",
      "Please fill all the requests": "Bitte füllen Sie alle Anfragen aus",
      "Set the analysis parameter": "Setzen Sie den Analyseparameter",
      "Date of sowing": "Aussaatdatum",
      "Select the soil type": "Wählen Sie den Bodentyp aus",
      "Sand": "Sand",
      "Loamy Sand": "Lehmiger Sand",
      "Sandy Loam": "Sandiger Lehm",
      "Loam": "Lehm",
      "Silty Loam": "Schluffiger Lehm",
      "Silt": "Schluff",
      "Sandy Clay Loam": "Sandiger Tonlehm",
      "Clay Loam": "Lehmiger Ton",
      "Silty Clay Loam": "Schluffiger Tonlehm",
      "Sandy Clay": "Sandton",
      "Clay": "Ton",
      "Sunflower": "Sonnenblume",
      "Millet": "Hirse",
      "Rice": "Reis",
      "Sugarbeet": "Zuckerbeete",
      "Sorghum": "Sorghum",
      "Soybean": "Sojabohne",
      "Canola": "Raps",
      "Tomato": "Tomate",
      "Cabbage": "Kohl",
      "Potato": "Kartoffel",
      "Sugarcane": "Zuckerrohr",
      "Select the Variety": "Sorte auswählen",
      "Do you want to provide fertilization?": "Möchten Sie Düngung bereitstellen?",
      "Date of fertilization": "Datum der Düngung",
      "Define the amount of Nitrogen provided (kg N / ha)": "Definieren Sie die Menge an Stickstoff, die bereitgestellt wird (kg N / ha)",
      "Define the amount of Phosphorus provided (kg P / ha)": "Definieren Sie die Menge an Phosphor, die bereitgestellt wird (kg P / ha)",
      "Define the amount of Potassium provided (kg K / ha)": "Definieren Sie die Menge an Kalium, die bereitgestellt wird (kg K / ha)",
      "Do you want to provide irrigation": "Möchten Sie Bewässerung bereitstellen",
      "Date of irrigation": "Datum der Bewässerung",
      "Define the amount of water mm": "Definieren Sie die Menge Wasser mm",
      "Next": "Weiter",
      "Submit": "Einreichen",
      "Temporal creation of the yield": "Zeitliche Erstellung des Ertrags",
      "Before you can run the model you should define the crop type. Go to the Field and update section the information": "Bevor Sie das Modell ausführen können, müssen Sie den Pflanzentyp definieren. Gehen Sie zum Abschnitt Feld und aktualisieren Sie die Informationen.",
      "Set the Field where apply the analysis": "Legen Sie das Feld fest, auf dem die Analyse durchgeführt werden soll.",
      "Set the Farmer": "Legen Sie den Landwirt fest.",
      "Set the Field": "Legen Sie das Feld fest.",
      "Run The Analysis": "Führen Sie die Analyse durch.",
      "NDVI Comparation": "NDVI-Vergleich",
      "SAR Comparation": "SAR-Vergleich",
      "Probabilistic Map": "Wahrscheinlichkeitskarte",
      "% Convenctional": "% Konventionell",
      "% Organic": "% Bio",
      "The field was declared by the farmer as": "Das Feld wurde vom Landwirt als erklärt",
      "Select the start year to analyze": "Wählen Sie das Startjahr für die Analyse aus.",
      "Select the base temperature for the crop": "Wählen Sie die Basistemperatur für die Pflanze aus.",
      "Select the max temperature for the crop": "Wählen Sie die maximale Temperatur für die Pflanze aus.",
      "Start analysis": "Starte die Analyse",
      "Upgrade your account": "Aktualisieren Sie Ihr Konto",
      "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO": "Wenn Sie auf die Satellitendaten zugreifen und die Verschreibungskarte generieren möchten, müssen Sie Ihr Konto auf PRO aktualisieren.",
      "Not Now": "Nicht jetzt",
      "Upgrade your account to PRO": "Aktualisieren Sie Ihr Konto auf PRO",
      "Your Account is PRO": "Ihr Konto ist PRO",
      "You can access to all the service": "Sie haben Zugriff auf alle Dienste",
      "Your Account is not PRO": "Ihr Konto ist nicht PRO",
      "Purchase the PRO Service": "Kaufen Sie den PRO-Service",
      "The total price is": "Der Gesamtpreis beträgt",
      "The payment was successful": "Die Zahlung war erfolgreich",
      "Now you should logout and login again to access all the features just unlocked": "Sie sollten sich jetzt abmelden und erneut anmelden, um auf alle freigeschalteten Funktionen zugreifen zu können.",
      "By purchasing this service you will access:": "Durch den Kauf dieses Dienstes haben Sie Zugriff auf:",
      "Prescription maps that can be integrated with your display tractors": "Verschreibungskarten, die mit Ihren Anzeigetraktoren integriert werden können",
      "Visualization & Analysis of sensor data": "Visualisierung und Analyse von Sensordaten",
      "Set alert": "Alarm einstellen",
      "All the agronomic tools": "Alle agronomischen Werkzeuge",
      "AI Tools": "KI-Werkzeuge",
      "Synchronization with Operation Center John Deere": "Synchronisation mit dem Betriebszentrum John Deere",
      "Vineyards": "Weinberge",
      "perennial": "Mehrjährig",
      "biennial": "Zweijährig",
      "triennial": "Dreijährig",
      "Kiwi": "Kiwi",
      "Garlic": "Knoblauch",
      "Apricot": "Aprikose",
      "Orange": "Orange",
      "Asparagus": "Spargel",
      "Oats": "Hafer",
      "Sugar_beet": "Zuckerrübe",
      "Basil": "Basilikum",
      "Swiss_chard": "Mangold",
      "Beet_leaves": "Rübenblätter",
      "Broccoli": "Brokkoli",
      "Hemp": "Hanf",
      "Artichoke": "Artischocke",
      "Carrot": "Karotte",
      "Cauliflower": "Blumenkohl",
      "chickpea": "Kichererbse",
      "cucumber": "Gurke",
      "Chicory": "Chicorée",
      "Cherry": "Kirsche",
      "Onion": "Zwiebel",
      "Endive": "Endivie",
      "Watermelon": "Wassermelone",
      "Rapeseed": "Raps",
      "Green_bean_for_industry": "Grüne Bohne für die Industrie",
      "Fresh_green_bean": "Frische grüne Bohne",
      "Bean": "Bohne",
      "Dry_bean": "Trockenbohne",
      "Spelt": "Dinkel",
      "Grain_fava_bean": "Grain Saubohne",
      "Favino": "Favino",
      "Fico": "Feige",
      "Fennel": "Fenchel",
      "Strawberry": "Erdbeere",
      "winter_wheat": "Winterweizen",
      "Kaki": "Persimmon",
      "Lettuce": "Kopfsalat",
      "Lentil": "Linsen",
      "Lemon": "Zitrone",
      "Linen": "Leinen",
      "Lupin": "Lupine",
      "Corn": "Mais",
      "Tangerine": "Mandarine",
      "Almond": "Mandel",
      "Eggplant": "Aubergine",
      "Apple": "Apfel",
      "Melon": "Melone",
      "Blueberry": "Heidelbeere",
      "Nectarines": "Nektarinen",
      "Kernel": "Kern",
      "Walnut": "Walnuss",
      "Olive": "Olive",
      "Bell_pepper": "Paprika",
      "Pear": "Birne",
      "Peach": "Pfirsich",
      "Pea": "Erbse",
      "Leek": "Lauch",
      "Parsley": "Petersilie",
      "Radicchio": "Radicchio",
      "Turnip": "Rübe",
      "Radish": "Radieschen",
      "Ribes": "Johannisbeere",
      "Shallots": "Schalotten",
      "Celery": "Sellerie",
      "Rye": "Roggen",
      "Soy": "Soja",
      "Spinach": "Spinat",
      "Plum_tree": "Pflaumenbaum",
      "Tabacco": "Tabak",
      "Triticale": "Triticale",
      "Vineyard": "Weinberg",
      "Savoy_cabbage": "Wirsing",
      "Pumpkin": "Kürbis",
      "Zucchini_for_industry": "Zucchini für die Industrie",
      "Fresh_zucchini": "Frische Zucchini",
      "Peanuts": "Erdnüsse",
      "Chard": "Mangold",
      "Leaf_beet": "Rübenblätter",
      "Hemp_for_seeds": "Hanf (Samen)",
      "Hemp_for_fiber": "Hanf (Fasern)",
      "Cardoon": "Artischocke",
      "Chestnut": "Kastanie",
      "Cherry_tree": "Kirschbaum",
      "French_bean": "Grüne Bohne",
      "Fava_bean": "Dicke Bohne",
      "Wheat": "Weizen",
      "Raspberry": "Himbeere",
      "Flax": "Flachs",
      "Ley_grass": "Lupine",
      "Corn_for_grain": "Aubergine",
      "Hazelnut": "Haselnuss",
      "Pear_tree": "Birnenbaum",
      "Peach_tree": "Pfirsichbaum",
      "Protein_pea": "Protein-Erbsen",
      "Processing_tomato": "Verarbeitete Tomaten",
      "Table_tomato": "Tafel-Tomate",
      "Table_grape": "Tafeltraube",
      "Gooseberry": "Stachelbeere",
      "Grapevine": "Weinstock",
      "Clover": "Klee",
      "Corn_salad": "Feldsalat",
      "Grapevine_for_wine_grapes": "Weinstock für Weintrauben",
      "Sweet_corn": "Zuckermais",
      "Silage_corn": "Maissilage",
      "Oilseed_rape": "Raps",
      "Field-grown_bell_pepper": "Feldpaprika",
      "Pea_for_industry": "Industrie-Erbsen",
      "Fresh_pea": "Frische Erbse",
      "Greenhouse_table_tomato": "Gewächshaus-Tafel-Tomate",
      "Clover_grassland": "Klee-Weideland",
      "Hill_pastures": "Hügelweiden",
      "Mixed_grassland_>50_legumes": "Gemischtes Weideland >50% Leguminosen",
      "Artificial_mixed_hill_pastures": "Künstliche gemischte Hügelweiden",
      "Stable_plains": "Stabile Ebenen",
      "Silage_fodder": "Silagefutter",
      "Arugula_1st_cut": "Rucola, 1. Schnitt",
      "Arugula_2nd_cut": "Rucola, 2. Schnitt",
      "Shallot": "Schalotte",
      "Arugula_in_open_field": "Rucola im Freiland",
      "Organic vs Conventional:": "Bio vs Konventionell:",
      "Are you sure to edit the polygon? if yes press again the Update Field button": "Sind Sie sicher, das Polygon bearbeiten zu wollen? Wenn ja, drücken Sie erneut auf die Schaltfläche 'Feld aktualisieren'",
      "Set the Year of analysis": "Jahr der Analyse festlegen",
      "Set the nutrient to visualize": "Nährstoff zur Visualisierung festlegen",
      "Carbonates": "Carbonate",
      "Soil Electrical Conductivity": "Bodenleitfähigkeit",
      "pH": "pH-Wert",
      "Please set the year": "Bitte legen Sie das Jahr fest",
      "Please set the nutrient": "Bitte legen Sie den Nährstoff fest",
      "Please set the farmer": "Bitte legen Sie den Landwirt fest",
      "Please set the field": "Bitte legen Sie das Feld fest",
      "The map is not ready yet": "Die Karte ist noch nicht bereit",
      "Please select the object of your request": "Bitte wählen Sie das Objekt Ihrer Anfrage aus",
      "Please report a valid message": "Bitte geben Sie eine gültige Nachricht ein",
      "Send us your message": "Senden Sie uns Ihre Nachricht",
      "Object of the request:": "Objekt der Anfrage:",
      "Object:": "Objekt:",
      "Support Request": "Supportanfrage",
      "Upgrade account": "Konto aktualisieren",
      "Problem with the application": "Problem mit der Anwendung",
      "Other": "Andere",
      "Message of the request:": "Nachricht der Anfrage:",
      "Message:": "Nachricht:",
      "Send message": "Nachricht senden",
      "My account": "Mein Konto",
      "Do you want to upgrade your account?": "Möchten Sie Ihr Konto aktualisieren?",
      "Send us a message": "Senden Sie uns eine Nachricht",
      "Your Message have been sent with success": "Ihre Nachricht wurde erfolgreich gesendet",
      "As soon as possible one of our operators will check your request and answer you": "So schnell wie möglich wird einer unserer Betreiber Ihre Anfrage überprüfen und Ihnen antworten",
      "Frumento_tenero": "Winterweizen",
      "Winter Wheat": "Winterweizen",
      "Annual percentage increase": "Jährliche prozentuale Zunahme",
      "Tons of CO2 Sequestered": "Tonnen CO2 eingelagert",
      "Carbon Credits Generated": "Generierte Kohlenstoffgutschriften",
      "Soil Map": "Bodenkarte",
      "Temporal Variation": "Zeitliche Variation",
      "If you want to access to this module data, you must upgrade your account to PRO":"Wenn Sie auf die Daten dieses Moduls zugreifen möchten, müssen Sie Ihr Konto auf PRO aktualisieren.",
      "Large Scale Application": "Großanwendung",
      "Field Name": "Feldname",
      "Crop": "Ernte",
      "Anomaly": "Anomalie",
      "Declared": "Erklärt",
      "Class": "Klasse",
      "Percentage": "Prozentsatz",

      "Hectares Deleted":" Gelöschte Hektar",

      "Search City:":"Stadt suchen:",
      "Export Document":"Dokument exportieren",
      "Download Section":"Downloadbereich",
      "Download Field - Format ShapeFile":"Feld herunterladen - ShapeFile-Format",
      "Download Field - Format Excel":"Feld herunterladen - Excel-Format",
      "Download Farmer Data - Format Excel":"Bauerndaten herunterladen - Excel-Format",

      "The crop field is empty":"Das Erntefeld ist leer",


      "Create a farm": "Eine Farm erstellen",
      "Create a field": "Ein Feld erstellen",
      "Delete Account": "Konto löschen",
      "Are you sure to delete your account?": "Sind Sie sicher, dass Sie Ihr Konto löschen möchten?",
      "Are you sure to delete the account?": "Sind Sie sicher, dass Sie das Konto löschen möchten?",
      "Please be carefull cause if you delete your account all the data related will be removed": "Bitte seien Sie vorsichtig, denn wenn Sie Ihr Konto löschen, werden alle zugehörigen Daten entfernt",
      "If you want to proceed, please report your email ": "Wenn Sie fortfahren möchten, geben Sie bitte Ihre E-Mail-Adresse an ",
      " in the following form input": " im folgenden Formularfeld",
      "Deleting the user...": "Benutzer wird gelöscht...",
      "Delete account":"Benutzer löschen",
      "In order to register you should accept the Terms And Conditions":"Um sich zu registrieren, müssen Sie die Allgemeinen Geschäftsbedingungen akzeptieren.",
      "Upload Field":"Upload Field",
      "Upload Zip File where within there is the shapefile with all the fields":"Laden Sie eine Zip-Datei hoch, die eine Shapefile enthält, in der sich alle Felder befinden, die Sie erstellen möchten",
      "Create Fields":"Felder Erstellen"
    }
  }
}

  export default translation;
  
  