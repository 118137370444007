import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Col, Row, Container, Card, CardHeader, CardBody, Spinner } from 'reactstrap';
import { MapContainer, TileLayer, LayersControl, GeoJSON, FeatureGroup } from 'react-leaflet';

import axios from 'axios';
import Header from 'components/Headers/Header'
import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next';

function DeleteField() {

  const [fieldData, setFieldData] = useState({
    FieldName: '',
    ActualCrop: '',
    CropRotation: '',
    OrganicVSConventional: '',
    Field: '',
    Centroid: ''
  });

  const [loadingdata, setloadingdata] = useState(true);
  const [loadingsubmit, setloadingsubmit] = useState(true);

  const { fieldId } = useParams();

  const navigate = useNavigate();

  const token = useSelector((state) => state.userLogin.userInfo.token);
  
  ////////////
  const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);  // Use i18n to change language
      }
  }, [i18n]);
  ////////////

  useEffect(() => {
    const fetchData = async () => {
      try {

          const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
          }
          
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/${fieldId}/`, config);
          setFieldData({
            FieldName: response.data.properties.FieldName,
            ActualCrop: response.data.properties.ActualCrop,
            CropRotation: response.data.properties.CropRotation,
            OrganicVSConventional: response.data.properties.OrganicVSConventional,
            Field: response.data,
            Centroid: response.data.properties.centroid
          });
          setloadingdata(false)
      } catch (error) {
          console.error('Error fetching field data:', error);
      }
    };

    fetchData();
  }, [fieldId, token]);

  const deleteField = async () => {
    setloadingsubmit(false)
    try {

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        await axios.delete(`${process.env.REACT_APP_BACKEND_API}/field/delete/${fieldId}/`, config);
        setloadingsubmit(true)
        navigate('/field/');
    } catch (error) {
        console.error(`Error deleting field with ID ${fieldId}:`, error);
    }
};

  return (
    <div>
      <Header/>
      {loadingdata ? (
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>
                  {t('Loading')}
                </Spinner>
              </Col>
            </Row>
          </Container>
        </>
      ):(
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <h3>{t('Are you sure to delete this field?')}</h3>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                {fieldData.Field && (
                  <Card>
                    <CardHeader>
                      {t('Field:')} {fieldData.FieldName}
                    </CardHeader>
                    <CardBody>
                      <MapContainer center={[fieldData.Centroid[1], fieldData.Centroid[0]]} zoom={14} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }}>
                          <LayersControl position="topright">
                              <LayersControl.BaseLayer checked name="OpenStreetMap">
                                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                              </LayersControl.BaseLayer>
                              <LayersControl.BaseLayer checked name="Satellite">
                                  <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                              </LayersControl.BaseLayer>
                          </LayersControl>
                          <FeatureGroup>
                              <GeoJSON      
                              data={{
                                  type: 'Feature',
                                  geometry: {
                                  type: 'Polygon',
                                  coordinates: fieldData.Field.geometry.coordinates,
                                  },
                                  properties: fieldData.Field.properties,
                              }}
                              style={{ fillColor: 'red', weight: 2, opacity: 1, color: 'red', fillOpacity: 0.3 }}
                              />
                          </FeatureGroup>
                      </MapContainer>
                      <hr></hr>
                        <h3>{t('Field Detail')}</h3>
                        {t('Crop Rotation:')} {t(`translation:${fieldData.CropRotation}`)}
                        <br></br>
                        {t('Crop:')} {t(`translation:${fieldData.ActualCrop}`)}
                        <br></br>
                        {t('Agronomic Management:')} {t(`translation:${fieldData.OrganicVSConventional}`)}
                    </CardBody>
                  </Card>
                )}
                <br></br>
              </Col>
              
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="d-flex justify-content-center">
                {loadingsubmit ? (
                    <>
                        <Button color="danger" onClick={deleteField}>
                            {t('Delete Field')} <i className="fas fa-trash"></i>
                        </Button>
                    </>
                    ) : (
                    <>
                      <Button color="danger" disabled>
                        <Spinner size="sm">
                            {t('Deleting the field...')}
                        </Spinner>
                        <span>
                          {' '}{t('Deleting the field...')}
                        </span>
                      </Button>
                    </>
                    )
                }
                <div className="mx-2"></div>
                <Link to="/field">
                    <Button color="primary">
                        {t('Go back')} <i className="fas fa-rotate-left"></i>
                    </Button>
                </Link>                                           
              </Col>
            </Row>
          </Container>
        </>
      )
    }
      
    </div>
  )
}

export default DeleteField
