import React, { useEffect, useState } from 'react';
import {Row, Col, Spinner, Container, Card, CardBody, CardHeader, Button} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import {useSelector} from 'react-redux'
import Header from 'components/Headers/Header';
import axios from 'axios'
import * as XLSX from 'xlsx';

function ExportDocument() {
  
  const [loadingfield, setLoadingfield] = useState(true)
  const [loadingfieldinfo, setLoadingfieldinfo] = useState(true)
  const [loadingfarm, setLoadingfarm] = useState(true)

  const token = useSelector((state) => state.userLogin.userInfo.token);

  ////////////

  const { t, i18n } = useTranslation();

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  const dowloadshape = async (e) => {

    setLoadingfield(false)
  
    try {

      const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'arraybuffer', 
      } 

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/export/field`, config)  
      
      const blob = new Blob([response.data], { type: 'application/zip' });

      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
  
      link.href = url;
  
      link.setAttribute('download', 'Field.zip');
  
      document.body.appendChild(link);
  
      link.click();
  
      document.body.removeChild(link);
  
      window.URL.revokeObjectURL(url);
  
    } catch (error) {

      console.log(error)

    } finally {

      setLoadingfield(true)

    }
      
  }

  const dowloadfieldinfo = async (e) => {

    setLoadingfieldinfo(false)
  
    try {

      const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
      } 

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/export/field-without-geometry`, config)  
  
      const ws = XLSX.utils.json_to_sheet(response.data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

      XLSX.writeFile(wb, 'data.xlsx');

    } catch (error) {

      console.log(error)

    } finally {
      setLoadingfieldinfo(true)

    }
    
  }

  const dowloadfarm = async (e) => {

    setLoadingfarm(false)
  
    try {

      const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
      } 

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/export/farm`, config)  
  
      const ws = XLSX.utils.json_to_sheet(response.data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

      XLSX.writeFile(wb, 'data.xlsx');

    } catch (error) {

      console.log(error)

    } finally {

      setLoadingfarm(true)
    }
    
  }

  return (
    <div>
        <Header />
        <Container className="mt--15" fluid>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
            <Card className="shadow">
              <CardHeader>
                <h3>{t('Download Section')}</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                    {loadingfield ? (
                      <>
                        <Button color='success' onClick={dowloadshape}>{t("Download Field - Format ShapeFile")} {" "} <i class="fa-solid fa-map"></i></Button>
                      </>
                    ):(
                      <>
                        <Button color="success" disabled>
                          <Spinner size="sm">
                          {t('Loading...')}
                          </Spinner>
                          <span>
                            {' '}{t('Loading')}
                          </span>
                        </Button>
                      </>
                    )}
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                    {loadingfieldinfo ? (
                      <>
                        <Button color='info' onClick={dowloadfieldinfo}>{t("Download Field - Format Excel")} {" "} <i class="fa-solid fa-file-excel"></i></Button>
                      </>
                    ):(
                      <>
                        <Button color="info" disabled>
                          <Spinner size="sm">
                          {t('Loading...')}
                          </Spinner>
                          <span>
                            {' '}{t('Loading')}
                          </span>
                        </Button>
                      </>
                    )}                    
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                    {loadingfarm ? (
                      <>
                        <Button color='primary' onClick={dowloadfarm}>{t("Download Farmer Data - Format Excel")} {" "} <i class="fa-solid fa-file-excel"></i></Button>
                      </>
                    ):(
                      <>
                        <Button color="primary" disabled>
                          <Spinner size="sm">
                          {t('Loading...')}
                          </Spinner>
                          <span>
                            {' '}{t('Loading')}
                          </span>
                        </Button>
                      </>
                    )}                    
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Container>
    </div>
  )
}

export default ExportDocument
