import React from "react";
import { useLocation, Route, Routes } from "react-router-dom";

import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import Index from "views/Index.js";

// Farm
import Farm from '../views/examples/farm/Farm'
import FarmCreate from '../views/examples/farm/FarmCreate'
import FarmDelete from '../views/examples/farm/FarmDelete'
import FarmUpdate from '../views/examples/farm/FarmUpdate'

// Field
import Field from '../views/examples/field/Field'
import CreateField from '../views/examples/field/CreateField'
import DeleteField from '../views/examples/field/DeleteField'
import UpdateField from '../views/examples/field/UpdateField'
import UploadField from '../views/examples/field/UploadField'

// IoT
import IoTSummary from '../views/examples/iot/IoTSummary'
import IoTAnalysis from '../views/examples/iot/IoTAnalysis'
import IoTAnomaly from '../views/examples/iot/IoTAnomaly'
import IoTComparation from '../views/examples/iot/IoTComparation'
import IoTTable from '../views/examples/iot/IoTTable'

// Allert
import Allert from '../views/examples/iot/Allert'
import CreateAllert from '../views/examples/iot/CreateAllert'
import DeleteAllert from '../views/examples/iot/DeleteAllert'
import UpdateAllert from '../views/examples/iot/UpdateAllert'

// SoilMapping
import SoilMapping from '../views/examples/soilmapping/SoilMapping'

// BioVsConv
import BioVsConv from '../views/examples/bioconv/BioVsConv'
import LargeScale from '../views/examples/bioconv/LargeScale'

// Payment
import UpgradeYourProfile from '../views/examples/profile/UpgradeYourProfile'
import PaymentSuccess from '../views/examples/profile/PaymentSuccess'

// Profile
import Profile from '../views/examples/profile/Profile'
import Support from '../views/examples/profile/Support'
import SupportMessageSendSuccess from '../views/examples/profile/SupportMessageSendSuccess'

import ExportDocument from '../views/examples/exportdocument/ExportDocument'

import ScrollToTop from "react-scroll-to-top";

import { useSelector } from 'react-redux'; 
import { useState } from "react";
import { imageOverlay } from "leaflet";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const farmerpro = useSelector((state) => state.userLogin.userInfo.is_farmer_pro);

  const [isFarmerPro, setIsFarmerPro] = useState(farmerpro);

  return (
    <>
      <ScrollToTop smooth color="#6f00ff" />
      <Sidebar
        {...props}
        logo={{
          innerLink: "/",
          imgSrc: require("../assets/img/brand/argon-react.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={"Marco"}
        />
        <Routes>
          <Route path="/" element={<Index />} />
          
          <Route path="/farm" element={<Farm />} />
          <Route path="/create-farm" element={<FarmCreate />} />
          <Route path="/update-farm/:fieldId" element={<FarmUpdate />} />
          <Route path="/delete-farm/:fieldId" element={<FarmDelete />} />

          <Route path="/field" element={<Field />} />
          <Route path="/create-field" element={<CreateField />} />
          <Route path="/update-field/:fieldId" element={<UpdateField />} />
          <Route path="/delete-field/:fieldId" element={<DeleteField />} />
          <Route path="/upload-field" element={<UploadField />} />

          {isFarmerPro ? (
            <>
              <Route path="/summary" element={<IoTSummary />} />
            </>
          ):(
            <>
            </>
          )}
          {isFarmerPro ? (
            <>
              <Route path="/iot-analisys" element={<IoTAnalysis />} />
            </>
          ):(
            <>
            </>
          )}
          {isFarmerPro ? (
            <>
               <Route path="/iot-anomalies" element={<IoTAnomaly />} />
            </>
          ):(
            <>
            </>
          )}         
          {isFarmerPro ? (
            <>
              <Route path="/iot-comparation" element={<IoTComparation />} /> 
            </>
          ):(
            <>
            </>
          )}
          {isFarmerPro ? (
            <>
              <Route path="/iot-table" element={<IoTTable />} /> 
            </>
          ):(
            <>
            </>
          )}
          {isFarmerPro ? (
            <>
              <Route path="/allert" element={<Allert />} />
            </>
          ):(
            <>
            </>
          )}
          {isFarmerPro ? (
            <>
              <Route path="/create-allert" element={<CreateAllert />} />
            </>
          ):(
            <>
            </>
          )}
          {isFarmerPro ? (
            <>
              <Route path="/update-allert/:fieldId" element={<UpdateAllert />} />
            </>
          ):(
            <>
            </>
          )}
          {isFarmerPro ? (
            <>
              <Route path="/delete-allert/:fieldId" element={<DeleteAllert />} />
            </>
          ):(
            <>
            </>
          )}
          {isFarmerPro ? (
            <>
              <Route path="/carbon-credits" element={<SoilMapping />} />
            </>
          ):(
            <>
            </>
          )} 
          {isFarmerPro ? (
            <>
              <Route path="/organic-vs-convenctional" element={<BioVsConv />} />
            </>
          ):(
            <>
            </>
          )}  
          {isFarmerPro ? (
            <>
              <Route path="/large-scale" element={<LargeScale />} />
            </>
          ):(
            <>
            </>
          )}
          <Route path="/user-profile" element={<Profile />} />
          <Route path="/support" element={<Support />} />
          <Route path="/support-message-send-success" element={<SupportMessageSendSuccess />} />
          <Route path="/export-document" element={<ExportDocument />} />
          <Route path="/upgrade-your-profile" element={<UpgradeYourProfile />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
        </Routes>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
