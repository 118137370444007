import React, { useEffect, useState } from 'react';
import {Row, Col, Spinner, Container, Card, CardBody, CardHeader, Alert, Button, CardTitle} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import {useSelector} from 'react-redux'
import Header from 'components/Headers/Header';
import {Form} from 'react-bootstrap'
import { MapContainer, TileLayer, LayersControl, GeoJSON, FeatureGroup } from 'react-leaflet';
import axios from 'axios'

function BioVsConv() {
    
    const [loading, setloading] = useState(true)
    const [error, seterror] = useState('')
    const [message, setmessage] = useState('')

    const [loadingsubmitting, setloadingsubmitting] = useState(true)

    const [farmeroption, setfarmeroption] = useState([])
    const [farmerselected, setfarmerselected] = useState('')

    const [fieldoption, setfieldoption] = useState([])
    const [fieldselected, setfieldselected] = useState('')

    const [indexhtml, setindexhtml] = useState('')
    const [sarhtml, setsarhtml] = useState('')
    const [maphtml, setmaphtml] = useState('')
    const [percentage, setpercentage] = useState('')

    const [loadingindexhtml, setloadingindexhtml] = useState(true)
    const [loadingsarhtml, setloadingsarhtml] = useState(true)
    const [loadingmaphtml, setloadingmaphtml] = useState(true)
    const [loadingpercentage, setloadingpercentage] = useState(true)

    const [fieldData, setfieldData] = useState({
        Field: '',
        Centroid: '',
        Crop: '',
        CropRotation: '',
        OrganicVsConventional: ''
    });

    const token = useSelector((state) => state.userLogin.userInfo.token);

    ////////////
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);
    ////////////

    useEffect(() => {
        
        const fetchData = async () => {
            try {

                const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
                }
                
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/`, config);
                                
                setfarmeroption(response.data);

            } catch (error) {
                setmessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
                seterror(true)
                setTimeout(function() {
                    seterror(false)
                }, 5000);
                return;
            } finally {
                setloading(false)
            }
        };

        fetchData(); 
    }, [token]);

    const handleSelectChange1 = async (e) => {
        
        setfarmerselected(e.target.value)
        
        const farmersele = e.target.value

        try {

            const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
            }
            
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-by-farmer/${farmersele}`, config);
                        
            setfieldoption(response.data);

        } catch (error) {
            setmessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            seterror(true)
            setTimeout(function() {
                seterror(false)
            }, 5000);
            return;
        }
    };

    const handleSelectChange2 = async (e) => {
        
        setfieldselected(e.target.value)
        
        const fieldsele = e.target.value

        try {
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
        
            const responsefield = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${fieldsele}`, config);
        
            setfieldData({
              Field: responsefield.data,
              Centroid: responsefield.data.properties.centroid,
              Crop: responsefield.data.properties.ActualCrop,
              CropRotation: responsefield.data.properties.CropRotation,
              OrganicVSConventional: responsefield.data.properties.OrganicVSConventional
            });

        } catch(error) {
            console.log(error)
            setmessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            seterror(true)
            setTimeout(function() {
                seterror(false)
            }, 5000);
            return;
        }
    };

    const submit = async (e) => {

        setloadingsubmitting(false)

        if (!fieldData.Crop) {
            setmessage(t('Before you can run the model you should define the crop type. Go to the Field and update section the information'))
            seterror(true)
            setTimeout(function() {
                seterror(false)
            }, 5000);
            setloadingsubmitting(true)
            return;
        }   
        
        setloadingindexhtml(false)
        setloadingsarhtml(false)
        setloadingmaphtml(false)
        setloadingpercentage(false)

        try {
            // farmer / field / crop

            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            } 
            
            // grafico index

            const responseindex = await axios.get(`${process.env.REACT_APP_BACKEND_API}/biovsconv/graph-comparation-index/${fieldselected}`, config)

            setloadingindexhtml(true)
            setindexhtml(responseindex.data);

            // grafico sar

            const responsesar = await axios.get(`${process.env.REACT_APP_BACKEND_API}/biovsconv/graph-comparation-sar/${fieldselected}`, config)

            setloadingsarhtml(true)
            setsarhtml(responsesar.data);
            

            // mappa probabilitica

            const responsemap = await axios.get(`${process.env.REACT_APP_BACKEND_API}/biovsconv/probabilistic-map/${fieldselected}`, config)

            setloadingmaphtml(true)
            setmaphtml(responsemap.data);
            
            // percentuale di differenza tra bio e conv

            const responsepercentage = await axios.get(`${process.env.REACT_APP_BACKEND_API}/biovsconv/percentage-difference/${fieldselected}`, config)

            setloadingpercentage(true)
            setpercentage(responsepercentage.data);

        } catch(error) {
            
            console.log(error)

        } finally {           
            
            setloadingsubmitting(true)

        }
    };
    

    return (
        <div>
            <Header />
            {loading ? (
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">{t('Loading...')}</span>
                                </Spinner>
                            </Col>
                        </Row>
                    </Container>
                </>
            ):(
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Card>
                                    <CardHeader>
                                        <h3>{t('Set the Field where apply the analysis')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                                                <Form>
                                                    <Form.Label>{t('Set the Farmer')}</Form.Label>
                                                    <Form.Control 
                                                        as="select" 
                                                        onChange={handleSelectChange1}
                                                        value={farmerselected}>
                                                            <option value={""}>
                                                                
                                                            </option>
                                                        {farmeroption.map((farmer, index) => (
                                                            <option key={index} value={farmer.NameFarmer}>
                                                                {farmer.NameFarmer}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                    <br></br>
                                                    <Form.Label>{t('Set the Field')}</Form.Label>
                                                    <Form.Control 
                                                        as="select" 
                                                        onChange={handleSelectChange2}
                                                        value={fieldselected}>
                                                            <option value={""}>
                                                                
                                                            </option>
                                                        {fieldoption.map((farmer, index) => (
                                                            <option key={index} value={farmer}>
                                                                {farmer}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </Form>
                                                {fieldData.Field && (
                                                    <>
                                                        <br></br>
                                                        <br></br>
                                                        <h3>{t('Actual Crop:')}</h3>
                                                        <h3>{t(`translation:${fieldData.Crop}`)}</h3>
                                                        <br></br>
                                                        <h3>{t('Crop Rotation:')}</h3>
                                                        <h3>{t(`translation:${fieldData.CropRotation}`)}</h3>
                                                        <br></br>
                                                        <h3>{t('Organic vs Conventional:')}</h3>
                                                        <h3>{t(`translation:${fieldData.OrganicVSConventional}`)}</h3>
                                                        <br></br>
                                                        {loadingsubmitting ? (
                                                            <>
                                                                <Button color='success' onClick={submit}>
                                                                    {t('Run The Analysis')}
                                                                </Button>
                                                            </>
                                                        ):(
                                                            <>
                                                                <Button color="success" disabled>
                                                                    <Spinner size="sm">
                                                                        Loading...
                                                                    </Spinner>
                                                                    <span>
                                                                    {' '}{t('Loading')}
                                                                    </span>
                                                                </Button>
                                                            </>
                                                        )}
                                                        <br></br>
                                                        {error && <Alert color="danger" fade={false}>{message}</Alert>}
                                                    </>
                                                )}
                                            </Col>
                                            <br></br>                              
                                            {fieldData.Field && (
                                                <>
                                                    <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9}>
                                                        <br></br>
                                                        <MapContainer center={[fieldData.Centroid[1], fieldData.Centroid[0]]} zoom={16} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }} key={JSON.stringify(fieldData.Field)}>
                                                            <LayersControl position="topright">
                                                                <LayersControl.BaseLayer checked name="OpenStreetMap">
                                                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                                                </LayersControl.BaseLayer>
                                                                <LayersControl.BaseLayer checked name="Satellite">
                                                                    <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                                                                </LayersControl.BaseLayer>
                                                            </LayersControl>
                                                            <FeatureGroup>
                                                            <GeoJSON      
                                                                data={{
                                                                    type: 'Feature',
                                                                    geometry: {
                                                                    type: 'Polygon',
                                                                    coordinates: fieldData.Field.geometry.coordinates,
                                                                    },
                                                                    properties: fieldData.Field.properties,
                                                                }}
                                                                style={{ fillColor: 'blue', weight: 1, opacity: 1, color: 'blue', fillOpacity: 0.15 }}
                                                            />
                                                            </FeatureGroup>
                                                        </MapContainer>
                                                        <br></br>
                                                        <br></br> 
                                                    </Col>
                                                </>
                                            )}
                                            {indexhtml ? (
                                                <>  
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                                        <br></br>
                                                        <h3>{t('NDVI Comparation')}</h3>
                                                        <br></br>
                                                        {loadingindexhtml ? (
                                                            <>
                                                            </>
                                                        ):(
                                                            <>
                                                                <Spinner>
                                                                    {t('Loading')}
                                                                </Spinner>
                                                            </>
                                                        )}
                                                        <br></br>
                                                        <iframe id="iframe1irnvoinoa" width="100%" height="400" srcDoc={indexhtml}></iframe>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>  
                                                    </Col>                                                   
                                                </>
                                            ):(
                                                <>
                                                </>
                                            )}
                                            {sarhtml ? (
                                                <>
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                                        <br></br>
                                                        <h3>{t('SAR Comparation')}</h3>
                                                        <br></br>
                                                        {loadingsarhtml ? (
                                                            <>
                                                            </>
                                                        ):(
                                                            <>
                                                                <Spinner>
                                                                {t('Loading')}
                                                                </Spinner>
                                                            </>
                                                        )}      
                                                        <br></br>                                                  
                                                        <iframe id="iframe1irnvoinob" width="100%" height="400" srcDoc={sarhtml}></iframe>
                                                        <br></br>
                                                        <br></br> 
                                                        <br></br>
                                                        <br></br> 
                                                    </Col>                                                
                                                </>
                                            ):null}
                                            {maphtml ? (
                                                <>
                                                    <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9}>
                                                        <br></br>
                                                        <h3>{t('Probabilistic Map')}</h3>
                                                        <br></br>
                                                        {loadingmaphtml ? (
                                                            <>
                                                            </>
                                                        ):(
                                                            <>
                                                                <Spinner>
                                                                {t('Loading')}
                                                                </Spinner>
                                                            </>
                                                        )}
                                                        <br></br>
                                                        <iframe id="iframe1irnvoinoc" width="100%" height="400" srcDoc={maphtml}></iframe>  
                                                        <br></br>
                                                        <br></br> 
                                                        <br></br>
                                                        <br></br>    
                                                    </Col>                                                    
                                                </>
                                            ):null}
                                            {percentage ? (
                                                <>
                                                    <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                                                        <h3>{t('Summary')}</h3>
                                                        <br></br>
                                                        {loadingpercentage ? (
                                                            <>
                                                            </>
                                                        ):(
                                                            <>
                                                                <Spinner>
                                                                {t('Loading')}
                                                                </Spinner>
                                                            </>
                                                        )}
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <Card className="card-stats mb-4 mb-xl-0">
                                                            <CardBody>
                                                                <Row>
                                                                    <div className="col">
                                                                        <CardTitle
                                                                        tag="h5"
                                                                        className="text-uppercase text-muted mb-0"
                                                                        >
                                                                        {t('% Convenctional')}
                                                                        </CardTitle>
                                                                        <span className="h2 font-weight-bold mb-0">
                                                                        {percentage && (
                                                                            <>
                                                                                {percentage[0].percentage}
                                                                            </>
                                                                        )}
                                                                        </span>
                                                                    </div>
                                                                    <Col className="col-auto">
                                                                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                                        <i className="fa fa-industry" />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                        <br></br>
                                                        <hr></hr>
                                                        <br></br>
                                                        <Card className="card-stats mb-4 mb-xl-0">
                                                            <CardBody>
                                                                <Row>
                                                                    <div className="col">
                                                                        <CardTitle
                                                                        tag="h5"
                                                                        className="text-uppercase text-muted mb-0"
                                                                        >
                                                                        {t('% Organic')}
                                                                        </CardTitle>
                                                                        <span className="h2 font-weight-bold mb-0">
                                                                        {percentage && percentage[1] &&  (
                                                                            <>
                                                                                {percentage[1].percentage}
                                                                            </>
                                                                        )}
                                                                        </span>
                                                                    </div>
                                                                    <Col className="col-auto">
                                                                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                                                        <i className="fa fa-leaf" />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                        <br></br>
                                                        <hr></hr>
                                                        <br></br>
                                                        <h3>{t('The field was declared by the farmer as')} {': '} {t(`translation:${fieldData.OrganicVSConventional}`)}</h3>
                                                    </Col>
                                                </>
                                            ):null} 
                                        </Row>                             
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </>
            )}
        </div>
    );
}

export default BioVsConv;
