import React, {useEffect, useState, useRef } from 'react'
import {Row, Col, Container, Spinner, Button, Alert, Card, CardBody} from 'reactstrap'
import {Form} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'

import { useTranslation } from 'react-i18next';

import Header from 'components/Headers/Header';
import { useSelector } from 'react-redux';
import axios from 'axios'

import { MapContainer, TileLayer, LayersControl, FeatureGroup, Marker } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import { 
    setDefaults,
    fromAddress,
  } from "react-geocode";

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet'; // Import leaflet library

function CreateField() {

    const mapRef = useRef(null);

    const [mapCenter, setMapCenter] = useState([45.1657, 10.4515]); // Imposta una posizione di default per il centro della mappa
    const [mapZoom, setMapZoom] = useState(4);
    
    const [markerPosition, setMarkerPosition] = useState(null);
    const [city, setCity] = useState('')

    setDefaults({
        key: "AIzaSyBzLuHkUEaOXswyeywIeljQ88BXDOZmajU", // Your API key here.
        language: "en", // Default language for responses.
        region: "es", // Default region for responses.
      });

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fromAddress(city); // Eseguire la richiesta API di geocoding
                const { lat, lng } = response.results[0].geometry.location;
                setMarkerPosition([lat, lng]); // Imposta le coordinate del marker nello stato
                setMapCenter([lat, lng])
                setMapZoom(15)

                mapRef.current.flyTo([lat, lng], 15, {
                    duration: 5 // Durata dell'animazione in secondi
                });
            } catch (error) {
                console.error('Errore durante la richiesta geocoding:', error);
            }
        };
    
        fetchData();
    }, [city]);

    const customMarkerIcon = new L.Icon({
        iconUrl: markerIcon,
        shadowUrl: markerIconShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });

    const [loading, setLoading] = useState(true);
    const [loadingsubmit, setloadingsubmit] = useState(true);
    const [farmernameoptions, setfarmernameoptions] = useState([]);
    const [farmername, setfarmername] = useState([]);
    const [fieldname, setfieldname] = useState('');
    const [croprotation, setcroprotation] = useState('perennial');
    const [crop1, setcrop1] = useState('');
    const [crop2, setcrop2] = useState('');
    const [crop3, setcrop3] = useState('');
    const [orgvsconv, setorgvsconv] = useState('Organic');

    const [pkfarmer, setpkfarmer] = useState([]);
    const [selectedFarmer, setSelectedFarmer] = useState('');
    const [selectedPkFarmer, setSelectedPkFarmer] = useState('');

    const [mapLayers, setMapLayers] = useState([]);
    const [isDrawing, setIsDrawing] = useState(true);

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const navigate = useNavigate();

    const token = useSelector((state) => state.userLogin.userInfo.token);
    const userId = useSelector((state) => state.userLogin.userInfo.id);

    useEffect(() => {

      const fetchData = async () => {

        try {
  
          const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
          }

          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);
          
          const extractedNames = response.data.map(item => item.NameFarmer);
          setfarmernameoptions(extractedNames);
          
          const pkfarmers = response.data.map(item => item.id);
          setpkfarmer(pkfarmers)          
          
          setLoading(false)

        } catch (error) {
          console.error('Errore durante la richiesta GET:', error);
        }
        
      };
  
      fetchData();
    }, [token]);

    ////////////
    const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);  // Use i18n to change language
        }
    }, [i18n]);
    ////////////

    const renderTextInputFields = () => {
        switch (croprotation) {
          case 'perennial':
            return (
                <>  
                    <Form>
                        <Form.Label>{t('Crop Rotation:')}</Form.Label>
                        <br></br>
                        <Form.Label>{t('Actual Crop')}</Form.Label>
                        <br></br>
                        <Form.Select placeholder={t('Actual Crop')} type="select" value={crop1} onChange={(e) => setcrop1(e.target.value)}>
                            <option value=""></option>
                            <option value="Vineyards">{t('Vineyards')}</option>
                            <option value="Alfalfa">{t('Alfalfa')}</option>
                        </Form.Select>
                    </Form>
                </>
            );
          case 'biennial':
            return (
              <>
                <Form.Label>{t('Crop Rotation:')}</Form.Label>
                <br></br>
                <Form.Label>{t('Actual Crop')}</Form.Label>
                <br></br>
                <Form.Select placeholder={t('Actual Crop')} type="select" value={crop1} onChange={(e) => setcrop1(e.target.value)}>
                    <option value=""></option>
                    <option value="Alfalfa">{t('Alfalfa')}</option>
                    <option value="Barley">{t('Barley')}</option>
                    <option value="Maize">{t('Maize')}</option>
                    <option value="Sunflower">{t('Sunflower')}</option>
                    <option value="Sorghum">{t('Sorghum')}</option>
                    <option value="Durum_wheat">{t('Durum Wheat')}</option>                    
                    <option value="Frumento_tenero">{t('Winter Wheat')}</option>      
                    <option value="Soia">{t('Soy')}</option>             
                </Form.Select>
                <br></br>
                <Form.Label>{t('The next year Crop')}</Form.Label>
                <br></br>
                <Form.Select placeholder={t('The next year Crop')} type="select" value={crop2} onChange={(e) => setcrop2(e.target.value)}>
                    <option value=""></option>
                    <option value="Alfalfa">{t('Alfalfa')}</option>
                    <option value="Barley">{t('Barley')}</option>
                    <option value="Maize">{t('Maize')}</option>
                    <option value="Sunflower">{t('Sunflower')}</option>
                    <option value="Sorghum">{t('Sorghum')}</option>
                    <option value="Durum_wheat">{t('Durum Wheat')}</option>
                    <option value="Frumento_tenero">{t('Winter Wheat')}</option>
                    <option value="Soia">{t('Soy')}</option>  
                </Form.Select>
              </>
            );
          case 'triennial':
            return (
              <>
                <Form.Label>{t('Crop Rotation:')}</Form.Label>
                <br></br>
                <Form.Label>{t('Actual Crop')}</Form.Label>
                <br></br>
                <Form.Select placeholder={t('Actual Crop')} type="select" value={crop1} onChange={(e) => setcrop1(e.target.value)}>
                    <option value=""></option>
                    <option value="Alfalfa">{t('Alfalfa')}</option>
                    <option value="Barley">{t('Barley')}</option>
                    <option value="Maize">{t('Maize')}</option>
                    <option value="Sunflower">{t('Sunflower')}</option>
                    <option value="Sorghum">{t('Sorghum')}</option>
                    <option value="Durum_wheat">{t('Durum Wheat')}</option>
                    <option value="Frumento_tenero">{t('Winter Wheat')}</option>
                    <option value="Soia">{t('Soy')}</option>  
                </Form.Select>
                <br></br>
                <Form.Label>{t('The next year Crop')}</Form.Label>
                <br></br>
                <Form.Select placeholder={t('The next year Crop')} type="select" value={crop2} onChange={(e) => setcrop2(e.target.value)}>
                    <option value=""></option>
                    <option value="Alfalfa">{t('Alfalfa')}</option>
                    <option value="Barley">{t('Barley')}</option>
                    <option value="Maize">{t('Maize')}</option>
                    <option value="Sunflower">{t('Sunflower')}</option>                    
                    <option value="Sorghum">{t('Sorghum')}</option>
                    <option value="Durum_wheat">{t('Durum Wheat')}</option>
                    <option value="Frumento_tenero">{t('Winter Wheat')}</option>
                    <option value="Soia">{t('Soy')}</option>  
                </Form.Select>
                <br></br>
                <Form.Label>{t("The crop in two years' time")}</Form.Label>
                <br></br>
                <Form.Select placeholder={t("The crop in two years' time")} type="select" value={crop3} onChange={(e) => setcrop3(e.target.value)}>
                    <option value=""></option>
                    <option value="Alfalfa">{t('Alfalfa')}</option>
                    <option value="Barley">{t('Barley')}</option>
                    <option value="Maize">{t('Maize')}</option>
                    <option value="Sunflower">{t('Sunflower')}</option>                    
                    <option value="Sorghum">{t('Sorghum')}</option>
                    <option value="Durum_wheat">{t('Durum Wheat')}</option>
                    <option value="Frumento_tenero">{t('Winter Wheat')}</option>
                    <option value="Soia">{t('Soy')}</option>  
                </Form.Select>
              </>
            );
          default:
            return null;
        }
    };
    
    const _onCreate = (e) => {

    if (!isDrawing) {
        return;
    }
    
    const { layerType, layer } = e;
    if (layerType === 'polygon') {
        const { _leaflet_id } = layer;
    
        // Convert LatLng objects to [lon, lat] array
        const latlngs = layer.getLatLngs()[0].map(({ lat, lng }) => [lng, lat]);
    
        // Ensure closure of the ring
        latlngs.push(latlngs[0]);
    
        setMapLayers([{ id: _leaflet_id, latlngs }]);
        setIsDrawing(false);
    }
    };

    const _onEdited = (e) => {
    const {
        layers: { _layers },
    } = e;
    
    Object.values(_layers).map(({ _leaflet_id, editing }) => {
        const latlngs = editing.latlngs[0].map(({ lat, lng }) => [lng, lat]);
    
        // Ensure closure of the ring
        latlngs.push(latlngs[0]);
    
        const geoJSON = {
        type: "Polygon",
        coordinates: [latlngs],
        };
    
        setMapLayers((layers) =>
        layers.map((l) =>
            l.id === _leaflet_id
            ? { ...l, geometry: geoJSON }
            : l
        )
        );
    });
    };
    
    const _onDeleted = (e) => {
    const {
        layers: { _layers },
    } = e;
    
    Object.values(_layers).map(({ _leaflet_id }) => {
        setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
    });
    
    setIsDrawing(true);
    };

    const handleRestart = () => {
        window.location.reload();
    };

    const handleFarmerSelection = (selectedValue) => {
        const selectedIndex = farmernameoptions.indexOf(selectedValue);
        const selectedPk = pkfarmer[selectedIndex];
        setSelectedFarmer(selectedValue);
        setSelectedPkFarmer(selectedPk);
      };

    const handleSubmit = () => {

        setloadingsubmit(false);

        if (farmername==='') {
            setMessage(t('The farmer company name is empty'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setloadingsubmit(true);
            return;
          } 

        if (fieldname==='') {
            setMessage(t('The field name is empty'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
                setloadingsubmit(true);
                return;
        } 

        if (mapLayers.length === 0) {
            setMessage(t('Please draw the edge of your field in the map'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setloadingsubmit(true);
            return;
        } 

        try {
            
            const formattedMapLayers = mapLayers.map(layer => layer.latlngs);
          
            const payload = {
                FieldName: fieldname,
                Farmer: selectedPkFarmer,
                Field: {
                    type: "Polygon",
                    coordinates: formattedMapLayers,
                },
                User: userId,
                ActualCrop:  crop1,
                CropRotation: croprotation,
                OrganicVSConventional: orgvsconv,
                SecondCrop: crop2,
                ThirdCrop: crop3,
            };
        
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }   
                
            axios.post(`${process.env.REACT_APP_BACKEND_API}/field/create/`, payload, config)

        } catch (error) {

            setMessage(t('An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setloadingsubmit(true);
            return;

        } finally {
            setloadingsubmit(true);
            navigate('/field/');
        }
        
    };

    return (
        <div>
            <Header/>
            {loading ? (
                <>
                    <Container className="mt--15" fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Spinner>
                            {t('Loading')}
                        </Spinner>
                        </Col>
                    </Row>
                    </Container>
                </>
            ):(
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <h3>{t('Create Field')}</h3>
                            </Col>
                                {farmernameoptions && farmernameoptions.length >0 ? (
                                    <>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                                            <Card>
                                                <CardBody>
                                                    <Form>
                                                        <Form.Label>{t('Farmer Company Name:')}</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            placeholder={t('Farmer Company Name:')}
                                                            value={selectedFarmer} 
                                                            onChange={(e) => handleFarmerSelection(e.target.value)}>
                                                                <option value={""}>
                                                                    
                                                                </option>
                                                            {farmernameoptions.map((option, index) => (
                                                                <option key={index} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                        <br></br>
                                                        <Form.Label>{t('Field name:')}</Form.Label>
                                                        <Form.Control 
                                                            type="text" 
                                                            placeholder={t('Field name:')} 
                                                            value={fieldname} 
                                                            onChange={(e) => setfieldname(e.target.value)}/>
                                                        <br></br>
                                                        <Form.Label>{t('Organic vs Convenctional')}</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            placeholder={t('Organic vs Convenctional')}
                                                            value={orgvsconv}
                                                            onChange={(e) => setorgvsconv(e.target.value)}
                                                            >
                                                            <option value={"Organic"}>
                                                                {t('Organic')}
                                                            </option>
                                                            <option value={"Convenctional"}>
                                                                {t('Convenctional')}                                                    
                                                            </option>                                             
                                                        </Form.Control>
                                                        <br></br>
                                                        <Form.Label>{t('Crop Rotation:')}</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            placeholder={t('Crop Rotation:')}
                                                            value={croprotation}
                                                            onChange={(e) => setcroprotation(e.target.value)}
                                                            >
                                                            <option value={"perennial"}>
                                                                {t('Perennial crop')}
                                                            </option>
                                                            <option value={"biennial"}>
                                                                {t('Two year crop rotation')}                                                    
                                                            </option>
                                                            <option value={"triennial"}>
                                                                {t('Three year crop rotation')}                                                    
                                                            </option>                                                
                                                        </Form.Control>
                                                        <br></br>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                                            <Card>
                                                <CardBody>
                                                    <Form>
                                                        <br></br>
                                                        {renderTextInputFields()}
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                            <Card>
                                                <CardBody>
                                                    <Form>
                                                        <h3>{t('Draw the edge of your field:')}</h3>
                                                        <br></br>
                                                        <Form.Control 
                                                            type="text" 
                                                            placeholder={t('Search City:')} 
                                                            value={city} 
                                                            onChange={(e) => setCity(e.target.value)}/>    
                                                        <br></br>
                                                        <MapContainer ref={mapRef} center={mapCenter} zoom={mapZoom} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }}>
                                                            <LayersControl position="topright">
                                                                <LayersControl.BaseLayer checked name="OpenStreetMap">
                                                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                                                </LayersControl.BaseLayer>
                                                                <LayersControl.BaseLayer checked name="Satellite">
                                                                    <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                                                                </LayersControl.BaseLayer>
                                                            </LayersControl>
                                                            <FeatureGroup>
                                                                <EditControl
                                                                    position="topleft"
                                                                    draw={{
                                                                    polygon: isDrawing, // Enable drawing only if isDrawing is true
                                                                    rectangle: false,
                                                                    polyline: false,
                                                                    circle: false,
                                                                    marker: false,
                                                                    circlemarker: false,
                                                                    }}
                                                                    onCreated={_onCreate}
                                                                    onEdited={_onEdited}
                                                                    onDeleted={_onDeleted}
                                                                />
                                                            </FeatureGroup>
                                                            {markerPosition && ( // Verifica se ci sono coordinate del marker prima di renderizzare il Marker
                                                                <Marker position={markerPosition} icon={customMarkerIcon}>
                                                                </Marker>
                                                            )}
                                                        </MapContainer>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        {error && 
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                                <Alert color="danger" fade={false}>{message}</Alert>
                                            </Col>                    
                                        }
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                            <Col className="d-flex justify-content-center">
                                                {loadingsubmit ? (
                                                    <>
                                                        <Button color="success" onClick={handleSubmit}>
                                                            {t('Create Field')} <i className="fas fa-plus"></i>
                                                        </Button>
                                                    </>
                                                    ) : (
                                                    <>
                                                        <Button color="success" disabled>
                                                        <Spinner size="sm">
                                                            {t('Creating the field...')}
                                                            </Spinner>
                                                            <span>
                                                            {' '}{t('Creating the field...')}
                                                            </span>
                                                        </Button>
                                                    </>
                                                    )
                                                }
                                                <div className="mx-2"></div>
                                                <Link to="/field">
                                                    <Button color="dark">
                                                        {t('Go back')} <i className="fas fa-rotate-left"></i>
                                                    </Button>
                                                </Link>
                                                <div className="mx-2"></div>
                                                <Button color="primary" onClick={handleRestart}>
                                                        {t('Restart Creation')} <i className="fas fa-rotate-left"></i>
                                                </Button>
                                            </Col>
                                        </Col>
                                    </>
                                ):(
                                    <>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                            <h3>{t("You don't have any farm registred, first you have to set at least one farmer")}</h3>
                                            <br></br>
                                            <br></br>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">                                                
                                            <Link to="/create-farm">
                                                <Button color="success">
                                                    {t('Create Farm')}
                                                </Button>
                                            </Link>
                                        </Col>
                                    </>
                                )}
                        </Row>
                    </Container>
                </>
            )
            }
        </div>
    )
}

export default CreateField
