import React from 'react'
import { useState } from "react";
import {useParams, useNavigate} from 'react-router-dom'
import axios from 'axios';

import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Alert,
    Spinner
  } from "reactstrap";

import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

function ActivateAccounts() {

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    
    const [loading, setLoading] = useState(true);

    const { uid, token } = useParams();

    const navigate = useNavigate();

    ////////////
    const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);  // Use i18n to change language
        }
    }, [i18n]);

    ////////////

    const handleSubmit = async (e) => {
        
        setLoading(false)

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/users/activation-account/${uid}/${token}/`);
            
            if (response.request.status===200) {
                setLoading(true)
                navigate('/account-activated/');
            } else {
                setMessage(t('Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com'))
                setError(true)
                setTimeout(function() {
                  setError(false)
                }, 5000);
                setLoading(true)
                return;
            }
        } catch (error) {
            console.error('Error Activation account:', error);
            setMessage(t('An error occurred during the activation fo your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoading(true)
            return;
        }
      };

    return (
      <>
        <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                <Row className="justify-content-center">
                    <Col lg="12" md="12">
                    <h1 >AIBIOSAT</h1>
                    <img
                        alt="Automatic Farm Solution"
                        src={"https://i.ibb.co/NZ8qZsk/111111.png"}
                        width={"200px"}
                        height={"200px"}
                    />
                    </Col>
                </Row>
                </div>
                <h4>{t("Do you want to activate your account?")}</h4>
                <br></br>
                {error && <Alert color="danger" fade={false}>{message}</Alert>}
                {loading ? (
                  <>
                    <Button className="my-4" color="success" type="button" onClick={handleSubmit}>
                      {t("Activate my account")} 
                    </Button>
                  </>
                ) : (
                  <>
                    <Button color="success" disabled>
                        <Spinner size="sm">
                            Loading...
                        </Spinner>
                        <span>
                        {' '}Loading
                        </span>
                    </Button>
                  </>
                )}
            </CardBody>
            </Card>
        </Col>
      </>
    )
}

export default ActivateAccounts
