import React, { useState, useEffect } from 'react';

import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

import "./stylesheadersbackgroundimage.css";

import axios from 'axios';
import { useSelector } from 'react-redux'; 
import { useTranslation } from 'react-i18next';

const Header = () => {
  
  const token = useSelector((state) => state.userLogin.userInfo.token);

  ////////////
  const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);  // Use i18n to change language
      }
  }, [i18n]);
  ////////////

  const [farmer, setfarmer] = useState('');
  const [fieldnumber, setfieldnumber] = useState('');
  const [hectares, sethectares] = useState('');
  const [detetedhectares, setdetetedhectares] = useState('');
  // const [anomalies, setanomalies] = useState('');

  useEffect(() => {

    const fetchData = async () => {
        try {

            const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
            }
            
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/number-farmer/`, config);
            
            setfarmer(response.data.number_of_farmers); // Assicurati che la risposta contenga l'array di opzioni

        } catch (error) {
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            // setError(false)
            // }, 5000);
            // return;
        }
    };

      fetchData(); 
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {
        try {

            const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
            }
            
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/number-field/`, config);
            
            setfieldnumber(response.data.number_of_field); // Assicurati che la risposta contenga l'array di opzioni

        } catch (error) {
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            // setError(false)
            // }, 5000);
            // return;
        }
    };

      fetchData(); 
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {
        try {

            const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
            }
            
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/field-hectares/`, config);
            
            sethectares(response.data.area_hectares); // Assicurati che la risposta contenga l'array di opzioni

        } catch (error) {
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            // setError(false)
            // }, 5000);
            // return;
        }
    };

      fetchData(); 
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {
        try {

            const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
            }
            
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/field-deleted-hectares/`, config);
            
            setdetetedhectares(response.data.area_hectares); // Assicurati che la risposta contenga l'array di opzioni

        } catch (error) {
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            // setError(false)
            // }, 5000);
            // return;
        }
    };

      fetchData(); 
  }, [token]);

  // useEffect(() => {

  //   const fetchData = async () => {
  //       try {

  //           const config = {
  //           headers: {
  //               'Content-type': 'application/json',
  //               Authorization: `Bearer ${token}`
  //           }
  //           }
            
  //           const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/anomalies/`, config);
            
  //           setanomalies(response.data.anomalies); // Assicurati che la risposta contenga l'array di opzioni

  //       } catch (error) {
  //           // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
  //           // setError(true)
  //           // setTimeout(function() {
  //           // setError(false)
  //           // }, 5000);
  //           // return;
  //       }
  //   };

  //     fetchData(); 
  // }, [token]);

  return (
    <>
      {/* <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"> */}
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {t('Number of farmer')}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {farmer && (
                            <>
                              {farmer}
                            </>
                          )}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fa fa-user" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {t('Number of field')}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                        {fieldnumber && (
                            <>
                              {fieldnumber}
                            </>
                          )}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <i className="fa fa-plus" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {t('Hectares Monitored')}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">                        
                          {hectares && (
                              <>
                                {hectares}
                              </>
                            )}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i className="fa fa-map" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {t('Hectares Deleted')}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">                        
                          {detetedhectares && (
                              <>
                                {detetedhectares}
                              </>
                            )}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                          <i className="fa fa-map" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {t('Anomalies')}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {anomalies && (
                            <>
                              {anomalies}
                            </>
                          )}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fa fa-warning" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
