import React, { useEffect, useState } from 'react';
import {Row, Col, Spinner, Container, Card, CardBody, CardHeader, Alert, Button, CardFooter, Table, Pagination, PaginationItem, PaginationLink} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import {useSelector} from 'react-redux'
import Header from 'components/Headers/Header';
import {Form} from 'react-bootstrap'
import axios from 'axios'
import * as XLSX from 'xlsx';

function LargeScale() {

  const [loadingdownload, setloadingdownload] = useState(true)
  const [loadingdownloadreport, setloadingdownloadreport] = useState(true)
  

  const [loading, setloading] = useState(true)
  const [error, seterror] = useState('')
  const [message, setmessage] = useState('')

  const [loadingsubmitting, setloadingsubmitting] = useState(true)

  const [farmeroption, setfarmeroption] = useState([])
  const [farmerselected, setfarmerselected] = useState('')

  const [maphtml, setmaphtml] = useState('')
  const [loadingmaphtml, setloadingmaphtml] = useState(true)

  const [percentage, setpercentage] = useState([])
  const [loadingpercentage, setloadingpercentage] = useState(true)

  const token = useSelector((state) => state.userLogin.userInfo.token);

  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 6; // Number of items per page

  const totalPages = Math.ceil(percentage.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, percentage.length - 1);
  const paginatedData = percentage.slice(startIndex, endIndex + 1);

  const setPage = (page) => {
    setCurrentPage(page);
  };

  ////////////
  const { t, i18n } = useTranslation();

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);
  ////////////

  useEffect(() => {
        
    const fetchData = async () => {
        try {

            const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
            }
            
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/`, config);
                            
            setfarmeroption(response.data);

        } catch (error) {
            setmessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            seterror(true)
            setTimeout(function() {
                seterror(false)
            }, 5000);
            return;
        } finally {
            setloading(false)
        }
    };

    fetchData(); 
  }, [token]);

  const submit = async (e) => {

    setloadingsubmitting(false)

    if (farmerselected==='') {
      setmessage(t('Before you can run the analysis you have to set the farmer company name'))
      seterror(true)
      setTimeout(function() {
          seterror(false)
      }, 5000);
      setloadingsubmitting(true)
      return;
    }

    setloadingmaphtml(false)
    setloadingpercentage(false)

    try {

      const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
      } 

      const responsepercentage = await axios.get(`${process.env.REACT_APP_BACKEND_API}/biovsconv/percentage-difference-large-scale/${farmerselected}`, config)

      setloadingpercentage(true)
      setpercentage(responsepercentage.data); 

      const responsemap = await axios.get(`${process.env.REACT_APP_BACKEND_API}/biovsconv/probabilistic-map-large-scale/${farmerselected}`, config)

      setloadingmaphtml(true)
      setmaphtml(responsemap.data);     
  
    } catch (error) {

      console.log(error)

    } finally {

      setloadingsubmitting(true)

    }
    
  }

  const download = async () => {

    setloadingdownload(false);

    try {            

      const ws = XLSX.utils.json_to_sheet(percentage);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    
      XLSX.writeFile(wb, 'data.xlsx');

    } catch (error) {  
        console.log(error)
    } finally {
        setloadingdownload(true);
    }

  };

  const downloadReport = async () => {

    setloadingdownloadreport(false)

    try {

      const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
      } 

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/biovsconv/download-report-large-scale/${farmerselected}`, config)  

      const blob = new Blob([response.data], { type: 'text/html' });

      // Create a temporary URL for the Blob object
      const url = window.URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement('a');
      link.href = url;

      // Set the file name
      link.setAttribute('download', 'report.html');

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger a click event on the link to initiate download
      link.click();

      // Remove the link from the document body
      document.body.removeChild(link);

      // Revoke the temporary URL to free up memory
      window.URL.revokeObjectURL(url);
  
    } catch (error) {

      console.log(error)

    } finally {
      
      setloadingdownloadreport(true)

    }

  };

  return (
    <div>
      <Header />
      {loading ? (
          <>
              <Container className="mt--15" fluid>
                  <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                          <Spinner animation="border" role="status">
                              <span className="sr-only">{t('Loading...')}</span>
                          </Spinner>
                      </Col>
                  </Row>
              </Container>
          </>
        ):(
          <>
            <Container className="mt--15" fluid>
              <Row>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} className="text-center mt-5">
                  <Card>
                    <CardHeader>
                      <h3>{t('Set the Farmer')}</h3>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Form.Label>{t('Set the Farmer')}</Form.Label>
                        <Form.Control 
                            as="select" 
                            onChange={(e) =>setfarmerselected(e.target.value)}
                            value={farmerselected}>
                              <option value={""}>
                                  
                              </option>
                            {farmeroption.map((farmer, index) => (
                              <option key={index} value={farmer.NameFarmer}>
                                  {farmer.NameFarmer}
                              </option>
                            ))}
                        </Form.Control>
                      </Form>
                    </CardBody>
                    <CardFooter>
                    {loadingsubmitting ? (
                      <>
                          <Button color='success' onClick={submit}>
                              {t('Run The Analysis')}
                          </Button>
                      </>
                    ):(
                        <>
                            <Button color="success" disabled>
                                <Spinner size="sm">
                                    Loading...
                                </Spinner>
                                <span>
                                {' '}{t('Loading')}
                                </span>
                            </Button>
                        </>
                    )}
                    <br></br>
                    {error && <Alert color="danger" fade={false}>{message}</Alert>}
                    </CardFooter>
                  </Card>
                </Col>
                {loadingpercentage ? (
                  <>
                    {percentage && percentage.length>0 ? (
                      <>
                        <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9} className="text-center mt-5">
                          <Card>
                            <CardHeader>
                              <h3>{t('Summary')}</h3>
                            </CardHeader>
                            <CardBody>
                              <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col">{t('Field Name')}</th>
                                    <th scope="col">{t('Crop')}</th>     
                                    <th scope="col">{t('Anomaly')}</th>                                                         
                                    <th scope="col">{t('Declared')}</th>
                                    <th scope="col">{t('Class')}</th>
                                    <th scope="col">{t('Percentage')}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {paginatedData.map((farmer, index) => (
                                    <tr key={index}>
                                      <td>{t(farmer.FieldName)}</td>
                                      <td>{t(farmer.Crop)}</td>
                                      <td>{t(farmer.Anomaly)}</td>                                    
                                      <td>{t(farmer.Declared)}</td>
                                      <td>{t(farmer.pred_class)}</td>
                                      <td>{t(farmer.percentage)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                              <Pagination aria-label="Page navigation example">
                                <PaginationItem disabled={currentPage <= 0}>
                                  <PaginationLink previous onClick={() => setPage(currentPage - 1)} />
                                </PaginationItem>
                                {[...Array(totalPages)].map((_, i) => (
                                  <PaginationItem key={i} active={i === currentPage}>
                                    <PaginationLink onClick={() => setPage(i)}>{i + 1}</PaginationLink>
                                  </PaginationItem>
                                ))}
                                <PaginationItem disabled={currentPage >= totalPages - 1}>
                                  <PaginationLink next onClick={() => setPage(currentPage + 1)} />
                                </PaginationItem>
                              </Pagination>
                            </CardBody>
                            <CardFooter>
                              {loadingdownload ? (
                                  <>
                                      <Button color='primary' onClick={download}>{t('Download Excel')} <i className='fa fa-download'></i></Button>
                                  </>
                                ):(
                                  <>
                                    <Button color="primary" disabled>
                                        <Spinner size="sm">
                                            Loading...
                                        </Spinner>
                                        <span>
                                        {' '}Loading
                                        </span>
                                    </Button>
                                  </>
                              )}
                              {loadingdownloadreport ? (
                                  <>
                                    <Button color='primary' onClick={downloadReport}>{t('Download Report')} <i className="fa-solid fa-file-pdf"></i></Button>
                                  </>
                                ):(
                                  <>
                                    <Button color="primary" disabled>
                                        <Spinner size="sm">
                                            Loading...
                                        </Spinner>
                                        <span>
                                        {' '}Loading
                                        </span>
                                    </Button>
                                  </>
                              )}
                              

                              {/* {maphtml ? (
                                <>
                                  <PDFDownloadLink className="btn btn-primary" document={<PDFreport maphtml={maphtml}/>} fileName="Report.pdf">
                                    {({ blob, url, loadingpdf, error }) => (
                                      <span>
                                        {loadingpdf ? 'Loading document...' : 'Download Report'}
                                        {' '}
                                        <i className="fa-solid fa-file-pdf"></i>
                                      </span>
                                    )}
                                  </PDFDownloadLink>
                                </>
                              ):null} */}
                            </CardFooter>
                          </Card>
                          <br></br>
                        </Col>
                      </>
                    ):null} 
                  </>
                ):(
                  <>
                    <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9} className="text-center mt-5">
                      <Spinner>
                      {t('Loading')}
                      </Spinner>
                    </Col>
                  </>
                )}  
                {loadingmaphtml ? (
                  <>
                    {maphtml ? (
                      <>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                          <Card>
                            <CardHeader>
                              <h3>{t('Probabilistic Map')}</h3>
                            </CardHeader>
                            <CardBody>
                              <iframe id="iframe2ciaociaociaociao" width="100%" height="400" srcDoc={maphtml}></iframe> 
                            </CardBody>
                          </Card>
                        </Col>                                                    
                      </>
                    ):null} 
                  </>
                ):(
                  <>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                      <Spinner>
                      {t('Loading')}
                      </Spinner>
                    </Col>
                  </>
                )}               
              </Row>
            </Container>
          </>
      )}
    </div>
  )
}

export default LargeScale
