import React, { useState, useEffect  } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Col, Row, Container, Spinner, Alert, Button, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';

import { Form } from 'react-bootstrap';

import axios from 'axios';

import { useSelector } from 'react-redux'

import Header from 'components/Headers/Header'

import { useTranslation } from 'react-i18next';

import { MapContainer, TileLayer, Marker, LayersControl, Popup } from 'react-leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet'; // Import leaflet library

function UpdateAllert() {

  const [startfecthing, setstartfecthing] = useState(true);
  const [loadingsubmitting, setloadingsubmitting] = useState(true);
  const { fieldId } = useParams();
  const [iotposition, setiotposition] = useState([]);

  const [variabile, setVariabile] = useState('');
  const [maggioreminore, setMaggioreMinore] = useState('');
  const [valore, setValore] = useState('');

  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const token = useSelector((state) => state.userLogin.userInfo.token);
  const userId = useSelector((state) => state.userLogin.userInfo.id);

  const [farmerData, setFarmerData] = useState({
    Cabina: '',
    Maggiore_Minore: '',
    Valore: '',
    Variabile: '',
  });

  const customMarkerIcon = new L.Icon({
    iconUrl: markerIcon,
    shadowUrl: markerIconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  ////////////
  const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);  // Use i18n to change language
      }
  }, [i18n]);
  ////////////

  useEffect(() => {
    const fetchData = async () => {
        try {
          const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
          }  

          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/allert/${fieldId}/`, config);
          
          setFarmerData({
            Cabina: response.data.Cabina,
            Maggiore_Minore: response.data.Maggiore_Minore,
            Valore: response.data.Valore,
            Variabile: response.data.Variabile
          });

          setVariabile(response.data.Variabile);
          setMaggioreMinore(response.data.Maggiore_Minore);
          setValore(response.data.Valore);

        } catch (error) {
            console.error('Error fetching field data:', error);
        }
    };

    fetchData();
  }, [fieldId, token]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }

        setiotposition('')
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-position?cabina=${farmerData.Cabina}`, config);
        
        setiotposition(response.data)

      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
      } finally {
        setstartfecthing(false)
      }
      
    };

    fetchData();
  }, [token, farmerData]);

  const handleRestart = () => {
    window.location.reload();
  };

  const onsubmit = async () => {
    
    setloadingsubmitting(false)

    if (valore==='') {
      setMessage(t('Please set the threshold value'))
      setError(true)
      setTimeout(function() {
          setError(false)
      }, 5000);
      setloadingsubmitting(true);
      return;
    }

    try {

      const payload = {
        Cabina: farmerData.Cabina,
        Maggiore_Minore: maggioreminore,
        User: userId,
        Valore: valore,
        Variabile: variabile,  
      };

      const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
      }  

      axios.put(`${process.env.REACT_APP_BACKEND_API}/iot/update-allert/${fieldId}/`, payload, config)
    
    } catch {

      console.error('Error during API request:', error);
      setMessage(t('Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com'))
      setError(true)
      setTimeout(function() {
          setError(false)
      }, 5000);

    } finally {

      setloadingsubmitting(true)
      navigate('/allert/');

    }

  }

  return (
    <div>
      <Header/>
      {startfecthing ? (
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>
                  {t('Loading')}
                </Spinner>
              </Col>
            </Row>
          </Container>
        </>
      ):(
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Card>
                  <CardHeader>
                    <h3>{t('Update Allert')}</h3>
                  </CardHeader>
                  <CardBody>
                    <Form>
                    {iotposition.length > 0 ? (
                        <>
                          <h3>{t('IoT Position')}</h3>
                          <MapContainer center={[iotposition[0].lat, iotposition[0].longi]} zoom={15} style={{ height: '200px', width: '100%' }}>
                            <LayersControl position="topright">
                                <LayersControl.BaseLayer checked name="OpenStreetMap">
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'/>
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer checked name="Satellite">
                                <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"/>        
                                </LayersControl.BaseLayer>
                            </LayersControl>
                            <Marker position={[iotposition[0].lat, iotposition[0].longi]} icon={customMarkerIcon}>
                                <Popup>
                                    <h3>{t('IoT Number')} {0 + 1}</h3>
                                </Popup>
                            </Marker>
                          </MapContainer>
                          <Form.Label>{t('Select the variable')}</Form.Label>
                          <Form.Control 
                            as="select" 
                            onChange={(e) => setVariabile(e.target.value)}
                            value={variabile}>
                              <option value={'Temperatura'}>
                                  {t('Soil Temperature')} (°C)
                              </option>
                              <option value={'Umidità'}>
                                  {t('Soil Humidity')} (%)                                      
                              </option>
                              <option value={'Conducibilità_Elettrica'}>
                                  {t('Electrical Conductivity')} (S/m)                      
                              </option>
                              <option value={'Carbonio_Organico'}>
                                  {t('Organic Carbon')} (mg/kg)                                    
                              </option>
                              <option value={'Azoto'}>
                                  {t('Nitrogen')} (mg/kg)                                        
                              </option>
                              <option value={'Fosforo'}>
                                  {t('Phosporous')} (mg/kg)                                      
                              </option>
                              <option value={'Potassio'}>
                                  {t('Potassium')} (mg/kg)                                   
                              </option>
                              <option value={'Batteria'}>
                                  {t('Battery')} (A)                                      
                              </option>
                          </Form.Control>
                          <br></br>
                          <Form.Label>{t('Select the logic')}</Form.Label>
                          <Form.Control 
                            as="select" 
                            onChange={(e) => setMaggioreMinore(e.target.value)}
                            value={maggioreminore}>
                              <option value={'Maggiore'}>
                                  {t('Higher than')} (°C)
                              </option>
                              <option value={'Minore'}>
                                  {t('Lower than')} (%)                                      
                              </option>
                          </Form.Control>
                          <br></br>
                          <Form.Label>{t('Set the threshold value')}</Form.Label>
                          <Form.Control 
                              type="number"
                              onChange={(e) => setValore(e.target.value)}
                              value={valore}>
                          </Form.Control>
                        </>
                      ) : (
                        <>
                        </>
                      )}
                    </Form>
                    {error && <Alert color="danger" fade={false}>{message}</Alert>}
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="d-flex justify-content-center">
                        {loadingsubmitting ? (
                          <>
                            <Button color="success" onClick={onsubmit}>
                              {t('Update Allert')} <i className="fas fa-edit"></i>
                            </Button>
                          </>
                        ):(
                          <>
                            <Button color="success" disabled>
                              <Spinner size="sm">
                              {t('Updating the allert...')}
                              </Spinner>
                              <span>
                                {' '}{t('Updating the allert...')}
                              </span>
                            </Button>
                          </>
                        )}
                        <br></br>
                      </Col>
                      <br></br>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="d-flex justify-content-center">
                        <Link to="/allert/">
                          <Button color="dark">
                            {t('Go back')} <i className="fas fa-rotate-left"></i>
                          </Button>
                        </Link>  
                        <br></br>
                      </Col>
                      <br></br>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="d-flex justify-content-center">
                        <Button color="primary" onClick={handleRestart}>
                          {t('Restart Creation')} <i className="fas fa-rotate-left"></i>
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
      
    </div>
  )
}

export default UpdateAllert
