// StripeCheckout.js

import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

// const stripePromise = loadStripe('pk_test_51PGO4mRwdYx22wSzvOARL7Jz0f5RLqa4rvh5AHYV559XVqbpLrwCjQZkGUMbgn7pNVzfVmSrK0Z4gZRvc5SlEECv00NSEewGjm');
const stripePromise = loadStripe(`${process.env.STRIPE_PUBLIC_KEY}`);



const StripeCheckout = () => {
  return (
    //<Elements stripe={stripePromise} options="${process.env.STRIPE_SECRET_KEY}">
    <Elements stripe={stripePromise} options="sk_test_51PGO4mRwdYx22wSzqYxic8lQHsQ9pCgqn8zcSgS0HYKeEfktXaIOxg7PtFl0ycifW5djcEYL0sIv0Wb8Mn40KHYQ00jXGFiJta">
      <CheckoutForm />
    </Elements>
  );
};

export default StripeCheckout;