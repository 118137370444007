import React from 'react'

import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
  } from "reactstrap";

import {Link} from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

function EmailChangePasswordSent() {

  ////////////
  const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguageAFS');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);  // Use i18n to change language
    }
  }, [i18n]);

  ////////////

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <Row className="justify-content-center">
                <Col lg="12" md="12">
                  <h1 >AIBIOSAT</h1>
                  <img
                      alt="Automatic Farm Solution"
                      src={"https://i.ibb.co/NZ8qZsk/111111.png"}
                      width={"200px"}
                      height={"200px"}
                  />
                </Col>
              </Row>
            </div>
            <h4>{t('Check your email inbox and follow the instructions to change the password.')}</h4>
            <br></br>
            <Link to={"/"}>
              <Button color="dark" fullWidth>
                {t('Go back to Login')}                  
              </Button>
            </Link>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default EmailChangePasswordSent
