import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col, Alert, Spinner, FormText } from "reactstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import validator from 'validator';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isIOS } from 'react-device-detect';
import LanguageSelector from 'components/LanguageSelector.js';
import { login } from 'actions/userActions';

const Login = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState('');
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguageAFS');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const validateEmail = (value) => {
    if (validator.isEmail(value)) {
      setEmailError('');
    } else {
      setEmailError(t('Invalid email address'))
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(false);

    if (email === '') {
      setMessage(t('The email is empty'))
      setError(true)
      setTimeout(function () {
        setError(false)
      }, 5000);
      setLoading(true)
      return;
    }

    if (password === '') {
      setMessage(t('The password is empty'))
      setError(true)
      setTimeout(function () {
        setError(false)
      }, 5000);
      setLoading(true)
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/users/check-active/${email}/`);

      if (response.request.status === 200) {

      } else {
        setMessage(t("The user is not active!"))
        setError(true)
        setTimeout(function () {
          setError(false)
        }, 5000);
        setLoading(true)
        return;
      }
    } catch (error) {
      setMessage(t("The user is not active!"))
      setError(true)
      setTimeout(function () {
        setError(false)
      }, 5000);
      setLoading(true)
      return;
    }

    try {
      dispatch(login(email, password, navigate));
    } catch (error) {
      setMessage(t("The email is used"))
      setError(true)
      setTimeout(function () {
        setError(false)
      }, 5000);
      setLoading(true)
      return;
    }

  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <Row className="justify-content-center">
                <Col lg="12" md="12">
                  <h1 >AIBIOSAT</h1>
                  <img
                    alt="Automatic Farm Solution"
                    src={"https://i.ibb.co/NZ8qZsk/111111.png"}
                    width={"200px"}
                    height={"200px"}
                  />
                </Col>
              </Row>
            </div>
            {error && <Alert color="danger" fade={false}>{message}</Alert>}

            <Row>
              <Col className="text-center mt-5">
                <LanguageSelector />
              </Col>
            </Row>
            <br></br>

            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    controlid='email'
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      validateEmail(e.target.value);
                    }} />
                </InputGroup>
                <FormText color="danger">{emailError}</FormText>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    controlid='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                {loading ? (
                  <>
                    <Button className="my-4" color="success" type="button" onClick={handleSubmit}>
                      Login
                    </Button>
                  </>
                ) : (
                  <>
                    <Button color="success" disabled>
                      <Spinner size="sm">
                        Loading...
                      </Spinner>
                      <span>
                        {' '}Loading
                      </span>
                    </Button>
                  </>
                )}
              </div>
            </Form>
            <BrowserView>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-dark"
                    href="/send-email-change-password/"
                  >
                    <small>{t('Forgot password?')}</small>
                  </a>
                </Col>
                {/* Conditionally render the "Create new account" link based on device type */}
                {isIOS ? null : (
                  <Col className="text-right" xs="6">
                    <a
                      className="text-dark"
                      href="/register/"
                    >
                      <small>{t('Create new account')}</small>
                    </a>
                  </Col>
                )}
              </Row>
            </BrowserView>
            <MobileView>
              <Row>
                <Col xs="6">
                  <a
                    className="text-dark"
                    href="/send-email-change-password/"
                  >
                    <small>{t('Forgot password?')}</small>
                  </a>
                </Col>
                <Col className="text-right" xs="6">
                  {/* Show a message for iOS devices */}
                  {isIOS ? <p>To create a new account please use the browser</p> : (
                    // For Android devices, you can optionally show a different message
                    <a
                      className="text-dark"
                      href="/register/"
                    >
                      <small>{t('Create new account')}</small>
                    </a>
                  )}
                </Col>
              </Row>
            </MobileView>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;