import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'; 
import axios from 'axios'
import Header from 'components/Headers/Header';
import StripeCheckout from 'components/stripe/StripeCheckout'
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { useTranslation } from 'react-i18next';

// secret key
// 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

// Publishable key
// pk_live_51H8VZNKRnKWFuuNTL1xp79XtCMPBPRPZEX8RiAk79Lq31uXPUYC5d95I24uBJ5kYwzUJ7ypUgU4pAuumtJh5PBAH00amfLFAmD

function UpgradeYourProfile() {

    const [hectares, sethectares] = useState('');

    const token = useSelector((state) => state.userLogin.userInfo.token);

    const farmerpro = useSelector((state) => state.userLogin.userInfo.is_farmer_pro);

    const [isFarmerPro, setIsFarmerPro] = useState(farmerpro);

    ////////////

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    ////////////

    useEffect(() => {

        const fetchData = async () => {
            try {
    
                const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
                }
                
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/field-hectares/`, config);
                
                
                sethectares(response.data.area_hectares);
    
            } catch (error) {
            }
        };
    
          fetchData(); 
      }, [token]);

    return (
        <div>
        <Header/>
        {isFarmerPro ? (
            <>
                <Container className="mt--15" fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                            <Card>
                                <CardHeader>
                                    <h3>{t('Your Acccount is PRO')}</h3>
                                </CardHeader>
                                <CardBody>
                                    <h3>{t('You can access to all the service')}</h3>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        ):(
            <>  
                <Container className="mt--15" fluid>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                            <Card>
                                <CardHeader>
                                    <h3>{t('Your Acccount is not PRO')}</h3>
                                </CardHeader>
                                <CardBody style={{ textAlign: 'left' }}>
                                    <h3>{t('Purchase the PRO Service')}</h3>
                                    <br></br>
                                    <h3>{t('The total price is')} { (hectares * 8 * 1.04).toFixed(2) } €</h3>
                                    <br></br>
                                    <h5>{t('By purchasing this service you will access :')}</h5>
                                    <ul>
                                        <li>{t('Prescription maps that can be integrated with your display tractors')}</li>
                                        <li>{t('Visualization & Analysis of sensor data')}</li>
                                        <li>{t('Set allert')}</li>
                                        <li>{t('All the agronomic tools')}</li>
                                        <li>{t('AI Tools')}</li>
                                        <li>{t('Synchronization with Operation Center Jhon Deere')}</li>
                                    </ul>
                                    <br></br>                                    
                                </CardBody>
                                <CardFooter>
                                    <StripeCheckout />
                                </CardFooter>
                            </Card> 
                        </Col>
                    </Row>
                </Container>
            </>
        )}
        </div>
    )
}

export default UpgradeYourProfile
