import React, { useState, useEffect  } from 'react';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

// Header
import Header from 'components/Headers/Header';

// UI libraries
import { Form, Col, Row } from 'react-bootstrap';
import {Container, Alert, Button, Spinner, Card, CardBody} from 'reactstrap'

// Leaflet
import { MapContainer, TileLayer, LayersControl, GeoJSON, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';

// Axios API
import axios from 'axios';

function UpdateField() {

  // Navigation & ID
  const { fieldId } = useParams()
  const navigate = useNavigate()

  // Authentication
  const token = useSelector((state) => state.userLogin.userInfo.token);
  const userId = useSelector((state) => state.userLogin.userInfo.id);

  // Leaflet
  const [mapLayers, setMapLayers] = useState([])
  const [isDrawing, setIsDrawing] = useState(true)

  // Parameter GeoJSON
  const [fieldData, setFieldData] = useState({
    Field: '',
    Centroid: ''
  });
  
  // loading
  const [isLoading, setisLoading] = useState(true)
  const [loadingsubmit, setloadingsubmit] = useState(true)
  const [warning, setwarning] = useState(false)
  const [warningmessage, setwarningmessage] = useState('')
  
  // From
  const [fieldname, setfieldname] = useState("")
  const [orgvsconv, setorgvsconv] = useState('Organic')
  const [croprotation, setcroprotation] = useState('perennial')
  const [crop1, setcrop1] = useState('');
  const [crop2, setcrop2] = useState('');
  const [crop3, setcrop3] = useState(''); 

  // Farmer Name
  const [farmernameoptions, setfarmernameoptions] = useState([]) 
  const [farmerselected, setfarmerselected] = useState('') 
  const [pkfarmer, setpkfarmer] = useState([]);
  const [SelectedPkFarmer, setSelectedPkFarmer] = useState('')
  
  // Validation & Error Message
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // i18n
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  // Retrive Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/${fieldId}/`, config);
        
        setfieldname(response.data.properties.FieldName)
        
        setorgvsconv(response.data.properties.OrganicVSConventional)
        
        setcroprotation(response.data.properties.CropRotation)

        setFieldData({
          Field: response.data,
          Centroid: response.data.properties.centroid
        });

        setisLoading(false)

      } catch (error) {
        setMessage(t('Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com'))
        setError(true)
        setTimeout(function() {
            setError(false)
        }, 5000);
        setloadingsubmit(false);
        return;
      }
    };

    fetchData();
  }, [fieldId, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }   
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farm-selected/${fieldId}/`, config);
      
        setfarmerselected(response.data.farmer_name)
        setSelectedPkFarmer(response.data.properties.Farmer)

      } catch (error) {
        setMessage(t('Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com'))
        setError(true)
        setTimeout(function() {
            setError(false)
        }, 5000);
        setloadingsubmit(false);
        return;
      }
    };

    fetchData();
  }, [fieldId, token]);

  const _onCreate = (e) => {
    if (!isDrawing) {
      return;
    }
  
    const { layerType, layer } = e;
    if (layerType === 'polygon') {
      const { _leaflet_id } = layer;
  
      // Convert LatLng objects to [lon, lat] array
      const latlngs = layer.getLatLngs()[0].map(({ lat, lng }) => [lng, lat]);
  
      // Ensure closure of the ring
      latlngs.push(latlngs[0]);
  
      setMapLayers([{ id: _leaflet_id, latlngs }]);
      setIsDrawing(false);
    }
  };

  const _onEdited = (e) => {
    const {
      layers: { _layers },
    } = e;
  
    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      const latlngs = editing.latlngs[0].map(({ lat, lng }) => [lng, lat]);
  
      // Ensure closure of the ring
      latlngs.push(latlngs[0]);
  
      const geoJSON = {
        type: "Polygon",
        coordinates: [latlngs],
      };
  
      setMapLayers((layers) =>
        layers.map((l) =>
          l.id === _leaflet_id
            ? { ...l, geometry: geoJSON }
            : l
        )
      );
    });
  };
  
  const _onDeleted = (e) => {
    const {
      layers: { _layers },
    } = e;
  
    Object.values(_layers).map(({ _leaflet_id }) => {
      setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
    });
  
    setIsDrawing(true);
  };

  const handleRestart = () => {
    window.location.reload();
  };

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);
        
        const extractedNames = response.data.map(item => item.NameFarmer);
        setfarmernameoptions(extractedNames);
        
        const pkfarmers = response.data.map(item => item.id);
        setpkfarmer(pkfarmers)       
        

      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
        setMessage(t('Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com'))
        setError(true)
        setTimeout(function() {
            setError(false)
        }, 5000);
        setloadingsubmit(false);
        return;
      }
      
    };

    fetchData();
  }, [token]);
  
  const renderTextInputFields = () => {
    switch (croprotation) {
      case 'perennial':
        return (
            <>  
                <Form>
                    <Form.Label>{t('Crop Rotation:')}</Form.Label>
                    <br></br>
                    <Form.Label>{t('Actual Crop')}</Form.Label>
                    <br></br>
                    <Form.Select placeholder={t('Actual Crop')} type="select" value={crop1} onChange={(e) => setcrop1(e.target.value)}>
                        <option value=""></option>
                        <option value="Vineyards">{t('Vineyards')}</option>
                        <option value="Alfalfa">{t('Alfalfa')}</option>
                    </Form.Select>
                </Form>
            </>
        );
      case 'biennial':
        return (
          <>
            <Form.Label>{t('Crop Rotation:')}</Form.Label>
            <br></br>
            <Form.Label>{t('Actual Crop')}</Form.Label>
            <br></br>
            <Form.Select placeholder={t('Actual Crop')} type="select" value={crop1} onChange={(e) => setcrop1(e.target.value)}>
                <option value=""></option>
                <option value="Alfalfa">{t('Alfalfa')}</option>
                <option value="Barley">{t('Barley')}</option>
                <option value="Maize">{t('Maize')}</option>
                <option value="Sunflower">{t('Sunflower')}</option>
                <option value="Sorghum">{t('Sorghum')}</option>
                <option value="Durum_wheat">{t('Durum Wheat')}</option>                    
                <option value="Frumento_tenero">{t('Winter Wheat')}</option>      
                <option value="Soia">{t('Soy')}</option>             
            </Form.Select>
            <br></br>
            <Form.Label>{t('The next year Crop')}</Form.Label>
            <br></br>
            <Form.Select placeholder={t('The next year Crop')} type="select" value={crop2} onChange={(e) => setcrop2(e.target.value)}>
                <option value=""></option>
                <option value="Alfalfa">{t('Alfalfa')}</option>
                <option value="Barley">{t('Barley')}</option>
                <option value="Maize">{t('Maize')}</option>
                <option value="Sunflower">{t('Sunflower')}</option>
                <option value="Sorghum">{t('Sorghum')}</option>
                <option value="Durum_wheat">{t('Durum Wheat')}</option>
                <option value="Frumento_tenero">{t('Winter Wheat')}</option>
                <option value="Soia">{t('Soy')}</option>  
            </Form.Select>
          </>
        );
      case 'triennial':
        return (
          <>
            <Form.Label>{t('Crop Rotation:')}</Form.Label>
            <br></br>
            <Form.Label>{t('Actual Crop')}</Form.Label>
            <br></br>
            <Form.Select placeholder={t('Actual Crop')} type="select" value={crop1} onChange={(e) => setcrop1(e.target.value)}>
                <option value=""></option>
                <option value="Alfalfa">{t('Alfalfa')}</option>
                <option value="Barley">{t('Barley')}</option>
                <option value="Maize">{t('Maize')}</option>
                <option value="Sunflower">{t('Sunflower')}</option>
                <option value="Sorghum">{t('Sorghum')}</option>
                <option value="Durum_wheat">{t('Durum Wheat')}</option>
                <option value="Frumento_tenero">{t('Winter Wheat')}</option>
                <option value="Soia">{t('Soy')}</option>  
            </Form.Select>
            <br></br>
            <Form.Label>{t('The next year Crop')}</Form.Label>
            <br></br>
            <Form.Select placeholder={t('The next year Crop')} type="select" value={crop2} onChange={(e) => setcrop2(e.target.value)}>
                <option value=""></option>
                <option value="Alfalfa">{t('Alfalfa')}</option>
                <option value="Barley">{t('Barley')}</option>
                <option value="Maize">{t('Maize')}</option>
                <option value="Sunflower">{t('Sunflower')}</option>                    
                <option value="Sorghum">{t('Sorghum')}</option>
                <option value="Durum_wheat">{t('Durum Wheat')}</option>
                <option value="Frumento_tenero">{t('Winter Wheat')}</option>
                <option value="Soia">{t('Soy')}</option>  
            </Form.Select>
            <br></br>
            <Form.Label>{t("The crop in two years' time")}</Form.Label>
            <br></br>
            <Form.Select placeholder={t("The crop in two years' time")} type="select" value={crop3} onChange={(e) => setcrop3(e.target.value)}>
                <option value=""></option>
                <option value="Alfalfa">{t('Alfalfa')}</option>
                <option value="Barley">{t('Barley')}</option>
                <option value="Maize">{t('Maize')}</option>
                <option value="Sunflower">{t('Sunflower')}</option>                    
                <option value="Sorghum">{t('Sorghum')}</option>
                <option value="Durum_wheat">{t('Durum Wheat')}</option>
                <option value="Frumento_tenero">{t('Winter Wheat')}</option>
                <option value="Soia">{t('Soy')}</option>  
            </Form.Select>
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);
        
        const extractedNames = response.data.map(item => item.NameFarmer);
        setfarmernameoptions(extractedNames);
        
        const pkfarmers = response.data.map(item => item.id);
        setpkfarmer(pkfarmers)          
        
        // setLoading(false)

      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
        setMessage(t('Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com'))
        setError(true)
        setTimeout(function() {
            setError(false)
        }, 5000);
        setloadingsubmit(false);
        return;
      }
      
    };

    fetchData();
  }, [token]);

  const handleFarmerSelection = (selectedValue) => {
    const selectedIndex = farmernameoptions.indexOf(selectedValue);
    const selectedPk = pkfarmer[selectedIndex];
    setSelectedPkFarmer(selectedPk);
    setfarmerselected(selectedValue);
  };

  const onSubmit = async () => {
    
    setloadingsubmit(false);

    if (fieldname==='') {
      setMessage(t('The field name is empty'))
      setError(true)
      setTimeout(function() {
          setError(false)
      }, 5000);
          setloadingsubmit(true);
          return;
    }

    if (croprotation==="biennial") {

      if (crop1==='') {
        setMessage(t('The crop field is empty'))
        setError(true)
        setTimeout(function() {
            setError(false)
        }, 5000);
            setloadingsubmit(true);
            return;
      }

      if (crop2==='') {
        setMessage(t('The crop field is empty'))
        setError(true)
        setTimeout(function() {
            setError(false)
        }, 5000);
            setloadingsubmit(true);
            return;
      }

    } else if (croprotation==="triennial") {

      if (crop1==='') {
        setMessage(t('The crop field is empty'))
        setError(true)
        setTimeout(function() {
            setError(false)
        }, 5000);
            setloadingsubmit(true);
            return;
      }

      if (crop2==='') {
        setMessage(t('The crop field is empty'))
        setError(true)
        setTimeout(function() {
            setError(false)
        }, 5000);
            setloadingsubmit(true);
            return;
      }

      if (crop3==='') {
        setMessage(t('The crop field is empty'))
        setError(true)
        setTimeout(function() {
            setError(false)
        }, 5000);
            setloadingsubmit(true);
            return;
      }

    } else {

      if (crop1==='') {
        setMessage(t('The crop field is empty'))
        setError(true)
        setTimeout(function() {
            setError(false)
        }, 5000);
            setloadingsubmit(true);
            return;
      }

    }

    const formattedMapLayers = mapLayers.map(layer => layer.latlngs);

    if (formattedMapLayers.length===0) {

      try {

        const payload = {
          FieldName: fieldname,
          Farmer: SelectedPkFarmer,
          Field: {
              type: "Polygon",
              coordinates: fieldData.Field.geometry.coordinates,
          },
          User: userId,
          ActualCrop:  crop1,
          CropRotation: croprotation,
          OrganicVSConventional: orgvsconv,
          SecondCrop: crop2,
          ThirdCrop: crop3,
        };

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }  
  
        axios.put(`${process.env.REACT_APP_BACKEND_API}/field/update/${fieldId}/`, payload, config)

      } catch {

        console.error('Error during API request:', error);
        setwarningmessage(t('Are you sure to edit the polygon? if yes press again the Update Field button'))
        setwarning(true)
        setTimeout(function() {
          setwarning(false)
        }, 5000);
        setloadingsubmit(true);
        return;

      } finally {

        setloadingsubmit(true);
        navigate('/field/');

      }

    } else {

      try {

        const payload = {
          FieldName: fieldname,
          Farmer: SelectedPkFarmer,
          Field: {
              type: "Polygon",
              coordinates: formattedMapLayers,
          },
          User: userId,
          ActualCrop:  crop1,
          CropRotation: croprotation,
          OrganicVSConventional: orgvsconv,
          SecondCrop: crop2,
          ThirdCrop: crop3,
        };
  
        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        } 

        axios.put(`${process.env.REACT_APP_BACKEND_API}/field/update/${fieldId}/`, payload, config)
         
      } catch(error) {

        console.error('Error during API request:', error);
        setwarningmessage(t('Are you sure to edit the polygon? if yes press again the Update Field button'))
        setwarning(true)
        setTimeout(function() {
          setwarning(false)
        }, 5000);
        setloadingsubmit(true);
        return;

      } finally {

        setloadingsubmit(true);
        navigate('/field/');

      }

    }

  };

  return (
    <div>
      <Header/>
      {isLoading ? (
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>
                  {t('Loading')}
                </Spinner>
              </Col>
            </Row>
          </Container>
        </>
        ):(
          <>
            <Container className="mt--15" fluid>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                  <h3>{t('Edit Field')}</h3>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                  <Card>
                    <CardBody>
                      <Form>
                        <Form.Label>{t('Farmer Company Name:')}</Form.Label>
                        <Form.Control
                          as="select"
                          placeholder={t('Farmer Company Name:')}
                          value={farmerselected} 
                          onChange={(e) => handleFarmerSelection(e.target.value)}>
                          {farmernameoptions.map((option, index) => (
                              <option key={index} value={option}>
                                  {option}
                              </option>
                          ))}
                        </Form.Control>
                        <Form.Label>{t('Field name:')}</Form.Label>
                        <Form.Control 
                          type="text" 
                          placeholder={t('Field name:')} 
                          value={fieldname} 
                          onChange={(e) => setfieldname(e.target.value)}/>
                        <br></br>
                        <Form.Label>{t('Organic vs Convenctional')}</Form.Label>
                        <Form.Control
                          as="select"
                          placeholder={t('Organic vs Convenctional')}
                          value={orgvsconv}
                          onChange={(e) => setorgvsconv(e.target.value)}
                          >
                          <option value={"Organic"}>
                            {t('Organic')}
                          </option>
                          <option value={"Convenctional"}>
                             {t('Convenctional')}                                                    
                          </option>                                             
                        </Form.Control>
                        <Form.Label>{t('Crop Rotation:')}</Form.Label>
                        <Form.Control
                          as="select"
                          value={croprotation}
                          onChange={(e) => setcroprotation(e.target.value)}
                          >
                          <option value={"perennial"}>
                            {t('Perennial crop')}
                          </option>
                          <option value={"biennial"}>
                            {t('Two year crop rotation')}                                                    
                          </option>
                          <option value={"triennial"}>
                            {t('Three year crop rotation')}                                                    
                          </option>                                                
                        </Form.Control>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                  <Card>
                    <CardBody>
                      {renderTextInputFields()}
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                  <Card>
                    <CardBody>
                      <h3>{t('Draw again the edge of your field:')}</h3>
                      <p>{t('In red there is the old field')}</p>
                      {fieldData.Field && (
                        <MapContainer center={[fieldData.Centroid[1], fieldData.Centroid[0]]} zoom={14} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }}>
                          <LayersControl position="topright">
                            <LayersControl.BaseLayer checked name="OpenStreetMap">
                              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer checked name="Satellite">
                              <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                            </LayersControl.BaseLayer>
                          </LayersControl>
                          <FeatureGroup>
                            <GeoJSON      
                              data={{
                                type: 'Feature',
                                geometry: {
                                  type: 'Polygon',
                                  coordinates: fieldData.Field.geometry.coordinates,
                                },
                                properties: fieldData.Field.properties,
                              }}
                              style={{ fillColor: 'red', weight: 1, opacity: 1, color: 'red', fillOpacity: 0.15 }}
                            />
                          </FeatureGroup>
                          <FeatureGroup>
                            <EditControl
                              position="topleft"
                              draw={{
                                polygon: isDrawing,
                                rectangle: false,
                                polyline: false,
                                circle: false,
                                marker: false,
                                circlemarker: false,
                                remove: false,
                              }}
                              onCreated={_onCreate}
                              onEdited={_onEdited}
                              onDeleted={_onDeleted}
                            />
                          </FeatureGroup>
                        </MapContainer>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {error && 
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                      <Alert color="danger" fade={false}>{message}</Alert>
                  </Col>                    
              }
              {warning && 
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                    <Alert color="warning" fade={false}>{warningmessage}</Alert>
                </Col>                    
              }
              <Row>
                <Col className="d-flex justify-content-center">
                  {loadingsubmit ? (
                      <>
                       <Button color="success" onClick={onSubmit}>
                       {t('Update Field')} <i className="fas fa-rotate-left"></i>
                        </Button>
                      </>
                    ) : (
                      <>                        
                        <Button color="success" disabled>
                          <Spinner size="sm">
                          {t('Updating Field')}
                          </Spinner>
                          <span>
                            {' '}{t('Updating Field')}
                          </span>
                        </Button>
                      </>
                    )}
                  
                </Col>
                <Col className="d-flex justify-content-center">
                  <Link to="/field/">
                    <Button color="dark">
                    {t('Go back')} <i className="fas fa-rotate-left"></i>
                    </Button>
                  </Link>
                </Col>
                <Col className="d-flex justify-content-center">
                  <Button color="primary" onClick={handleRestart}>
                    {t('Restart Creation')} <i className="fas fa-rotate-left"></i>
                  </Button>
                </Col>
              </Row>
            </Container>
          </>
        )}
    </div>
  )
}

export default UpdateField
