import { useState, useEffect } from "react";

import { Button, Card, CardHeader, CardBody, Table, Container, Row, Col, Spinner,  Pagination, PaginationItem, PaginationLink } from "reactstrap";

import { Form } from 'react-bootstrap'

import { MapContainer, TileLayer, LayersControl, GeoJSON, FeatureGroup } from 'react-leaflet';

import {Link} from 'react-router-dom'

import axios from 'axios'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from "components/Headers/Header.js";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");

  const [FarmerData, setFarmerData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [FieldData, setFieldData] = useState([]);
  const [loadingfield, setloadingfield] = useState(true);

  const [selectedOption, setSelectedOption] = useState('');
  const [options, setOptions] = useState([]);

  const [fieldData, setFieldDataa] = useState([]);

  const token = useSelector((state) => state.userLogin.userInfo.token);

  ////////////
  const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);  // Use i18n to change language
      }
  }, [i18n]);
  ////////////

  /// Pagination Aziende agricole registrate

  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 5; // Number of items per page

  const totalPages = Math.ceil(FarmerData.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, FarmerData.length - 1);
  const paginatedData = FarmerData.slice(startIndex, endIndex + 1);

  const setPage = (page) => {
    setCurrentPage(page);
  };

  /// pagination Campi Registrati

  const [currentPageField, setCurrentPageField] = useState(0);

  // Calcola i dati paginati basati sullo stato della paginazione
  const pageSizeField = 5; // Numero di elementi per pagina
  const totalPagesField = Math.ceil(FieldData.length / pageSizeField);
  const startIndexField = currentPageField * pageSizeField;
  const endIndexField = Math.min(startIndexField + pageSizeField - 1, FieldData.length - 1);
  const paginatedFieldData = FieldData.slice(startIndexField, endIndexField + 1);

  // Aggiungi una funzione per impostare la pagina corrente dei dati dei campi
  const setPageField = (page) => {
    setCurrentPageField(page);
  };

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/`, config);
        setFarmerData(response.data);
      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/fieldfeatures/`, config);
        setFieldData(response.data);
      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
      } finally {
        setloadingfield(false);
      }
    };
  
    fetchData();
  }, [token]);

  useEffect(() => {
    // Funzione per effettuare la richiesta GET e aggiornare lo stato delle opzioni
    const fetchData = async () => {
        try {

            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
            
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-by-farmer-info/`, config);

            setOptions(response.data); // Assicurati che la risposta contenga l'array di opzioni

        } catch (error) {
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            // setError(false)
            // }, 5000);
            // return;
        }
      };

      fetchData(); 
  }, [token]);

  const functionchange = async (e) => {

    const selectedValue = e.target.value;
    setSelectedOption(e.target.value)

    try {
      const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
      }
  
      const responsefield = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-by-farmer-geojson/${selectedValue}`, config);
      
      setFieldDataa(responsefield.data.features);
      
    } catch(error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {

        console.log(options[0])

        try {

            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
            
            const responsefield = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-by-farmer-geojson/${options[0]}`, config);
            
            setFieldDataa(responsefield.data.features);
            console.log(fieldData)
          } catch(error) {
            console.log(error)
          }
      };

      fetchData(); 
  }, [token, options]);
  
  const getColor = (actualCrop) => {
    switch (actualCrop) {
        case 'Vineyards':
            return 'green';
        case 'Barley':
            return 'yellow';
        case 'Sunflower':
            return 'red';
        case 'Alfalfa':
          return 'dark'; 
        case 'Durum_wheat':
          return 'violet';  
        case 'Sorghum':
          return 'lightgreen';    
        case 'Frumento_tenero':
          return 'orange';
        case 'Soia':
          return 'pink';  
        default:
            return 'blue';
    }
  };
  
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="6">
            <Card className="shadow">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
                  <Spinner>
                    Loading
                  </Spinner>
                </div>
              ) : (
                <>
                  {FarmerData && FarmerData.length > 0 ? (
                    <>
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <div className="col">
                          <h3 className="mb-0">{t('Farmer Registred')}</h3>
                          </div>
                          <div className="col text-right">
                            <Link to="/farm/">
                              <Button color="primary" size="sm">
                                {t('Add / Modify / Delete')}
                              </Button>
                            </Link>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">{t('Farmer')}</th>
                              <th scope="col">{t('Email')}</th>
                              <th scope="col">{t('Phone Number')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {paginatedData.map((farmer, index) => (
                              <tr key={index}>
                                <td>{farmer.NameFarmer}</td>
                                <td>{farmer.Email}</td>
                                <td>{farmer.PhoneNumber}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Pagination aria-label="Page navigation example">
                          <PaginationItem disabled={currentPage <= 0}>
                            <PaginationLink previous onClick={() => setPage(currentPage - 1)} />
                          </PaginationItem>
                          {[...Array(totalPages)].map((_, i) => (
                            <PaginationItem key={i} active={i === currentPage}>
                              <PaginationLink onClick={() => setPage(i)}>{i + 1}</PaginationLink>
                            </PaginationItem>
                          ))}
                          <PaginationItem disabled={currentPage >= totalPages - 1}>
                            <PaginationLink next onClick={() => setPage(currentPage + 1)} />
                          </PaginationItem>
                        </Pagination>
                      </CardBody>
                    </>
                  ) : (
                    <>
                      <CardHeader className="border-0">
                        <h3 className="mb-0">{t('No registered farm')}</h3>
                      </CardHeader>
                      <CardBody>
                        <Link to="/create-farm/">
                          <Button color="success">
                            {t('Create a farm')}
                          </Button>
                        </Link>
                      </CardBody>
                    </>
                  )}
                </>
              )}
            </Card>
          </Col>
          <Col className="mb-5 mb-xl-0" xl="6">
          <Card className="shadow">
              {loadingfield ? (
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
                  <Spinner>
                    Loading
                  </Spinner>
                </div>
              ) : (
                <>
                  {FieldData && FieldData.length > 0 ? (
                    <>
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <div className="col">
                          <h3 className="mb-0">{t('Field Registred')}</h3>
                          </div>
                          <div className="col text-right">
                            <Link to="/field/">
                              <Button color="primary" size="sm">
                                {t('Add / Modify / Delete')}
                              </Button>
                            </Link>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">{t('Field Name')}</th>
                              <th scope="col">{t('Farmer')}</th>                            
                              <th scope="col">{t('Actual Crop')}</th>
                              <th scope="col">{t('Crop Rotation')}</th>
                              <th scope="col">{t('Organic vs Convenctional')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {paginatedFieldData.map((farmer, index) => (
                              <tr key={index}>
                                <td>{farmer.FieldName}</td>
                                <td>{farmer.Farmer}</td>
                                <td>{farmer.ActualCrop}</td>
                                <td>{farmer.CropRotation}</td>
                                <td>{farmer.OrganicVSConventional}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Pagination aria-label="Page navigation example">
                          <PaginationItem disabled={currentPageField <= 0}>
                            <PaginationLink previous onClick={() => setPageField(currentPageField - 1)} />
                          </PaginationItem>
                          {[...Array(totalPagesField)].map((_, i) => (
                            <PaginationItem key={i} active={i === currentPageField}>
                              <PaginationLink onClick={() => setPageField(i)}>{i + 1}</PaginationLink>
                            </PaginationItem>
                          ))}
                          <PaginationItem disabled={currentPageField >= totalPagesField - 1}>
                            <PaginationLink next onClick={() => setPageField(currentPageField + 1)} />
                          </PaginationItem>
                        </Pagination>
                      </CardBody>
                    </>
                  ) : (
                    <>
                      <CardHeader className="border-0">
                        <h3 className="mb-0">{t('No registered farm')}</h3>
                      </CardHeader>
                      <CardBody>
                        <Link to="/create-field/">
                          <Button color="success">
                            {t('Create a field')}
                          </Button>
                        </Link>
                      </CardBody>
                    </>
                  )}
                </>
              )}
            </Card>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">{t('Farmer')}</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Control 
                        as="select" 
                        onChange={functionchange}
                        value={selectedOption}>
                        {options.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </Form>
                {fieldData.length > 0 && (
                  <>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <MapContainer center={[fieldData[0].geometry.coordinates[0][0][1], fieldData[0].geometry.coordinates[0][0][0]]} zoom={14} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }} key={JSON.stringify(fieldData)}>
                          <LayersControl position="topright">
                            <LayersControl.BaseLayer checked name="OpenStreetMap">
                              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer checked name="Satellite">
                              <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                            </LayersControl.BaseLayer>
                          </LayersControl>
                          {fieldData.map((field, index) => (
                            <FeatureGroup key={index}>
                              <GeoJSON
                                data={{
                                  type: 'Feature',
                                  geometry: {
                                    type: 'Polygon',
                                    coordinates: field.geometry.coordinates,
                                  },
                                  properties: field.properties,
                                }}
                                style={(feature) => ({
                                  fillColor: getColor(feature.properties.ActualCrop), // Function to get color based on ActualCrop
                                  weight: 3,
                                  opacity: 1,
                                  color: 'blue',
                                  fillOpacity: 0.5
                                })}
                                onEachFeature={(feature, layer) => {
                                  layer.bindPopup(
                                      `<PopupContent> 
                                        <p>${field.properties.FieldName}</p>                             
                                      </PopupContent>`
                                  );
                                }}
                              />
                            </FeatureGroup>
                          ))}
                        </MapContainer>
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>     
      </Container>
    </>
  );
};

export default Index;
