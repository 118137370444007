import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import * as shapefile from 'shapefile';
import proj4 from 'proj4';
import Header from 'components/Headers/Header';
import { Container, Col, Row, Spinner, Table, Card, CardHeader, CardBody, CardFooter, Button, Input, Modal, ModalHeader, ModalBody} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import centroid from '@turf/centroid';
import FieldMap from './FieldMap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function UploadField() {

    const [loading, setLoading] = useState(true);
    const [loadingsubmit, setLoadingsubmit] = useState(true);

    const [modalopen, setModalopen] = useState(false);

    const [geoJsonData, setGeoJsonData] = useState(null);
    const [mapCenter, setMapCenter] = useState([0, 0]);
    const [tableData, setTableData] = useState([]);

    const [projectoptions, setProjectoptions] = useState([]);

    const navigate = useNavigate();

    const token = useSelector((state) => state.userLogin.userInfo.token);
    const userId = useSelector((state) => state.userLogin.userInfo.id);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                };

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/`, config);
                
                console.log(response)

                const extractedNames = response.data.map(item => item.NameFarmer);
                setProjectoptions(extractedNames);

            } catch (error) {
                console.error('Errore durante la richiesta GET:', error);
            }

        };

        fetchData();
    }, [token]);

    const handleFileUpload = async (event) => {
        setLoading(false);

        const file = event.target.files[0];
        const arrayBuffer = await file.arrayBuffer();

        const zip = await JSZip.loadAsync(arrayBuffer);
        const shpFile = Object.keys(zip.files).find(fileName => fileName.endsWith('.shp'));
        const dbfFile = Object.keys(zip.files).find(fileName => fileName.endsWith('.dbf'));
        const prjFile = Object.keys(zip.files).find(fileName => fileName.endsWith('.prj'));

        let fromCRS = null;
        if (prjFile) {
            const prjContent = await zip.files[prjFile].async('string');
            fromCRS = proj4(prjContent).oProj;
            
            if (fromCRS.name==="GCS_WGS_1984") {

            } else {
                setModalopen(true)
                setLoading(true);
                return;
            }

        }       
        

        if (shpFile && dbfFile) {
            const shpArrayBuffer = await zip.files[shpFile].async('arraybuffer');
            const dbfArrayBuffer = await zip.files[dbfFile].async('arraybuffer');

            shapefile.open(shpArrayBuffer, dbfArrayBuffer)
                .then(source => {
                    const features = [];
                    return source.read().then(function log(result) {
                        if (result.done) {
                            let geojson = { type: "FeatureCollection", features };
                            if (fromCRS) {
                                geojson = transformGeoJSON(geojson, fromCRS, proj4.defs('EPSG:4326'));
                            }
                            setGeoJsonData(geojson);
                            calculateMapCenter(geojson);

                            const newData = geojson.features.map((feature, index) => ({
                                id: index + 1,
                                project: '',
                                fieldname: '',
                                orgvsconv: 'Organic',
                                croprotation: 'perennial',
                                crop1: '',
                                crop2: '',
                                crop3: ''
                            }));
                            setTableData(newData);
                            setLoading(true);
                            return;
                        }
                        features.push(result.value);
                        return source.read().then(log);
                    });
                })
                .catch(error => {
                    console.error('Error reading shapefile:', error);
                    setLoading(true);
                });
        } else {
            alert('Il file zip non contiene file .shp e .dbf validi.');
            setLoading(true);
        }
    };

    const transformGeoJSON = (geojson, fromCRS, toCRS) => {
        const transformedFeatures = geojson.features.map(feature => {
            const transformedGeometry = transformGeometry(feature.geometry, fromCRS, toCRS);
            return {
                ...feature,
                geometry: transformedGeometry
            };
        });

        return {
            ...geojson,
            features: transformedFeatures
        };
    };

    const transformGeometry = (geometry, fromCRS, toCRS) => {
        if (geometry.type === 'Polygon' || geometry.type === 'MultiLineString') {
            const coordinates = geometry.coordinates.map(ring => ring.map(coord => proj4(fromCRS, toCRS, coord)));
            return { ...geometry, coordinates };
        } else if (geometry.type === 'MultiPolygon') {
            const coordinates = geometry.coordinates.map(polygon => polygon.map(ring => ring.map(coord => proj4(fromCRS, toCRS, coord))));
            return { ...geometry, coordinates };
        } else {
            console.error('Unsupported geometry type:', geometry.type);
            return geometry;
        }
    };

    const calculateMapCenter = (geojson) => {
        const centroids = geojson.features.map(feature => centroid(feature).geometry.coordinates);
        const avgLat = centroids.reduce((sum, coord) => sum + coord[1], 0) / centroids.length;
        const avgLng = centroids.reduce((sum, coord) => sum + coord[0], 0) / centroids.length;
        setMapCenter([avgLat, avgLng]);
    };

    const handleTableChange = (event, index, field) => {
        const updatedData = [...tableData];
        updatedData[index][field] = event.target.value;
        setTableData(updatedData);
    };
    
    const getFarmid = async (farmname) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }   
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/farm-id/${farmname}`, config);
            return response.data;
        } catch (error) {
            console.error("Error fetching farmer ID:", error);
            throw error;
        }
    };

    const handleSubmit = async () => {

        setLoadingsubmit(false);

        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            };

            for (let i = 0; i < tableData.length; i++) {

                const singleGeoJson = geoJsonData.features[i];
                const singleTableData = tableData[i];
                
                const farmid = await getFarmid(singleTableData.project)  

                console.log(farmid)  

                const payload = {
                    Farmer: farmid,
                    FieldName: singleTableData.fieldname,                    
                    Field: {
                        type: "Polygon",
                        coordinates: singleGeoJson.geometry.coordinates,
                    },
                    User: userId,
                    ActualCrop:  singleTableData.crop1,
                    CropRotation: singleTableData.croprotation,
                    OrganicVSConventional: singleTableData.orgvsconv,
                    SecondCrop: singleTableData.crop2,
                    ThirdCrop: singleTableData.crop3,
                };

                try {

                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/field/create/`, payload, config)

                    if (response.status === 200) {

                    } else {
                        console.error('Errore durante il caricamento dell\'elemento:', i);
                    }

                } catch (error) {
                    console.error('Errore durante la richiesta POST per l\'elemento:', i, error);
                }
            }

        } catch (error) {
            console.error('Errore durante il processo di caricamento:', error);
        } finally {
            setLoadingsubmit(true);
            navigate('/field/');
        }
    };

    return (
        <div>
            <Header />
            {/* <input type="file" accept=".zip" onChange={handleFileUpload} /> */}
            <Container className="mt--15" fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="mt-5">
                        <Input
                            id="exampleFile"
                            name="file"
                            type="file"
                            accept=".zip"
                            onChange={handleFileUpload}
                        />
                        <br></br>
                        <h3>
                            {t('Upload Zip File where within there is the shapefile with all the fields')}
                        </h3>
                        <Modal isOpen={modalopen} toggle={() => setModalopen(false)}>
                            <ModalHeader toggle={() => setModalopen(false)}>
                                <h3>ERROR</h3>
                            </ModalHeader>
                            <ModalBody>
                                <p>{t('The shapefile uploaded is not using the Coordinate Reference System of WGS 84')}</p>
                                <p>{t('Please reproject the file content to WGS 84')}</p>
                            </ModalBody>
                        </Modal>
                    </Col>
                </Row>
                {loading ? (
                    <>

                    </>
                ) : (
                    <>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Spinner>
                                    {t('Loading')}
                                </Spinner>
                            </Col>
                        </Row>
                    </>
                    )}
                {geoJsonData && (
                    <>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Card>
                                    <CardHeader>
                                        {t('Create Field')}
                                    </CardHeader>
                                    <CardBody>
                                        <Table className="align-items-center table-flush" bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>{t('Field')}</th>
                                                    <th>{t('Farmer')}</th>
                                                    <th>{t('Field Name')}</th>
                                                    <th>{t('Organic vs Convenctional')}</th>
                                                    <th>{t('Crop Rotation')}</th>
                                                    <th>{t('Actual Crop')}</th>
                                                    <th>{t('The next year Crop')}</th>
                                                    <th>{t("The crop in two years' time")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.map((row, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <FieldMap feature={geoJsonData.features[index]} />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="select"
                                                                value={row.project}
                                                                onChange={e => handleTableChange(e, index, 'project')}
                                                            >
                                                                {projectoptions.map((project, idx) => (
                                                                    <option key={idx} value={project}>{project}</option>
                                                                ))}
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                value={row.fieldname}
                                                                onChange={e => handleTableChange(e, index, 'fieldname')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="select"
                                                                value={row.orgvsconv}
                                                                onChange={e => handleTableChange(e, index, 'orgvsconv')}
                                                            >
                                                                <option value={"Organic"}>
                                                                    {t('Organic')}
                                                                </option>
                                                                <option value={"Convenctional"}>
                                                                    {t('Convenctional')}                                                    
                                                                </option>  
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="select"
                                                                value={row.croprotation}
                                                                onChange={e => handleTableChange(e, index, 'croprotation')}>
                                                                <option value={"perennial"}>
                                                                    {t('Perennial crop')}
                                                                </option>
                                                                <option value={"biennial"}>
                                                                    {t('Two year crop rotation')}                                                    
                                                                </option>
                                                                <option value={"triennial"}>
                                                                    {t("Three year' crop rotation")}                                                    
                                                                </option>    
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="select"
                                                                value={row.crop1}
                                                                onChange={e => handleTableChange(e, index, 'crop1')}>
                                                                    <option value=""></option>
                                                                    <option value="Vineyards">{t('Vineyards')}</option>
                                                                    <option value="Alfalfa">{t('Alfalfa')}</option>
                                                                    <option value="Barley">{t('Barley')}</option>
                                                                    <option value="Maize">{t('Maize')}</option>
                                                                    <option value="Sunflower">{t('Sunflower')}</option>
                                                                    <option value="Sorghum">{t('Sorghum')}</option>
                                                                    <option value="Durum_wheat">{t('Durum Wheat')}</option>                    
                                                                    <option value="Frumento_tenero">{t('Winter Wheat')}</option>      
                                                                    <option value="Soia">{t('Soy')}</option>
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="select"
                                                                value={row.crop2}
                                                                onChange={e => handleTableChange(e, index, 'crop2')}>
                                                                    <option value=""></option>
                                                                    <option value="Vineyards">{t('Vineyards')}</option>
                                                                    <option value="Alfalfa">{t('Alfalfa')}</option>
                                                                    <option value="Barley">{t('Barley')}</option>
                                                                    <option value="Maize">{t('Maize')}</option>
                                                                    <option value="Sunflower">{t('Sunflower')}</option>
                                                                    <option value="Sorghum">{t('Sorghum')}</option>
                                                                    <option value="Durum_wheat">{t('Durum Wheat')}</option>                    
                                                                    <option value="Frumento_tenero">{t('Winter Wheat')}</option>      
                                                                    <option value="Soia">{t('Soy')}</option>
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="select"
                                                                value={row.crop3}
                                                                onChange={e => handleTableChange(e, index, 'crop3')}>
                                                                    <option value=""></option>
                                                                    <option value="Vineyards">{t('Vineyards')}</option>
                                                                    <option value="Alfalfa">{t('Alfalfa')}</option>
                                                                    <option value="Barley">{t('Barley')}</option>
                                                                    <option value="Maize">{t('Maize')}</option>
                                                                    <option value="Sunflower">{t('Sunflower')}</option>
                                                                    <option value="Sorghum">{t('Sorghum')}</option>
                                                                    <option value="Durum_wheat">{t('Durum Wheat')}</option>                    
                                                                    <option value="Frumento_tenero">{t('Winter Wheat')}</option>      
                                                                    <option value="Soia">{t('Soy')}</option>
                                                            </Input>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                    <CardFooter>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                                                {loadingsubmit ? (
                                                    <>
                                                        <Button color="success" onClick={handleSubmit}>
                                                            {t('Create Fields')} <i className="fas fa-plus"></i>
                                                        </Button>
                                                    </>
                                                ) : (
                                                        <>
                                                            <Button color="success" disabled>
                                                                <Spinner size="sm">
                                                                    {t('Creating fields...')}
                                                                </Spinner>
                                                                <span>
                                                                    {' '}{t('Creating fields...')}
                                                                </span>
                                                            </Button>
                                                        </>
                                                    )}
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                                                <Link to="/field">
                                                    <Button color="dark">
                                                        {t('Go back')} <i className="fas fa-rotate-left"></i>
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
        </div>
    );
}

export default UploadField;
