import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import Header from 'components/Headers/Header';
import { useSelector } from 'react-redux'; 
import axios from 'axios'
import {Spinner, Container, Row, Col, Button, Card, CardHeader, CardBody, Table} from 'reactstrap'
import {Form} from 'react-bootstrap'

import * as XLSX from 'xlsx';

function IoTTable() {
    const [iotposition, setiotposition] = useState([]);
    const [loadingiot, setloadingiot] = useState(true)

    const [loadingsubmit, setloadingsubmit] = useState(true)
    const [loadingdownload, setloadingdownload] = useState(true)

    

    const [selectediot, setselectediot] = useState('');
    const [optionsiot, setoptionsiot] = useState([]);

    const [data, setData] = useState([]);

    const token = useSelector((state) => state.userLogin.userInfo.token);

    useEffect(() => {
      
        const fetchData = async () => {
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
                
            const responseposition = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/positioniot/`, config);
            
            setiotposition(responseposition.data);
            setoptionsiot(responseposition.data);
            setloadingiot(false)

          } catch (error) {
            // console.error('Errore durante la richiesta GET:', error);
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //   setError(false)
            // }, 5000);
            // return;
          }
        };
    
        fetchData();
      }, [token]);



    ////////////
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);
    ////////////
    
    const submit = async () => {

        setloadingsubmit(false);

        try {            

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }

            if (selectediot==='') {

                const response= await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-table?cabina=${optionsiot[0].Cabina}`, config)

                const responseData = JSON.parse(response.data);

                setData(responseData); 
            } else {

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-table?cabina=${selectediot}`, config);
                const responseData = JSON.parse(response.data);
                
                setData(responseData); 
            }           
          
        } catch (error) {  
            console.log(error)
            // console.error('Errore durante la richiesta GET:', error);
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //   setError(false)
            // }, 5000);
            // return;
        } finally {
            setloadingsubmit(true);
        }
 
    };

    const download = async () => {

        setloadingdownload(false);

        try {            

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }

            if (selectediot==='') {

                const response= await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-table?cabina=${optionsiot[0].Cabina}`, config)

                const responseData = JSON.parse(response.data);

                const ws = XLSX.utils.json_to_sheet(responseData);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        
                // Save the file
                XLSX.writeFile(wb, 'data.xlsx');
            } else {

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-table?cabina=${selectediot}`, config);
                const responseData = JSON.parse(response.data);

                const ws = XLSX.utils.json_to_sheet(responseData);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        
                // Save the file
                XLSX.writeFile(wb, 'data.xlsx');
            }           
          
        } catch (error) {  
            console.log(error)
            // console.error('Errore durante la richiesta GET:', error);
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //   setError(false)
            // }, 5000);
            // return;
        } finally {
            setloadingdownload(true);
        }
 
    };

    
    return (
        <div>
            <Header/>
            {loadingiot ? (
                <Container className="mt--15" fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                            <Spinner>{t('Loading')}</Spinner>
                        </Col>
                    </Row>
                </Container>
                ) : (
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={2} lg={2} xl={2} xxl={2} className="text-center mt-5">
                                <Card>
                                    <CardHeader>
                                        <h3>{t('Analysis')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <br></br>
                                            <Form.Label>{t('Select the IoT Cabin')}</Form.Label>
                                            <Form.Control 
                                                as="select" 
                                                onChange={(e) => setselectediot(e.target.value)}
                                                value={selectediot}>
                                                    {optionsiot.map((option, index) => (
                                                        <option key={index} value={option.Cabina}>
                                                            {option.Cabina}
                                                        </option>
                                                    ))}
                                            </Form.Control>
                                            <br></br>
                                            {loadingsubmit ? (
                                                <>
                                                    <Button color='success' onClick={submit}>{t('View data')} <i className='fa fa-eye'></i></Button>
                                                </>
                                            ):(
                                                <>
                                                    <Button color="success" disabled>
                                                        <Spinner size="sm">
                                                            Loading...
                                                        </Spinner>
                                                        <span>
                                                        {' '}Loading
                                                        </span>
                                                    </Button>

                                                </>
                                            )}
                                            <br></br>
                                            <br></br>
                                            {loadingdownload ? (
                                                <>
                                                    <Button color='primary' onClick={download}>{t('Download data')} <i className='fa fa-download'></i></Button>
                                                </>
                                            ):(
                                                <>
                                                    <Button color="primary" disabled>
                                                        <Spinner size="sm">
                                                            Loading...
                                                        </Spinner>
                                                        <span>
                                                        {' '}Loading
                                                        </span>
                                                    </Button>

                                                </>
                                            )}
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={12} sm={12} md={10} lg={10} xl={10} xxl={10} className="text-center mt-5">                                
                                {data && (        
                                    <Card>
                                        <CardHeader>
                                            {t('Table')}
                                        </CardHeader>
                                        <CardBody>
                                            <Table hover bordered responsive >
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">{t('Data')}</th>
                                                        <th scope="col">{t('Battery')} (A)</th>
                                                        <th scope="col">{t('Soil Temperature')} (°C)</th>  
                                                        <th scope="col">{t('Soil Humidity')} (%)</th>  
                                                        <th scope="col">{t('Electrical Conductivity')} (S/m)</th>
                                                        <th scope="col">{t('Nitrogen')} (mg/kg)</th>  
                                                        <th scope="col">{t('Phosporous')} (mg/kg)</th>  
                                                        <th scope="col">{t('Potassium')} (mg/kg)</th>  
                                                        <th scope="col">{t('Organic Carbon')} (mg/kg)</th>  

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((farmer, index) => (
                                                        <tr key={index}>
                                                            <td>{new Date(farmer.Data).toLocaleString()}</td>
                                                            <td>{farmer.Batteria}</td>
                                                            <td>{farmer.Temperatura}</td>
                                                            <td>{farmer.Umidita}</td>
                                                            <td>{farmer.Conducibilita_Elettrica}</td>
                                                            <td>{farmer.Azoto}</td>
                                                            <td>{farmer.Fosforo}</td>
                                                            <td>{farmer.Potassio}</td>
                                                            <td>{farmer.Carbonio_Organico}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>                            

                                )}           
                            </Col>                            
                        </Row>
                    </Container>
                </>
            )}
        </div>
    )
}

export default IoTTable
