import React from 'react';
import { MapContainer, TileLayer, GeoJSON, LayersControl } from 'react-leaflet';
import centroid from '@turf/centroid';

const FieldMap = ({ feature }) => {
    // Calculate centroid of the feature geometry
    const featureCentroid = centroid(feature).geometry.coordinates;
    const center = [featureCentroid[1], featureCentroid[0]]; // Leaflet uses [lat, lng]

    return (
        <MapContainer center={center} zoom={14} style={{ height: "200px", width: "200px" }}>
            <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="OpenStreetMap">
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer checked name="Satellite">
                    <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                </LayersControl.BaseLayer>
            </LayersControl>
            <GeoJSON data={feature} />
        </MapContainer>
    );
};

export default FieldMap;
