// CheckoutForm.js
import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios'
import { useSelector } from 'react-redux'; 

import {Button, Card, CardHeader, CardBody, Spinner} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import {useNavigate} from 'react-router-dom'

const CheckoutForm = () => {

    const [hectares,sethectares] = useState('')

    const navigate = useNavigate()

    ////////////

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    ////////////

    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(true);

    const token = useSelector((state) => state.userLogin.userInfo.token);

    useEffect(() => {

        const fetchData = async () => {
            try {
    
                const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
                }
                
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/field-hectares/`, config);

                console.log(response.data)
                
                sethectares(response.data.area_hectares);
    
            } catch (error) {
            }
        };
    
          fetchData(); 
      }, [token]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(false)

        if (!stripe || !elements) {
        return;
        }

        const cardElement = elements.getElement(CardElement);

        const { tokenstripe, error } = await stripe.createToken(cardElement);

        if (error) {
        console.error(error);
        
        } else {

            try {

                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }
    
                const payload = {
                    amount: 5*hectares*1.22, 
                    currency: 'euro',
                    hectares: hectares
                }
    
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/payment/`, payload, config);
    
            } catch(error) {
                console.log(error)
            } finally{
                setLoading(true)
                navigate("/payment-success/")
            }

        }
    };

    return (
        <>  
            <Card>
                <CardHeader>
                    <h3>{t('Upgrade your account to PRO')}</h3>
                </CardHeader>
                <CardBody>
                    <CardElement />
                    <br></br>
                    {loading ? (
                        <>
                            <Button color='success' onClick={handleSubmit} disabled={!stripe}>
                                {t('Upgrade your account to PRO')}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button color="success" disabled>
                                <Spinner size="sm">
                                    Loading...
                                </Spinner>
                                <span>
                                {' '}Loading
                                </span>
                            </Button>
                        </>
                    )}

                </CardBody>
            </Card>

        </>
    );
};

export default CheckoutForm;
